/*md

# b-option_switch

This component allows user to choose one option for ex. shipping method, saved address etc.

```html_example
<div class="b-option_switch">
	<div class="b-option_switch-inner">
		<input id="shippingMethod-001" class="b-option_switch-input" name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio" value="001" data-value="001" data-ref="field" data-event-change="update">
		<div class="b-option_switch-icon"></div>
		<label class="b-option_switch-label" for="shippingMethod-001">
			<div class="b-option_switch-label_surface">
				<span class="b-option_switch-name">Ground</span>
				Free
			</div>
			<span class="b-option_switch-description">
				7-10 Business Days
			</span>
		</label>
	</div>
</div>
<div class="b-option_switch">
	<div class="b-option_switch-inner">
		<input id="shippingMethod-002" class="b-option_switch-input" name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio" value="002" data-value="002" data-ref="field" data-event-change="update" checked="">
		<div class="b-option_switch-icon"></div>
		<label class="b-option_switch-label" for="shippingMethod-002">
			<div class="b-option_switch-label_surface">
				<span class="b-option_switch-name">2-Day Express</span>
				$9.99
			</div>
			<span class="b-option_switch-description">
				2 Business Days
			</span>
		</label>
	</div>
</div>
```

*/

.b-option_switch {
	$root: &;

	--option-indent: 24px;

	padding: var(--option-indent);
	user-select: none;

	&-inner {
		display: flex;
		position: relative;
	}

	&-label {
		cursor: pointer;
		display: block;
		width: 100%;

		&::before {
			border: $global-line solid $color-divider;
			border-radius: $global-radius;
			bottom: calc(-1 * var(--option-indent));
			content: '';
			left: calc(-1 * var(--option-indent));
			margin-top: -1px;
			position: absolute;
			right: calc(-1 * var(--option-indent));
			top: calc(-1 * var(--option-indent));

			#{$root}-input:checked ~ & {
				border-color: $color-text-dimmed;
				z-index: 1;
			}
		}
	}

	&-label_surface {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		position: relative;
	}

	&-label_edit {
		margin-inline-start: 24px;
		z-index: 1;
	}

	&-input {
		@include g-radio(_input);
	}

	&-icon {
		@include g-radio(_icon);

		position: relative;
		z-index: initial;

		#{$root}-input:active + & {
			@include g-radio(_icon, m-active);
		}

		#{$root}:hover & {
			@include g-radio(_icon, m-hover);
		}

		#{$root}-input:checked + & {
			@include g-radio(_icon, m-checked);
		}
	}

	&-name {
		font-size: 16px;
		font-weight: bold;
	}

	&-description {
		color: $color-text-dimmed;
		display: block;
		font-weight: 500;
		margin-top: 4px;
		position: relative;
	}

	&-promotion {
		margin: 8px 0 0;

		.b-promotion {
			margin: 0;
		}
	}
}

.b-option_switch.m-cart {
	--option-indent: 16px;
}
