.b-header_ext {
	$root: &;

	@include g-page-designer-alignments;

	&-wrapper {
		display: flex;

		@include media(md-up) {
			max-width: var(--max-width, none);
		}

		&.m-pictute_before {
			#{$root}-img {
				margin-right: 20px;
			}
		}

		&.m-pictute_after {
			flex-direction: row-reverse;

			#{$root}-img {
				margin-left: 20px;
			}
		}

		&.m-pictute_above {
			flex-direction: column;

			#{$root}-img {
				align-self: center;
				margin-bottom: 20px;
			}
		}
	}

	&-picture {
		display: block;
		height: 48px;
		position: relative;
		width: 48px;

		img {
			@include g-image_container(_img);
		}
	}

	.b-text_block {
		align-self: center;
	}
}
