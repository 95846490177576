.c-banner_content {
	background-color: var(--bg-content, transparent);
	max-width: 100%;
	position: relative;
	width: var(--content-width, auto);

	@include media(sm) {
		width: 100%;
	}

	&.m-extra_padding {
		padding: 60px;

		@include media(md) {
			padding: 28px;
		}

		@include media(sm) {
			padding: 20px;
		}
	}

	&-image {
		width: 100%;
	}

	&-picture {
		background: $color-bg-shade2;
		bottom: 0;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top: 0;

		img {
			@include g-image_container(_img);
		}
	}
}
