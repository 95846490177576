@use 'sass:math';
$dot-size: 34px;
$margin-inline-end: 6px;

.b-carousel {
	$root: &;

	position: relative;

	&-title {
		@include heading($h2-font-size, $h2-lineheight, $font-weight-heavy);

		margin-bottom: 36px;
		text-align: center;
	}

	&-track {
		@include g-snap_scroll;

		&.m-mousemove_navigation {
			scroll-snap-type: unset;
		}

		&.m-grabbing {
			cursor: grab;
			scroll-behavior: auto;
			user-select: none;

			&::before {
				bottom: 0;
				content: '';
				display: block;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 2;
			}
		}
	}

	&-item {
		scroll-snap-align: start;
	}

	// Prev / Next
	&-ctrl {
		appearance: none;
		background-color: rgba($white, 0.26);
		border: none;
		color: $black;
		cursor: pointer;
		display: none;
		height: 48px;
		margin-top: -24px;
		position: absolute;
		text-align: center;
		top: 50%;
		transition: 0.4s linear;
		transition-property: background-color, opacity;
		user-select: none;
		width: 48px;
		z-index: 1;

		@include hover-supported {
			&:hover {
				background-color: $white;
				color: color(base-5);
			}
		}

		&:focus {
			outline: none;
		}

		#{$root}.m-inited & {
			display: block;

			@include media-breakpoint-down(xs) {
				display: none;
			}
		}

		&[disabled],
		#{$root}.m-no_scroll & {
			// It should not be hidden with display: none
			// 1) screen reader should announce "Unavailable" to user
			// 2) we should prevent of user false clicks when button is disappears

			opacity: 0;
			z-index: -1;

			@include hover-supported {
				&:hover {
					color: inherit;
				}
			}
		}

		#{$root}.m-no_scroll & {
			height: 0;
			padding: 0;
		}

		&.m-prev {
			left: 0;
		}

		&.m-next {
			right: 0;
		}

		svg {
			height: 33px;
			width: 33px;
		}
	}

	// Pagination. Used only on HP Hero
	&-pagination {
		bottom: 16px;
		display: none;
		justify-content: center;
		left: grid-gutter(sm);
		position: absolute;
		right: grid-gutter(sm);
		z-index: 1;

		@include media-breakpoint-down(sm) {
			justify-content: flex-start;
		}

		#{$root}.m-no_scroll & {
			display: none;
		}
	}

	&-page {
		background-color: transparent;
		border: none;
		cursor: pointer;
		display: block;
		height: 20px;
		line-height: 20px;
		position: relative;
		text-align: center;
		width: 20px;

		&::before {
			$dot-size: 8px;

			background-color: $black;
			border: 1px solid $white;
			border-radius: $dot-size;
			content: '';
			display: inline-block;
			height: $dot-size;
			left: 50%;
			margin-inline-start: -#{math.div($dot-size, 2)};
			margin-top: -#{math.div($dot-size, 2)};
			position: absolute;
			top: 50%;
			transition: 0.4s linear;
			transition-property: width, height, margin;
			width: $dot-size;
		}

		&.m-current {
			&::before {
				background-color: $grey65;
				content: '';
				height: 14px;
				margin-inline-start: -7px;
				margin-top: -7px;
				width: 14px;
			}
		}
	}

	// Container component to allow multiple nesting within carousel slide
	&-item_wrapper {
		@include g-page-designer-bottom_margins;

		width: 100%;
	}
}

.b-carousel_ext .b-carousel,
.b-carousel_simple .b-carousel,
.b-carousel.m-products .b-carousel {
	&-pagination_dot {
		background: none;
		border: none;
		color: transparent;
		cursor: pointer;
		fill: $color-grey88;
		height: $dot-size;
		margin-inline-end: $margin-inline-end;
		padding: 0;
		width: $dot-size;

		@include hover-supported {
			&:hover {
				fill: color('primary-1');
			}
		}

		&:last-child {
			margin-inline-end: 0;
		}

		&[aria-disabled='true'] {
			cursor: initial;
		}

		svg {
			transform: scale(0.6);
			transition: transform 0.4s linear;
		}

		&.m-current {
			fill: color('primary-1');

			svg {
				transform: scale(1);
			}
		}
	}
}

// stylelint-disable no-descending-specificity
.b-carousel_ext .b-carousel,
.b-carousel.m-products .b-carousel {
	&-pagination {
		position: static;
		justify-content: center;
		flex-wrap: nowrap;
	}
}

.b-carousel.m-products { //This is actually the Einstein carousel
	$pdx-product_tile-bg: $color-grey73;

	@include media-breakpoint-up(md) {
		padding: 0 40px;
	}

	&.m-inited {
		.carousel-item {
			display: block;
		}
	}

	.b-carousel-track {
		@include media-breakpoint-only(xs) {
			margin: 0 -12px;
		}
	}

	.b-carousel-ctrl {
		height: auto;
		padding: 0;
		width: auto;
	}

	.b-carousel-item {
		padding: 5px 10px;
		max-width: 25%;
		min-width: 25%;

		@include media-breakpoint-only(md) {
			max-width: 33.3%;
			min-width: 33.3%;
		}

		@include media-breakpoint-only(xs) {
			max-width: 75%;
			min-width: 75%;
		}
	}

	.b-carousel-pagination {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.product {
		background: $pdx-product_tile-bg;
		box-shadow: none;
		height: 100%;
	}

	.product-tile {
		display: flex;
		flex-direction: column;
		height: 100%;
		text-align: left;
	}

	.product-link-button {
		display: none;
	}

	.price {
		font-weight: normal;
	}

	.price-value {
		display: block;
	}

	.tile-body {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 0 20px;
	}

	.product-tile-master-link-wrap {
		flex-grow: 1;
	}
}
