/*md

# g-backdrop_dialog

Dialog window backdrop styles that used in several components and particular breakpoints.

Serve as overlay and container to hold dialog box inside + provide scroll on overflow.
This is solution for large dialogs that scrolled inside viewport.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-backdrop_dialog;
	}
	// ...
}
```
*/

@mixin g-backdrop_dialog {
	align-items: center;
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	transition: $motion-ease-popups;
	transition-property: visibility, background-color;
	visibility: hidden;
	z-index: z(modal);

	&.m-opened,
	&.m-active {
		background-color: rgba($color-bg-overlay, 0.4);
		overflow-y: scroll;
		visibility: visible;
	}
}
