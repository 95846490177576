/*md

# Tabs (`b-tab_list` / `b-tab_panel`)

Tabs presents multiple mutually exclusive panes of content in the same area.
Includes a tabbed control(tab) and a content area. Clicking a tab displays its corresponding pane in the content area.

## Attributes

```
[boolean] - data-active-first - activate first tab and first tab panel
[string]  - data-active-panel - activate tab and tab panel by provided panel id
[boolean] - data-auto-activation - if tabs list should follow accessibility `Tabs with Automatic Activation` feature
```

## Usage

To get started with tabs we should link the tab and the content area.
We have a `data-panel-name` attribute on a tab and `id` attribute on the pane for that.

### Tabs with automatic activation (data-auto-activation="true")

The tab pane content will change just in case of using arrow keys.
With using just a Tab key, inactive tabs will be skipped by focus.
Click functionality works as usual.

```html_example
<div
    data-widget="tabs"
    data-auto-activation="true"
    data-active-panel="firstPanel"
    data-event-keydown="handleKeydown"
>
    <div data-ref="tablist" role="tablist" class="b-tab_list">
        <button aria-selected="true"
                class="b-tab_list-tab"
                data-panel-name="firstPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-firstPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            First tab
        </button>

        <button
                tabindex="-1"
                aria-selected="false"
                class="b-tab_list-tab"
                data-panel-name="secondPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-secondPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            Second tab
        </button>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="firstPanel"
    >
        <h1>The content of a FIRST tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="secondPanel"
    >
        <h1>The content of a SECOND tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.</p>
    </div>
</div>
```

### Tabs with manual activation (data-auto-activation="false")

The tab pane content will change just in case of using Enter/Space button on focused element.
Click functionality works as usual.

```html_example
<div
    data-widget="tabs"
    data-auto-activation="false"
    data-event-keydown="handleKeydown"
    data-active-panel="firstPanel"
>
    <div data-ref="tablist" role="tablist" class="b-tab_list">
        <button aria-selected="true"
                class="b-tab_list-tab"
                data-panel-name="firstPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-firstPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            First tab
        </button>

        <button
            aria-selected="false"
            class="b-tab_list-tab"
            data-panel-name="secondPanel"
            data-widget-event-click="handleTabClick"
            data-id="button-secondPanel"
            data-event-click.prevent="handleClick"
            data-widget="button"
            role="tab"
        >
            Second tab
        </button>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="firstPanel"
    >
        <h1>The content of a FIRST tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="secondPanel"
    >
        <h1>The content of a SECOND tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.</p>
    </div>
</div>
```

## SCSS Notes

We have two SCSS blocks for Tabs. The first is for tab controls `b-tab_list` , and the second is fortab content
areas `b-tab_panel`.
*/

.b-tab_list {
	$root: &;

	align-items: center;
	display: flex;
	justify-content: center;
	margin: 16px auto;
	overflow-x: auto;
	scrollbar-width: none;
	user-select: none;

	&::-webkit-scrollbar {
		display: none;
	}

	&.m-search {
		margin: 0 0 44px;
	}

	&.m-pdp {
		font-size: 2rem;
		justify-content: flex-start;
		margin: 0 0 32px -8px;
	}

	&.m-checkout {
		justify-content: flex-start;
	}

	&.m-account {
		margin-bottom: 40px;

		@include media(sm) {
			margin-bottom: 32px;
		}
	}

	&-tab {
		appearance: none;
		background: transparent;
		border: none;
		border-bottom: 4px solid transparent;
		border-radius: 0;
		cursor: pointer;
		display: block;
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 1px;
		padding: 12px 40px;
		position: relative;
		text-decoration: none;
		text-transform: initial;
		transition: color $motion-fast;
		white-space: nowrap;

		@include media(sm) {
			padding: 12px 20px;
			width: 100%;
		}

		&:hover {
			color: $color-action;
		}

		&::after {
			background-color: $color-grey88;
			bottom: -5px;
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			right: 0;
		}

		&.m-active {
			border-color: $color-action;
			color: $color-action;
		}

		#{$root}.m-pdp & {
			margin-inline-start: 0;
			padding: 16px;
		}

		#{$root}.m-checkout & {
			font-weight: bold;
			text-transform: uppercase;
		}

		#{$root}.m-search & {
			@include media(sm) {
				font-size: 20px;
				padding: 12px;
			}
		}
	}
}

.b-tab_panel {
	display: none;
	width: 100%;

	&.m-active {
		display: block;
	}
}
