.b-search_form {
	@include g-page-designer-alignments;
    @include g-page-designer-component-paddings('.b-search_form-content');

	&-content {
		max-width: var(--max-width, 100%);
		width: 100%;

		@include media(sm) {
			max-width: 100%;
		}
	}

	.b-header_search {
		&-wrap {
			margin-bottom: 36px;
		}

		&-title_no_results {
			@include g-heading_4;

			text-align: start;
			word-break: break-word;
		}

		&-suggestion {
			font-size: 16px;
			margin-top: 16px;
		}

		&-keywords_wrap {
			display: inline-block;
		}
	}
}
