.b-product_attribute_content {
	width: 100%;

	&-title {
		background-color: $color-blue98;
		border: 2px dashed $color-blue;
		font-size: 12px;
		padding: 4px;
		text-align: center;

		.b-carousel-item.m-duplicate_attribute &,
		.b-product_attributes_gallery-item.m-duplicate_attribute & {
			background-color: $color-red95;
			border: 2px dashed $color-red;
			color: $color-red;
		}
	}
}
