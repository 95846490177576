$dot-size: 24px;

.b-progress_bar {
	background-color: $color-bg;
	display: block;
	margin: 0 auto;
	opacity: 0;
	padding-bottom: 38px;
	padding-top: 12px;
	position: sticky;
	transform: translateY(-2px); // fix for zoomed in pages
	transition: opacity $motion-ease;
	visibility: hidden;
	z-index: z(progress-bar);

	&.m-initialized {
		opacity: 1;
		visibility: visible;
	}

	&.m-hide_sm {
		@include media(sm) {
			display: none;
		}
	}

	&-inner {
		@include g-section_holder_header;

		position: relative;
	}

	&-content {
		background-color: $color-grey88;
		height: 2px;
		position: relative;
	}

	&-line {
		background-color: $color-primary;
		display: block;
		height: 2px;
		position: absolute;
		top: 0;
		width: 0;
	}

	&-dot {
		color: $color-grey88;
		cursor: pointer;
		min-height: $dot-size;
		min-width: $dot-size;
		position: absolute;
		text-decoration: none;
		top: -12px;
		transform: translateX(-50%);
		transition: color $motion-fast;

		&::before {
			background-color: $color-grey88;
			border-radius: 50%;
			content: '';
			height: 8px;
			left: 0;
			margin: auto;
			outline: 4px solid $color-white;
			position: absolute;
			right: 0;
			top: 9px;
			transition: color $motion-fast;
			width: 8px;
		}

		&.m-active {
			color: $color-black;

			&::before {
				background-color: $color-primary;
			}
		}

		&:hover {
			@include media(lg-up) {
				color: $color-black;

				&::before {
					background-color: $color-primary;
				}
			}
		}
	}

	&-dot_name {
		display: inline-block;
		max-width: 140px;
		overflow: hidden;
		padding-top: 20px;
		text-overflow: ellipsis;
		transition: opacity $motion-ease;
		white-space: nowrap;

		@include media(sm) {
			opacity: 0;
		}
	}

	&-dot.m-text_left &-dot_name {
		margin-left: -8px;
		transform: translateX(50%);
	}

	&-dot:first-child &-dot_name {
		@include media(sm) {
			opacity: 1;
		}
	}

	&-dot.m-active &-dot_name {
		@include media(sm) {
			opacity: 0;
		}
	}

	&-dot.m-last_active &-dot_name {
		@include media(sm) {
			opacity: 1;
		}
	}

	&.m-hide_labels &-dot_name {
		display: none;
	}
}
