/*md

# g-snap_scroll

Components that apply snap scroll CSS rules to different components and cases.

Designed to use same snap scroll functionality in different components and/or on
particular breakpoints.
Ex: b-carousel, b-product_gallery, .b-product_slider etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include g-snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/

@mixin g-snap_scroll($direction: x, $type: mandatory) {
	display: flex;
	overflow: hidden;
	-ms-overflow-style: none; // stylelint-disable-line order/properties-alphabetical-order
	scroll-behavior: smooth;
	-ms-scroll-chaining: none; // stylelint-disable-line property-no-vendor-prefix
	scrollbar-width: none; // FF

	@if ($direction == 'y') {
		flex-direction: column;
		overflow-y: auto;
		overscroll-behavior-y: contain;
		scroll-snap-type: if($type == 'none', none, y $type);
		//touch-action: pan-y;
	} @else {
		overflow-x: auto;
		overscroll-behavior-x: contain;
		scroll-snap-type: if($type == 'none', none, x $type);
		//touch-action: pan-x;
	}

	&::-webkit-scrollbar {
		display: none;
	}
}
