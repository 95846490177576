@mixin g-page-designer-link($_hover_color: $color-action) {
	background: transparent;
	border: 0;
	border-color: transparent;
	color: inherit;
	cursor: pointer;
	font-weight: 400;
	height: auto;
	letter-spacing: 0;
	min-height: auto;
	padding: 0;
	text-decoration: underline;
	text-transform: none;

	@include hover-supported {
		&:hover {
			color: $_hover_color;
			text-decoration: none;
		}
	}
}
