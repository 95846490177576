/*md

# g-link

This component is designed to provide same styles of text-type links (hyperlinks)
across different components.

For UI type links see `g-link_ui`.

## Usage

```scss
// for regular cases
.b-user_content-link {
	@include g-link;
}

// for cases when text color is inverted (white, red etc) and action color will not
// be good (ex blue hover state on red alert banner)
.b-error_message-link {
	@include g-link(inherit);
}
```

*/
@mixin g-link($_hover_color: $color-action) {
	color: inherit;
	cursor: pointer;
	font-weight: 500;
	text-decoration: underline;

	@include hover-supported {
		&:hover {
			color: $_hover_color;

			@if $_hover_color == inherit {
				text-decoration: underline;
			}
		}
	}
}
