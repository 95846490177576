$dot-size: 34px;
$margin-inline-end: 6px;

.b-carousel_simple {
	@include g-page-designer-vertical_alignments;

	display: flex;

	.b-carousel {
		display: flex;
		flex-direction: column;
		margin: 0;
		max-width: 100%;
		width: 100%;

		&.m-inited .b-carousel-ctrl {
			@include media(md-down) {
				display: block;
			}
		}

		&-ctrl {
			background-color: transparent;
			border: 1px solid;
			border-radius: 50%;
			height: 30px;
			padding: 0;
			width: 30px;
			top: 50%;

			&[disabled] {
				opacity: 0.5;
				z-index: 1;
			}

			svg {
				height: 17px;
				width: 16px;
			}
		}

		&-pagination {
			justify-content: initial;
			margin: 0 auto;
			min-height: 34px;
			order: 1;
			overflow: hidden;
			position: initial;
			width: 3*$dot-size + 2*$margin-inline-end;
		}

		&-pagination_content {
			display: inline-flex;
			left: $dot-size + $margin-inline-end;
			position: relative;
			transition: left $motion-ease;
		}

		&-pagination_dot {
			border: none;
			color: transparent;
			cursor: pointer;
			fill: $color-grey46;
			height: $dot-size;
			margin-inline-end: $margin-inline-end;
			padding: 0;
			width: $dot-size;

			@include hover-supported {
				&:hover {
					fill: $color-blue38;
				}
			}

			&:last-child {
				margin-inline-end: 0;
			}

			&[aria-disabled='true'] {
				cursor: initial;
			}

			svg {
				transform: scale(0.6);
				transition: transform $motion-ease;
			}

			&.m-current {
				fill: $color-blue;

				svg {
					transform: scale(1);
				}
			}
		}

		&-track {
			&.m-mousemove_navigation {
				scroll-behavior: smooth;
				scroll-snap-type: x mandatory;
			}
		}

		&-item {
			max-width: 100%;
			min-width: 100%;

			.b-text_block {
				height: 100%;
				position: relative;
			}

			.carousel-item {
				display: block;
			}
		}

		.product-tile-container-border {
			display: none;
		}
	}

	&.m-type-numeric .b-carousel {
		display: flex;
		flex-direction: column;

		&-actions {
			align-items: center;
			display: flex;
			order: 2;
			padding-top: 10px;
			position: relative;
			width: 170px;
			margin: 0 auto;

			@include media(sm) {
				padding-top: 8px;
			}
		}

		&-ctrl {
			display: inline-block;
			margin-top: 0;
			position: relative;
			transform: none;
			height: 24px;
			border: 0;

			svg {
				height: 24px;
				width: 24px;
			}
		}

		&-pagination {
			display: none;
		}

		&-numeric_pagination {
			margin-top: 2px;
			text-align: center;
			width: 100%;
		}
	}

	&.m-type-standard {
		.b-carousel {
			padding: 0 60px;
	
			@include media(md) {
				padding: 0 44px;
			}
	
			@include media(sm) {
				padding: 0;
			}
	
			&-actions {
				display: block;
				left: 0;
				position: absolute;
				right: 0;
				height: 100%;
				
	
				@include media(lg-up) {
					left: 60px;
					right: 60px;
				}
	
				@include media(md) {
					left: 44px;
					right: 44px;
				}
			}
	
			&-ctrl {
				margin-top: -15px;
	
				&.m-prev {
					@include media(lg-up) {
						left: -40px;
					}
	
					@include media(md) {
						left: -34px;
					}
				}
	
				&.m-next {
					@include media(lg-up) {
						right: -40px;
					}
	
					@include media(md) {
						right: -34px;
					}
				}
			}

			&-pagination {
				.page {
					appearance: none;
					border: 0;
					box-shadow: none;
					background-color: $color-grey46;
					border-radius: 50%;
					height: 12px;
					width: 10px;
					margin: 0 7px;

					&.m-current {
						background-color: $btn-blue;
					}
				}
			}

			&.m-inited {
				.b-carousel-pagination {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}

	&.m-hide_controls .b-carousel {
		&-actions,
		&-pagination {
			display: none;
		}

		&-track {
			&.m-grabbing {
				cursor: default;
				user-select: auto;
			}
		}
	}

	.l-grid_layout-item.m-lg_3 &.m-type-standard .b-carousel {
		padding: 0;

		&-actions {
			display: none;
		}
	}

	.l-grid_layout-item.m-md_4 &.m-type-standard .b-carousel {
		@include media(md) {
			padding: 0;
		}

	}
}
