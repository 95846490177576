/*md

# b-promo_box

`promo-box` is basic container for creating promo boxes and banners.
It designed to position description (`b-promo_caption`) over the image box.

This implementation could handle both image and text-centric approaches.

## Example

```html_example
<figure class="b-promo_box m-caption_center">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Overflow handling

This component is designed to handle any type of overflow without cutting text content.

### Very long text

```html_example
<figure class="b-promo_box m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities and other thing into long long title with some additional details
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### Different aspect ratio of image

```html_example
<figure class="b-promo_box">
	<picture class="b-promo_box-picture" style="padding-bottom:10%">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Horizontal alignment

### `m-caption_left`

```html_example
<figure class="b-promo_box m-caption_left">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_right`

```html_example
<figure class="b-promo_box m-caption_right">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_center`

```html_example
<figure class="b-promo_box m-caption_center">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_offcenter`

```html_example
<figure class="b-promo_box m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Vertical alignment

For sake of simpleness and robustness Launch 360 do not provide predefined vertical alignment
variations.

## Video as base

It is not limited what you could use as base for banner - it could be image or video.

What you need to do:

* `autoplay loop muted playsinline` is required to auto play video without user gesture in iOS

Please see [iOS manual](https://webkit.org/blog/6784/new-video-policies-for-ios/)

```html_example
<figure class="b-promo_box">
	<div class="b-promo_box-picture">
		<video autoplay loop muted playsinline width="1600" height="800">
			<source src="../../images/guide/examples/banner-video-16x9-lg.mp4" type="video/mp4" />
			<source src="../../images/guide/examples/banner-video-16x9-lg.mov" type="video/quicktime" />
		</video>
	</div>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Advanced: tiled background

```html_example
<style>
	.b-promo_box.m-image_3x4_right {
		.b-promo_box-picture {
			grid-column: 8 / span 4;
			padding-bottom: aspect-ratio(3, 4);
			padding-bottom: 133.3333333333%;
		}
	}
	.b-promo_box.m-image_3x4_left {
		.b-promo_box-picture {
			grid-column: 1 / span 4;
			padding-bottom: aspect-ratio(3, 4);
			padding-bottom: 133.3333333333%;
		}
	}
</style>
<figure class="b-promo_box m-caption_left m-image_3x4_right m-text_below">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/banner-3x4-5.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
    <figcaption class="b-promo_box-caption b-promo_caption">
        <h2 class="b-promo_caption-title">
            New beauty neutrals color
        </h2>
        <p class="b-promo_caption-subtitle">
            Plunge into calm pastel colors, choose for yourself only the most sophisticated and beautiful attire in the new season
        </p>
        <div class="b-promo_caption-actions">
            <a
                class="b-button"
                href="$url('Search-Show', 'cgid', 'category')$"
            >
                Shop New Season
            </a>
        </div>
    </figcaption>
</figure>
```

## Advanced: Background stretched independent from container

```html_example
<figure class="b-promo_box m-full_bleed m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
    <figcaption class="b-promo_box-inner">
        <div class="b-promo_box-caption b-promo_caption">
            <h2 class="b-promo_caption-title">
                Make boilerplate better
            </h2>
            <p class="b-promo_caption-subtitle">
                Everyone's fallen for boilerplate so we added to her games repertoire for spring with new playful styles
                inspired by darts and that staple of a British pub - the fruit machine.
            </p>
            <div class="b-promo_caption-actions">
                <a
                    class="b-button"
                    href="$url('Search-Show', 'cgid', 'category')$"
                >
                    Shop New Season
                </a>
            </div>
        </div>
    </figcaption>
</figure>
```
*/

// Could be
// .b-promo_box ?m-text_below | m-text_over
//     .b-promo_img ?m-hero | m-2x1 | m-3x4 | m-left | m-right
//     .b-promo_caption ?m-center | m-left | m-right | m-offcenter
.b-promo_box {
	$root: &;

	@include g-grid;

	&-picture {
		@include g-image_container(_container, aspect-ratio(2.26, 1));

		grid-column: grid-start / grid-end;
		grid-row: 1 / 2;
		z-index: -1; // We need wrap content into grid to prevent of text cutting if image is too narrow or if content too high

		@include media(sm) {
			padding-bottom: aspect-ratio(1, 1);
		}

		img,
		video {
			@include g-image_container(_img);
		}
	}

	&-inner {} // reserved for hero banner

	&-caption {
		grid-column: grid-start / grid-end;
		grid-row: 1 / 2;
		padding: 48px 0;
		text-align: center;
	}

	// Modifications
	&.m-full_bleed {
		grid-template-columns: auto; // Altering hero because it full-bleed banner and move grid to `-inner` element
		height: 100%;

		#{$root}-picture {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			padding-bottom: aspect-ratio(1442, 466); // Hero has unique logic for aspect ratios, because it should tease below the fold content

			@include media(md) {
				padding-bottom: aspect-ratio(768, 448);
			}

			@include media(sm) {
				padding-bottom: aspect-ratio(375, 432);
			}
		}

		#{$root}-inner {
			@include g-grid;
			@include g-section_holder;

			grid-column: 1 / 2;
			grid-row: 1 / 2;
			margin-bottom: 16px; // space for HP hero pagination
			margin-top: 16px; // space for HP hero pagination
			width: 100%;
		}
	}

	&:not(.m-full_bleed) {
		#{$root}-caption {
			@include media(sm) {
				padding-left: grid-margin(sm);
				padding-right: grid-margin(sm);
			}
		}
	}

	// Caption modifications
	&.m-caption_left {
		#{$root}-caption {
			text-align: start;

			@include media(md-up) {
				grid-column: 2 / span 5;
			}
		}
	}

	&.m-caption_right {
		#{$root}-caption {
			text-align: start;

			@include media(md-up) {
				grid-column: 8 / span 5;
			}
		}
	}

	&.m-caption_center {
		#{$root}-caption {
			@include media(md-up) {
				grid-column: 3 / span 8;
			}
		}
	}

	&.m-caption_offcenter {
		#{$root}-caption {
			text-align: start;

			@include media(md-up) {
				grid-column: 7 / span 5;
			}
		}
	}

	// Layouts modifications

	// &.text_over {} default
	&.m-text_below:not(.m-full_bleed) {
		#{$root}-picture {
			@include media(sm) {
				grid-column: grid-start / grid-end;
			}
		}

		#{$root}-caption {
			@include media(sm) {
				grid-column: grid-start / grid-end;
				grid-row: 2 / 3;
				padding: 16px 0;
			}
		}
	}

	&.m-text_below.m-full_bleed { // When text_below inside full_bleed we need to alter
		@include media(sm) {
			display: block;

			#{$root}-picture {
				grid-column: grid-start / grid-end;
			}

			#{$root}-inner {
				grid-column: grid-start / grid-end;
				grid-row: 2 / 3;
			}

			#{$root}-caption {
				padding: 16px 0;
			}
		}
	}
}
