/*md
@no-stat

# Grids

## How to setup grids config for project

### Several grid configs for project

We can use several grid configs per project. To do this, we need to add a new grid name to the `$grids` map with settings.

```scss
$grids: (
	default: (
		//...
	),
	altered: (
		//...
	)
);
```

### Gaps / margin / column span configuration:

```scss
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	)
);
```

## Working with grids

### Development approaches

#### 1. Using `g-grid` mixin when css grid is applicable.

With features of `display: grid`. Please see [g-grid](02-components-g-grid.html) details.

#### 2. Using `grid-span` function to create custom layout based on (flex, float, inline-block, table etc.)

Could be used in conjunction with different display properties while maintaining their common features(floating, centering, etc.).
Please see [grid-span](01-core-functions-grid-span.html) details.

As example please see [flex based non semantic grid](05-blocks-guide-l-cols.html) like you could see before CSS grid era (Foundation, Bootstrap etc.).

#### 3. Get gaps / margin / column span to create your own grid layout system.

For that we have the next grid functions in `_grids_tools.scss`:
- grid-gutter
- grid-margin
- grid-columns

Please see [grid functions](00-configuration-grids_tools.html) details with usage examples.
*/
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	)
);

@import 'grids_tools';
