/*md

# b-scrollable_table

Mobile friendly tabular data component.

This table has an inner scroll on mobile devices with sticky cell headers. On desktop and tablet devices will be shown the usual table.

## Example
```html_example
<div class="b-scrollable_table">
	<div class="b-scrollable_table-content">
	    <table class="b-scrollable_table-table">
	        <tbody>
	            <tr>
	                <th scope="row">
	                    <strong>US</strong>
	                </th>
	                <td>
	                    <strong>2</strong>
	                </td>
	                <td>
	                    <strong>4</strong>
	                </td>
	                <td>
	                    <strong>6</strong>
	                </td>
	                <td>
	                    <strong>8</strong>
	                </td>
	                <td>
	                    <strong>10</strong>
	                </td>
	                <td>
	                    <strong>12</strong>
	                </td>
	            </tr>
	            <tr>
	                <th scope="row">
	                    <strong>EURO</strong>
	                </th>
	                <td>
	                    <strong>34</strong>
	                </td>
	                <td>
	                    <strong>36</strong>
	                </td>
	                <td>
	                    <strong>38</strong>
	                </td>
	                <td>
	                    <strong>40</strong>
	                </td>
	                <td>
	                    <strong>42</strong>
	                </td>
	                <td>
	                    <strong>44</strong>
	                </td>
	            </tr>
	            <tr>
	                <th scope="row">
	                    <strong>UK</strong>
	                </th>
	                <td>
	                    <strong>6</strong>
	                </td>
	                <td>
	                    <strong>8</strong>
	                </td>
	                <td>
	                    <strong>10</strong>
	                </td>
	                <td>
	                    <strong>12</strong>
	                </td>
	                <td>
	                    <strong>14</strong>
	                </td>
	                <td>
	                    <strong>16</strong>
	                </td>
	            </tr>
	            <tr>
	                <th scope="row">CHEST</th>
	                <td>80/31</td>
	                <td>80/32</td>
	                <td>86/34</td>
	                <td>91/36</td>
	                <td>96/38</td>
	                <td>101/40</td>
	            </tr>
	            <tr>
	                <th scope="row">WAIST</th>
	                <td>63/25</td>
	                <td>65/26</td>
	                <td>65/27</td>
	                <td>74/30</td>
	                <td>79/31</td>
	                <td>84/33</td>
	            </tr>
	            <tr>
	                <th scope="row">HIPS</th>
	                <td>89/35</td>
	                <td>91/36</td>
	                <td>94/37</td>
	                <td>99/39</td>
	                <td>104/41</td>
	                <td>109/43</td>
	            </tr>
	        </tbody>
	    </table>
	</div>
</div>
```
*/

.b-scrollable_table {
	margin: 16px 0;
	position: relative;

	&::after {
		@include media(sm) {
			background-color: $color-grey88;
			bottom: 0;
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			width: 1px;
		}
	}

	&-content {
		position: relative;

		@include media(sm) {
			border-left: 2px solid $color-grey88;
			overflow: auto;
		}
	}

	&-table {
		border: 2px solid $color-grey88;
		border-collapse: collapse;
		width: 100%;

		@include media(sm) {
			border-left: 0;
		}
	}

	th {
		background-color: $color-grey95;
		border: 1px solid $color-grey88;
		font-weight: normal;
		min-width: 70px;
		padding: 12px 8px;
		text-align: start;
		width: 1px;

		@include media(lg-up) {
			min-width: 110px;
			padding: 12px 24px;
		}

		@include media(sm) {
			background-clip: padding-box;
			border-left: 0;
			left: 0;
			position: sticky;
		}

		&::after {
			@include media(sm) {
				background-color: $color-grey88;
				bottom: 0;
				content: '';
				position: absolute;
				right: -1px;
				top: 0;
				width: 1px;
			}
		}
	}

	td {
		border: 1px solid $color-grey88;
		min-width: 90px;
		padding: 12px 8px;
		text-align: center;

		@include media(lg) {
			padding: 12px 24px;
		}
	}
}
