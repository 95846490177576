@use 'sass:color';

.b-product_tile {
	$root: &;

	position: relative;

	&-top {
		display: flex;
		flex-direction: column;
		margin-bottom: 12px;
		overflow: hidden;
		position: relative;
	}

	&-image_link {
		display: block;

		&.m-not_available {
			opacity: 0.5;
		}
	}

	&-image {
		img {
			@include g-image_container(_img);
		}
	}

	&-quick_view {
		background-color: rgba($color-bg, 0.8);
		border: none;
		bottom: 0;
		color: $color-action;
		font-weight: 500;
		left: 0;
		opacity: 0;
		position: absolute;
		text-transform: initial;
		transition-duration: $motion-fast;
		transition-property: color, opacity;
		width: 100%;

		@include media(md-down) {
			display: none;
		}

		&:hover {
			background-color: rgba($color-bg, 0.8);
			color: $color-action;
		}

		&.m-product_set {
			pointer-events: none;
		}

		&:focus,
		#{$root}-top:hover & {
			opacity: 1;
		}
	}

	&-no_available {
		color: $color-error;
		font-weight: 700;
		margin-bottom: 8px;
	}

	&-title {
		font-weight: normal;
		line-height: 1.6;
		margin-bottom: 4px;
		padding-right: 4px;
	}

	&-link {
		@include g-link_ui;

		display: block;
		word-break: break-word;
	}

	&-price {
		margin-bottom: 12px;
	}

	&.m-hero_product {
		grid-column: 2/-1;
		grid-row: 2/4;

		@include media(sm) {
			grid-column: 1/-1;
			grid-row: 3/4;
		}
	}
}
