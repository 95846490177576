/*md

# b-promo_line

Promo-line is classic text banner. It placed right under the header and stretched to full viewport width.

It consist from several elements, but has only one to place text into it - `b-promo_line-inner`.

It could consist inline images, rich formatting text (`strong`, `em`) or links.

## Example

```html_example
<div class="b-promo_line m-header">
	<p class="b-promo_line-inner">
		Get 20% off everything with code: <strong>ILOVE20</strong> <a href="$url('Product-Show', 'pid', 'product-1')$">Buy now</a>
	</p>
</div>
```

*/

.b-promo_line {
	background-color: $color-bg-promo_line;
	color: $color-fg-promo_line;
	font-size: 16px;
	font-weight: 500;
	padding: 28px 0;
	text-align: center;

	@include media(md-down) {
		padding: 12px 0;
	}

	&.m-search_results {
		background-color: $color-bg-promo_line-m-search_results;
		color: $color-fg-promo_line-m-search_results;

		@include media(sm) {
			display: none;
		}
	}

	.m-has_dialog &.m-header { // we need a wrapper to handle scroll / no scroll content width bumping when Dialogs is opened
		overflow-y: scroll;
	}

	&-inner {
		@include g-section_holder;
	}

	a {
		@include g-link(inherit);
	}
}
