/*md

# b-price

Designed to provide same styles of pricing across different components without explicit CSS class.
`.b-price` inherit font-size from parent wrapper or general font-size

## Regular price

```html_example
<div class="b-price">
	<span class="b-price-item">
		$9.99
	</span>
</div>
```

## Sale price

```html_example
<div class="b-price">
	<span class="b-sr_only">Was</span>
	<span class="b-price-item m-old">
		$11.99
	</span>
	<span class="b-sr_only">, is</span>
	<span class="b-price-item">
		$9.99
	</span>
</div>
```

## Price range

```html_example
<div class="b-price">
	<span class="b-price-item">
		$9.99
	</span>
	<span class="b-price-divider">-</span>
	<span class="b-price-item">
		$11.99
	</span>
</div>
```

## Free price

```html_example
<div class="b-price">
	<span class="b-price-item m-free">
		FREE
	</span>
</div>
```

*/

.b-price {
	align-items: baseline;
	display: flex;
	flex-wrap: wrap;
	font-weight: 700;
	position: relative;

	&-item {
		display: inline-block;
		margin-inline-end: 8px;
		white-space: nowrap;

		&:last-child {
			margin: 0;
		}

		&.m-old {
			color: $color-text-dimmed;
			text-decoration: line-through;
		}

		&.m-old ~ & {
			color: $color-accent;
		}

		&.m-free {
			color: $color-accent;
			text-transform: uppercase;
		}
	}

	&-divider {
		margin-inline-end: 8px;
	}

	&-per_unit {
		color: $color-text-dimmed;
		font-size: 14px;
		font-weight: 400;
	}

	&-badge {
		background-color: $color-bg-badges;
		color: $color-fg-badges;
		font-size: 12px;
		font-weight: 600;
		line-height: 1;
		padding: 4px 8px;
		word-break: break-word;

		.b-product_details-price & {
			font-size: 16px;
			margin-inline-end: 8px;
			position: relative;
			top: -4px;
		}

		.b-suggestions_product-price & {
			position: relative;
			top: -1px;
		}

		.b-quick_buy & {
			margin-inline-end: 8px;
		}
	}
}
