/*md
@no-stat

# Breakpoints

## Launch 360 breakpoints

Launch 360 has 4 main breakpoints that targeted to [supported devices](https://confluence.ontrq.com/display/RSB/SFRA+BP+-+Supported+Browsers+and+Devices)
 - iPhone X, iPad, MS Windows desktop / Macbook Pro retina

** Please not iPad landscape - is LG breakpoint **

[See more info](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

## Supported screen resolutions

Launch 360 is come "Retina ready". It supports MDPI and XHDPI pixel density across all site.

| Device             | Screen Resolution, CSS pixels | Pixel density |
|--------------------|-------------------------------|---------------|
| Desktop Windows PC | 1920x1080                     | MDPI          |
| Macbook pro 13     | 1280x800 / 1440x900           | XHDPI         |
| iPad Air 2         | 1024x768                      | XHDPI         |
| iPhone X           | 375x812                       | XHDPI         |
| Samsung Galaxy S9  | 360x740                       | XHDPI         |

## Supported screen scaling

Launch 360 comes with support of 1:1, 1:1.25, 1.5, 1:2 screen scaling. To do so you need
to take care not only by whole pixel but pixel fractions that is used.

*/

// Breakpoints
$break-xl: 1367px;
$break-lg: 1024px;
$break-md: 768px;

// config for `media()` mixin
$media: (
	sm: 'screen and (max-width: #{$break-md - 0.1})',
	md: 'screen and (min-width: #{$break-md}) and (max-width: #{$break-lg - 0.1})',
	lg: 'screen and (min-width: #{$break-lg}) and (max-width: #{$break-xl - 0.1})',
	xl: 'screen and (min-width: #{$break-xl})',
	md-up: 'screen and (min-width: #{$break-md})',
	md-down: 'screen and (max-width: #{$break-lg - 0.1})',
	lg-up: 'screen and (min-width: #{$break-lg})',
	lg-down: 'screen and (max-width: #{$break-xl - 0.1})'
);

@import 'breakpoint-tools';
