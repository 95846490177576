$global-fs: 16;

@mixin g-page-designer-typography() {
	h1,
	.title-h1 {
		@include g-heading_1;
	}

	h2 {
		@include g-heading_2;
	}

	h3 {
		@include g-heading_3;
	}

	h4 {
		@include g-heading_4;
	}

	h5 {
		@include g-heading_5;
	}

	h1,
	.title-h1,
	h2,
	h3,
	h4,
	h5 {
		margin-bottom: 20px;

		@include media(sm) {
			margin-bottom: 12px;
		}
	}

	ul,
	ol {
		display: block;
		margin-bottom: 16px;
		padding-left: 20px;
	}

	li {
		display: list-item;
		margin-bottom: 4px;
	}

	ul {
		list-style: disc outside;
	}

	ol {
		list-style: decimal outside;
	}

	p {
		font-weight: 500;
		margin-bottom: 16px;
	}

	blockquote {
		border-left: 4px solid $color-grey73;
		font-style: italic;
		margin: 0 0 16px;
		padding: 0 0 0 20px;
	}

	a:hover {
		color: $color-primary;
	}
}

@mixin fontSize($sizeValue) {
	font-size: ($sizeValue / $global-fs) + rem;
}