.b-product_ext {
	@include g-page-designer-vertical_alignments;

	display: flex;
	width: 100%;

	.b-product_tile {
		width: 100%;

		img {
			object-position: var(--img-obj-position);
		}

		&-description {
			@include g-text_overflow(2);

			margin-bottom: 8px;
		}
	}

	.b-promotion,
	.b-product_tile_swatches,
	.b-product_tile-no_available,
	.b-product_tile-price {
		margin-bottom: 0;
	}

	.b-rating,
	.b-product_tile-no_available,
	.b-button {
		margin-top: 12px;
	}

	.b-product_tile-price {
		margin-top: 4px;
	}

	.b-button {
		@include media(sm) {
			width: 100%;
		}
	}
}
