.b-category_tile {
	$root: &;

	@include g-page-designer-alignments;

	&-inner {
		display: grid;
		width: 100%;

		@include media(md-up) {
			max-width: var(--max-width, 100%);
		}
	}

	&-link,
	&-picture,
	&-content {
		grid-column: 1/2;
		grid-row: 1/2;
	}

	&-link,
	&-picture,
	img {
		border-radius: var(--aspect-radio-border, 0);
	}

	&-link {
		border: 0;
	}

	&-picture {
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: var(--bg-padding, 100%);
		position: relative;
		width: 100%;

		img {
			@include g-image_container(_img);

			object-position: var(--img-obj-position);
		}

		#{$root}-link & {
			transform: scale(1);
			transition: transform $motion-ease;
		}

		#{$root}-link:hover & {
			transform: scale(1.1);
		}
	}

	&-content {
		@include g-page-designer-vertical_alignments;

		display: flex;
		font-size: 18px;
		font-weight: 500;
		overflow: hidden;
		padding: 12px 12px;
		pointer-events: none;
		z-index: 1;

		a {
			pointer-events: auto;
		}

		p {
			margin-top: revert;
		}
	}

	.b-text_block {
		width: 100%;
	}
}
