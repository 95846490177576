h1,
.h1 {
  font-size: 2rem;
  line-height: 2.625rem;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
}

h2,
.h2 {
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
}

h3,
.h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
}

h4,
.h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
}

h5,
.h5 {
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: false;
  margin-top: 10px;
  margin-bottom: 20px;
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: false;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Roboto Condensed", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

/*md
@no-stat

# Breakpoints

## Launch 360 breakpoints

Launch 360 has 4 main breakpoints that targeted to [supported devices](https://confluence.ontrq.com/display/RSB/SFRA+BP+-+Supported+Browsers+and+Devices)
 - iPhone X, iPad, MS Windows desktop / Macbook Pro retina

** Please not iPad landscape - is LG breakpoint **

[See more info](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

## Supported screen resolutions

Launch 360 is come "Retina ready". It supports MDPI and XHDPI pixel density across all site.

| Device             | Screen Resolution, CSS pixels | Pixel density |
|--------------------|-------------------------------|---------------|
| Desktop Windows PC | 1920x1080                     | MDPI          |
| Macbook pro 13     | 1280x800 / 1440x900           | XHDPI         |
| iPad Air 2         | 1024x768                      | XHDPI         |
| iPhone X           | 375x812                       | XHDPI         |
| Samsung Galaxy S9  | 360x740                       | XHDPI         |

## Supported screen scaling

Launch 360 comes with support of 1:1, 1:1.25, 1.5, 1:2 screen scaling. To do so you need
to take care not only by whole pixel but pixel fractions that is used.

*/
/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS,which puts the properties and values you are changing right next
to each other.
That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in a different file.

## Configuration

**Site Layout Conception** details with examples you can find [here](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1024px)',
	lg-down: 'screen and (max-width: 1367px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};

	@include media(sm) {
		// styles for "s" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
.storepage {
  width: 100%;
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .storepage {
    padding-top: 1.25rem;
  }
}

a.pd-image-link {
  border: none;
}
a.pd-image-link:hover, a.pd-image-link:visited {
  border: none;
}

.component--full-screen-width {
  max-width: 100vw;
}
.component--full-screen-width .container {
  max-width: 100vw;
}
.component--full-screen-width .row {
  margin: 0;
}
.component--full-screen-width .col-12 {
  padding: 0;
}
.component--full-screen-width .pd-image-link {
  width: 100%;
}
.component--full-screen-width picture {
  width: 100%;
}
.component--full-screen-width .carousel-control-prev {
  left: 1px;
}
.component--full-screen-width .carousel-control-next {
  right: 1px;
}

.m-page_designer .component--full-screen-width {
  width: calc(var(--vw, 1vw) * 100);
  margin: 0 calc(calc(var(--vw, 1vw) * -50) + 50%);
}

/*md
@no-stat

# Breakpoints

## Launch 360 breakpoints

Launch 360 has 4 main breakpoints that targeted to [supported devices](https://confluence.ontrq.com/display/RSB/SFRA+BP+-+Supported+Browsers+and+Devices)
 - iPhone X, iPad, MS Windows desktop / Macbook Pro retina

** Please not iPad landscape - is LG breakpoint **

[See more info](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

## Supported screen resolutions

Launch 360 is come "Retina ready". It supports MDPI and XHDPI pixel density across all site.

| Device             | Screen Resolution, CSS pixels | Pixel density |
|--------------------|-------------------------------|---------------|
| Desktop Windows PC | 1920x1080                     | MDPI          |
| Macbook pro 13     | 1280x800 / 1440x900           | XHDPI         |
| iPad Air 2         | 1024x768                      | XHDPI         |
| iPhone X           | 375x812                       | XHDPI         |
| Samsung Galaxy S9  | 360x740                       | XHDPI         |

## Supported screen scaling

Launch 360 comes with support of 1:1, 1:1.25, 1.5, 1:2 screen scaling. To do so you need
to take care not only by whole pixel but pixel fractions that is used.

*/
/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS,which puts the properties and values you are changing right next
to each other.
That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in a different file.

## Configuration

**Site Layout Conception** details with examples you can find [here](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1024px)',
	lg-down: 'screen and (max-width: 1367px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};

	@include media(sm) {
		// styles for "s" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
/*md
@no-stat

# Palette

This is palette settings for project/brand.
It divided into 2 main categories - palette and applied color.

* Palette is general set of colors. It could be used directly if you do not have themes.
* Applied colors designed as layer of abstraction to have ability to overwrite it on brand level.

All themes have individual logic so it almost impossible to implement some single
universal approach. Launch 360 provide basic tools for further customization
depending of your requirements.

Please see _colors.md for more information about themes.

*/
/*md
@no-stat

# Globals variables

This variables are set of different global settings that is used across sets of components.

It include:

* globals
* depth of components (box-shadow)
* motion of components

*/
/*md
@no-stat

# Z-indexes

Z-index is an inherently tricky thing, and maintaining z-index order in a complex layout is difficult.
With different stacking orders and contexts, keeping track of them as their numbers increase can be hard.
<br />
<br />
We use sass function to help manage z-indexes from single place.
The most important requirement of this technique is sticking to it.
Any rogue hard-coded z-index values could compromise the integrity of those derived from your list.

## Usage

**We don't use hardcoded integer `z-index` values. Instead, we use indexes from the map `$z-indexes`**

### 1. Set up `$z-indexes` map
```scss
$z-indexes: (
    components: (
        component_name: (),
        checkbox: (
            before: (),
            after: (),
            icon: (),
        )
    ),
    content: (),
    popup-menu: ()
);
```

### 2. Add values in SCSS classes using `z()` function

#### Global components
```scss
.b-components { z-index: z(components); }
.b-content { z-index: z(content); }
.b-pop_up-menu { z-index: z(popup-menu); }
```

#### Inside a component
```scss
.b-component_name { z-index: z(components, component_name); }
.b-checkbox {
    &-before { z-index: z(components, checkbox, before); }
    &-after { z-index: z(components, checkbox, after); }
    &-icon { z-index: z(components, checkbox, icon); }
}
```

### 3. Get resulting CSS
```scss
.b-components { z-index: 1; }
.b-content { z-index: 2; }
.b-pop_up-menu { z-index: 3; }

.b-component_name { z-index: 1; }
.b-checkbox-before { z-index: 1; }
.b-checkbox-after { z-index: 2; }
.b-checkbox-icon { z-index: 3; }
```

*/
/*md
@no-stat

# Grids

## How to setup grids config for project

### Several grid configs for project

We can use several grid configs per project. To do this, we need to add a new grid name to the `$grids` map with settings.

```scss
$grids: (
	default: (
		//...
	),
	altered: (
		//...
	)
);
```

### Gaps / margin / column span configuration:

```scss
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	)
);
```

## Working with grids

### Development approaches

#### 1. Using `g-grid` mixin when css grid is applicable.

With features of `display: grid`. Please see [g-grid](02-components-g-grid.html) details.

#### 2. Using `grid-span` function to create custom layout based on (flex, float, inline-block, table etc.)

Could be used in conjunction with different display properties while maintaining their common features(floating, centering, etc.).
Please see [grid-span](01-core-functions-grid-span.html) details.

As example please see [flex based non semantic grid](05-blocks-guide-l-cols.html) like you could see before CSS grid era (Foundation, Bootstrap etc.).

#### 3. Get gaps / margin / column span to create your own grid layout system.

For that we have the next grid functions in `_grids_tools.scss`:
- grid-gutter
- grid-margin
- grid-columns

Please see [grid functions](00-configuration-grids_tools.html) details with usage examples.
*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get data from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# adjust-color-to-bg

This function used to adjust color of some element depending on provided background color.
As basis function using luminance with human-perceived criteria as breakpoint for colors
[See more details](http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef).

It is especially useful for crating flexible themes.

## Arguments

```
$backgroundColor - bg color
$colorInverse - color if bg is dark. If not provided would return $color-white
$colorNormal - color if bg is light. If not provided would return $color-text

adjust-color-to-bg($backgroundColor, $colorInverse, $colorNormal)
```

## Usage

```scss
.component {
	color: adjust-color-to-bg($color-bg-header-line-1);

	// => results default 'white' if background dark
	// => results default 'black' if background is light
}

.component-custom-inverse-color {
	color: adjust-color-to-bg($color-bg-footer, grey);

	// => results 'grey' if background dark
	// => results default 'black' if background is light
}

.component-all-custom-colors {
	color: adjust-color-to-bg($color-bg-footer, green, red);

	// => result 'green' if background dark
	// => result 'red' if background is light
}
```

Based on Hugo Giraudel [work](https://css-tricks.com/snippets/sass/luminance-color-function/)
*/
/*md
@no-stat

# grid-span

`grid-span` function returns the width which 1 or several columns are takes (including inner gutters).

It returns value in percents.

This could be used for direct set to **width, max-width, flex-basis, etc.** to create
custom grid layout.

### Parameters

```scss
@function grid-span($column: 1, $break: 'lg', $with-gutter: false, $grid: 'default')
```

## Examples

### Flex-basis example

```scss
.b-grid {
	display: flex;

	.b-grid__item {
		flex-basis: grid-span($column: 3);
	}
}
```

### Floated items example

```scss
.b-grid {
	.b-grid__item {
		float: left;
		width: grid-span($column: 2);
	}
}
```

### Inline-block items example

```scss
.b-grid {
	.b-grid__item {
		display: inline-block;
		max-width: grid-span($column: 2);
	}
}
```

Please see [grid](00-configuration-grids.html) for more grid usage examples.

*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 0.5625%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md

# g-text_overflow

This is global component designed to reduce text lines and add "..." in the end.

## Usage

```scss
.component-link {
	@include g-text_overflow;
}

.component-link {
	@include g-text_overflow(2);
}
```

*/
/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behavior of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md

# g-button

Designed to provide same styles of buttons across different components.
It is possible to use with `<button>` or `<a>` elements

## First type button

```html_example
<button type="submit" class="b-button">
	Sign in
</button>
```

## First type disabled button

```html_example
<button type="submit" class="b-button m-disabled">
	Sign in
</button>
```

## First type, full width button

```html_example
<button type="submit" class="b-button m-width_full">
	Sign in
</button>
```

## Second type button

```html_example
<button type="submit" class="b-button m-outline">
	Sign in
</button>
```

## Second type disabled button

```html_example
<button type="submit" class="b-button m-outline m-disabled">
	Sign in
</button>
```

## Second type, full width button

```html_example
<button type="submit" class="b-button m-outline m-width_full">
	Sign in
</button>
```

## Link button

```html_example
<button type="submit" class="b-button m-link">
	Sign in
</button>
```

## Medium height Link button

```html_example
<button type="submit" class="b-button m-link m-medium">
	Sign in
</button>
```

## Small height Link button

```html_example
<button type="submit" class="b-button m-link m-small">
	Sign in
</button>
```

*/
/*md

# g-button_icon_only

Designed to provide same styles of buttons that contain only icon (without any text)
across different components without explicit CSS class.

It is used for header menubar icons, hamburger menu items and dialog close button.

```scss
.b-dialog {
	// ...
	&-close {
		@include g-button_icon_only;
	}
}
```
*/
/*md

# g-radio

The component is generally used for choosing item which includes in the radio group.

It design to share same styles of radio across completely different
components:`b-payment_accordion`, `b-options_switch`, `b-refinement_radio`,
`b-variation_swatch`.

## Usage

Only one g-radio in a given group can be selected at the same time.

If user selects one option in the list that other options come to unselected.

`g-radio` has states: unchecked, checked, hover, disabled and invalid.

## Unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-2" class="b-radio-input"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-2">Some text</label>
</div>
```

## Checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-3" class="b-radio-input" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-3">Some text</label>
</div>
```

## Disabled unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-4" class="b-radio-input" disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-4">Some text</label>
</div>
```

## Disabled checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-5" class="b-radio-input" checked disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-5">Some text</label>
</div>
```

## Invalid unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-6" class="b-radio-input m-invalid"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-6">Some text</label>
</div>
```

## Invalid checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-7" class="b-radio-input m-invalid" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-7">Some text</label>
</div>
```

## Customization by SCSS

Radio button styles that used in several component.

Designed to use same style of radio in different components
ex: b-radio, b-payment_option, b-shipping_option, b-stores etc.

It provide styles only for icon element based on input node.

```scss
.b-option_switch {
	// ...
	&-input {
		@include g-radio(_input);
	}

	&-icon {
		@include g-radio(_icon);

		.b-option_switch-input:active + & {
			@include g-radio(_icon, m-active);
		}

		.b-option_switch-input:hover + & {
			@include g-radio(_icon, m-hover);
		}

		.b-option_switch-input:checked + & {
			@include g-radio(_icon, m-checked);
		}

		.b-option_switch-input[disabled] + & {
			@include g-radio(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-checkbox

This component allows user to choose between two mutually exclusive state
(checked or unchecked).

It design to share same styles of radio across completely different
components: `b-refinements_checkbox`, `b-comparison_checkbox` etc.

## Usage

A `g-checkbox` is used for select or unselect action items.

It is always tied to `<label>` that describes two opposite states.

The component usually points to choose settings or preferences.

`g-checkbox` has states (unchecked, checked, hover, disabled and invalid).

## Unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-1" />
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-1">Some text</label>
</div>
```

## Checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-2" checked/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-2">Some text</label>
</div>
```

## Disabled unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-3" disabled/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-3">Some text</label>
</div>
```

## Disabled checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-4" checked disabled/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-4">Some text</label>
</div>
```

## Invalid unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-5"/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-5">Some text</label>
</div>
```

## Invalid checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-6" checked/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-6">Some text</label>
</div>

```

## Customization by SCSS

Checkbox styles that used in several component.

Designed to use same style of checkbox in different components without additional CSS class.
ex: `b-checkbox`, `b-refinement_checkbox`, `b-account_preference` etc.

It provide styles only for icon element based on SVG.

```scss
.b-refinement_checkbox {
	// ...
	&-icon {
		@include g-checkbox(_icon);

		.b-refinement_checkbox:active & {
			@include g-checkbox(_icon, m-active);
		}

		.b-refinement_checkbox.m-checked & {
			@include g-checkbox(_icon, m-checked);
		}

		.b-refinement_checkbox.m-disabled & {
			@include g-checkbox(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-spinner

Global spinner component applied to different blocks that fetch data.

Designed to use same style of spinner in different components and on particular breakpoints.
Ex: `b-minicart_popup`, `b-suggestions`, `b-plp_grid`, `b-product_details`, `b-cart` etc.

```scss
.b-product_gallery {
	&.m-loading_long::before {
		@include g-spinner();
	}
	// ...
}
```
*/
/*md

# g-link

This component is designed to provide same styles of text-type links (hyperlinks)
across different components.

For UI type links see `g-link_ui`.

## Usage

```scss
// for regular cases
.b-user_content-link {
	@include g-link;
}

// for cases when text color is inverted (white, red etc) and action color will not
// be good (ex blue hover state on red alert banner)
.b-error_message-link {
	@include g-link(inherit);
}
```

*/
/*md

# g-link_ui

This component is designed to provide consistent styles of UI-type links across
different UI components. For example links in header or footer, that expected to be more
like buttons and do not have attributes that expected for text links (hyperlinks) -
to be underlined, have visited state etc.

## Usage

```scss
// for regular cases
.b-menu_bar-item {
	@include g-link_ui;
}

// for cases when text color is inverted (white, red etc) and action color will not
// be good (ex blue hover state on red alert banner)
.b-component-link {
	@include g-link_ui(inherit);
}
```

*/
/*md

# g-link_hamburger

Hamburger menu generic link that used in several component.

Designed to use same style of hamburger link in different components
ex: menu, account link, language switcher etc.

```scss
.b-menu {
	// ...
	&-item {
		@include media(sm) {
			@include g-link_hamburger;
		}
	}
}
```
*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-snap_scroll

Components that apply snap scroll CSS rules to different components and cases.

Designed to use same snap scroll functionality in different components and/or on
particular breakpoints.
Ex: b-carousel, b-product_gallery, .b-product_slider etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include g-snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-backdrop_dialog

Dialog window backdrop styles that used in several components and particular breakpoints.

Serve as overlay and container to hold dialog box inside + provide scroll on overflow.
This is solution for large dialogs that scrolled inside viewport.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-backdrop_dialog;
	}
	// ...
}
```
*/
/*md

# g-backdrop_panel

Backdrop (overlay) for panels designed to handle swipe-to-close animation.

Serve as regular overlay.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-backdrop_dialog;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
in the manner as it would be just wrapped into main container.

It could be used not only for standalone blocks, but also for page layouts.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# g-section_holder_header

Since header is differs from other container (g-section_holder)
we need special component with different `max-width` and `margin` than
`section_holder`.

This is global component designed to hold header of the site.

On projects it could be removed and changed to `section_holder`.

```scss
.l-header-inner {
	background: green;

	&-inner {
		@include g-section_holder_header;
	}
}
```
*/
/*md

# g-heading_*

Basic simple typography styles applied to different UI components.

This covers only very basic cases and should be extended on project.

```scss
.b-cart_empty {
	// ...

	&-title {
		@include g-heading_1;

		margin-bottom: 32px;
	}
}
```
*/
/*md

# g-accordion

Global accordion component

## Attributes

```
data-allow-toggle="true" - Flag that allows or disallows toggle
data-open-first="true" - Flag that open first item
data-allow-multiple="true" - Flag that allows or disallows multiple open items
```

## Simple accordion example

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="false"
    data-open-first="true"
    data-allow-multiple="false"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Accordion with multiple open items

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="true"
    data-open-first="false"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Init accordion on sm, md & lg devices

### Attributes

```
.b-accordion
	data-widget="accordion" - init for all viewports
	data-widget.sm="accordion" - init for sm viewports
	data-widget.sm.md="accordion" - init for sm & md viewports
	data-widget.sm.md.lg="accordion" - init for sm & md & lg viewports
.b-accordion-item
	data-widget="accordionItem" - init for all viewports
	data-widget.sm="accordionItem" - init for sm viewports
	data-widget.sm.md="accordionItem" - init for sm & md viewports
	data-widget.sm.md.lg="accordionItem" - init for sm & md & lg viewports
```

```html_example
<div
    data-id="descriptions"
    data-widget.sm.md.lg="accordion"
    data-allow-toggle="true"
    data-open-first="true"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Customization by SCSS

This implementation allow to use accordion for one viewport and any other component for rest viewports

```scss
.b-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);
	}

	&-title {
		@include g-accordion(_control);
	}

	&-content {
		@include g-accordion(_content);

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);
	}
}
```
*/
/*md

# g-grid

g-grid is layout component based on CSS grid.

It is designed to use project defined grid (see _grids.scss) into components where CSS grid is
applicable.

As the result this component declare CSS grid configuration. Ex:

```
// scss
@include g-grid();
// css
grid-gap: 20px;
grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
```

And that it could be used to place items inside this declared grid. Ex:

```scss
.b-grid {
	@include g-grid();

	.b-columns__item {
		@include media(lg-up) {
			grid-column: 2 / span 4;
			grid-row: 1 / 2;
		}

		@include media(md-down) {
			grid-column: grid-start / span 7;
		}
	}
}
```

Please see [grid](00-configuration-grids.html) for more grid usage examples.

*/
.l-grid_layout {
  margin: 0 auto;
  max-width: 1440px;
  padding-left: 88px;
  padding-right: 88px;
  background-color: var(--bg-layout-color);
  background-position: var(--bg-image-position);
  background-repeat: var(--bg-image-repeat);
  background-size: var(--bg-image-size);
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .l-grid_layout {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_top_sm .l-grid_layout-content {
    padding-top: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_top_sm .l-grid_layout-content {
    padding-top: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_top_sm .l-grid_layout-content {
    padding-top: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_top_md .l-grid_layout-content {
    padding-top: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_top_md .l-grid_layout-content {
    padding-top: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_top_md .l-grid_layout-content {
    padding-top: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_top_lg .l-grid_layout-content {
    padding-top: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_top_lg .l-grid_layout-content {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_top_lg .l-grid_layout-content {
    padding-top: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_top_xl .l-grid_layout-content {
    padding-top: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_top_xl .l-grid_layout-content {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_top_xl .l-grid_layout-content {
    padding-top: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_right_sm .l-grid_layout-content {
    padding-right: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_right_sm .l-grid_layout-content {
    padding-right: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_right_sm .l-grid_layout-content {
    padding-right: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_right_md .l-grid_layout-content {
    padding-right: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_right_md .l-grid_layout-content {
    padding-right: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_right_md .l-grid_layout-content {
    padding-right: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_right_lg .l-grid_layout-content {
    padding-right: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_right_lg .l-grid_layout-content {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_right_lg .l-grid_layout-content {
    padding-right: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_right_xl .l-grid_layout-content {
    padding-right: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_right_xl .l-grid_layout-content {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_right_xl .l-grid_layout-content {
    padding-right: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_bottom_sm .l-grid_layout-content {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_bottom_sm .l-grid_layout-content {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_bottom_sm .l-grid_layout-content {
    padding-bottom: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_bottom_md .l-grid_layout-content {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_bottom_md .l-grid_layout-content {
    padding-bottom: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_bottom_md .l-grid_layout-content {
    padding-bottom: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_bottom_lg .l-grid_layout-content {
    padding-bottom: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_bottom_lg .l-grid_layout-content {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_bottom_lg .l-grid_layout-content {
    padding-bottom: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_bottom_xl .l-grid_layout-content {
    padding-bottom: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_bottom_xl .l-grid_layout-content {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_bottom_xl .l-grid_layout-content {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_left_sm .l-grid_layout-content {
    padding-left: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_left_sm .l-grid_layout-content {
    padding-left: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_left_sm .l-grid_layout-content {
    padding-left: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_left_md .l-grid_layout-content {
    padding-left: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_left_md .l-grid_layout-content {
    padding-left: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_left_md .l-grid_layout-content {
    padding-left: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_left_lg .l-grid_layout-content {
    padding-left: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_left_lg .l-grid_layout-content {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_left_lg .l-grid_layout-content {
    padding-left: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-padding_left_xl .l-grid_layout-content {
    padding-left: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-padding_left_xl .l-grid_layout-content {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-padding_left_xl .l-grid_layout-content {
    padding-left: 80px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout {
    background-image: var(--bg-image-desktop);
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout {
    background-image: var(--bg-image-tablet);
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout {
    background-image: var(--bg-image-mobile);
  }
}
.l-grid_layout.m-full_bleed, .l-grid_layout.m-full_with_centered {
  max-width: 1920px;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-2_8_2 .l-grid_layout-item.m-lg_2 {
    display: none;
  }
}
.l-grid_layout-content {
  display: grid;
}
@media screen and (min-width: 1367px) {
  .l-grid_layout-content {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .l-grid_layout-content {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout-content {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout-content {
    grid-gap: 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
.m-full_with_centered .l-grid_layout-content {
  margin: 0 auto;
  max-width: 1440px;
  padding-left: 88px;
  padding-right: 88px;
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .m-full_with_centered .l-grid_layout-content {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .m-full_with_centered .l-grid_layout-content {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767.9px) {
  .m-full_with_centered .l-grid_layout-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.l-grid_layout-content.m-hide_gutters {
  grid-gap: 0;
}
.l-grid_layout-item {
  max-width: 100%;
}
.l-grid_layout-item.m-horizontal_left, .l-grid_layout-item.m-horizontal_center, .l-grid_layout-item.m-horizontal_right {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.l-grid_layout-item.m-horizontal_left.component--full-screen-width, .l-grid_layout-item.m-horizontal_center.component--full-screen-width, .l-grid_layout-item.m-horizontal_right.component--full-screen-width {
  width: calc(var(--vw, 1vw) * 100);
}
.l-grid_layout-item.m-horizontal_left {
  justify-content: flex-start;
}
.l-grid_layout-item.m-horizontal_center {
  justify-content: center;
}
.l-grid_layout-item.m-horizontal_right {
  justify-content: flex-end;
}
.l-grid_layout-item.m-vertical_top {
  align-self: flex-start;
}
.l-grid_layout-item.m-vertical_middle {
  align-self: center;
}
.l-grid_layout-item.m-vertical_bottom {
  align-self: flex-end;
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in {
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in {
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in {
    margin-bottom: 12px;
  }
}
.l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in {
    margin-bottom: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in {
    margin-bottom: 52px;
  }
}
.l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in {
    margin-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in {
    margin-bottom: 64px;
  }
}
.l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in {
    margin-bottom: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in {
    margin-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in {
    margin-bottom: 80px;
  }
}
.l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in:last-child {
  margin-bottom: 0;
}
.l-grid_layout-item_in {
  display: flex;
}
.l-grid_layout-item_in > div,
.l-grid_layout-item_in > section,
.l-grid_layout-item_in > .c-banner__link {
  width: 100%;
}
.l-grid_layout-item_in:only-child {
  height: 100%;
}
.l-grid_layout-item:not(.m-vertical_top):not(.m-vertical_middle):not(.m-vertical_bottom)[data-items=one] {
  align-self: auto;
}
.l-grid_layout-item.m-title {
  grid-column: span 6;
  order: -2;
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-title {
    grid-column: span 12;
  }
}
.l-grid_layout-item.m-fade_in > div, .l-grid_layout-item.m-fade_in > section {
  opacity: 0;
  transform: translateY(10%);
  transition: 1.2s ease-out;
  transition-property: transform, opacity;
}
.l-grid_layout-item.m-bounce {
  overflow: hidden;
}
.l-grid_layout-item.m-bounce > div, .l-grid_layout-item.m-bounce > section {
  opacity: 0;
  transform: scale(0.8);
}
.l-grid_layout-item.m-fade_in.m-animated > div, .l-grid_layout-item.m-fade_in.m-animated > section {
  opacity: 1;
  transform: translateY(0);
}
.l-grid_layout-item.m-bounce.m-animated > div, .l-grid_layout-item.m-bounce.m-animated > section {
  animation-duration: 1.2s;
  animation-fill-mode: both;
  animation-name: growIn;
}
.l-grid_layout-item.m-sm_6 {
  grid-column: span 6;
}
.l-grid_layout-item.m-sm_5 {
  grid-column: span 5;
}
.l-grid_layout-item.m-sm_4 {
  grid-column: span 4;
}
.l-grid_layout-item.m-sm_3 {
  grid-column: span 3;
}
.l-grid_layout-item.m-sm_2 {
  grid-column: span 2;
}
.l-grid_layout-item.m-sm_1 {
  grid-column: span 1;
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_12 {
    grid-column: span 12;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_11 {
    grid-column: span 11;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_10 {
    grid-column: span 10;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_9 {
    grid-column: span 9;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_8 {
    grid-column: span 8;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_7 {
    grid-column: span 7;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_6 {
    grid-column: span 6;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_5 {
    grid-column: span 5;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_4 {
    grid-column: span 4;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_3 {
    grid-column: span 3;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_2 {
    grid-column: span 2;
  }
}
@media screen and (min-width: 768px) {
  .l-grid_layout-item.m-md_1 {
    grid-column: span 1;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_12 {
    grid-column: span 12;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_11 {
    grid-column: span 11;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_10 {
    grid-column: span 10;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_9 {
    grid-column: span 9;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_8 {
    grid-column: span 8;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_7 {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_6 {
    grid-column: span 6;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_5 {
    grid-column: span 5;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_4 {
    grid-column: span 4;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_3 {
    grid-column: span 3;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_2 {
    grid-column: span 2;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout-item.m-lg_1 {
    grid-column: span 1;
  }
}
.l-grid_layout-item_title {
  padding-bottom: 20px;
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout-item_title {
    padding-bottom: 16px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_top_sm .m-title {
    padding-top: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_top_sm .m-title {
    padding-top: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_top_sm .m-title {
    padding-top: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_top_md .m-title {
    padding-top: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_top_md .m-title {
    padding-top: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_top_md .m-title {
    padding-top: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_top_lg .m-title {
    padding-top: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_top_lg .m-title {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_top_lg .m-title {
    padding-top: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_top_xl .m-title {
    padding-top: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_top_xl .m-title {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_top_xl .m-title {
    padding-top: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_right_sm .m-title {
    padding-right: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_right_sm .m-title {
    padding-right: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_right_sm .m-title {
    padding-right: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_right_md .m-title {
    padding-right: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_right_md .m-title {
    padding-right: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_right_md .m-title {
    padding-right: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_right_lg .m-title {
    padding-right: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_right_lg .m-title {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_right_lg .m-title {
    padding-right: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_right_xl .m-title {
    padding-right: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_right_xl .m-title {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_right_xl .m-title {
    padding-right: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_bottom_sm .m-title {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_bottom_sm .m-title {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_bottom_sm .m-title {
    padding-bottom: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_bottom_md .m-title {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_bottom_md .m-title {
    padding-bottom: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_bottom_md .m-title {
    padding-bottom: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_bottom_lg .m-title {
    padding-bottom: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_bottom_lg .m-title {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_bottom_lg .m-title {
    padding-bottom: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_bottom_xl .m-title {
    padding-bottom: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_bottom_xl .m-title {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_bottom_xl .m-title {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_left_sm .m-title {
    padding-left: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_left_sm .m-title {
    padding-left: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_left_sm .m-title {
    padding-left: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_left_md .m-title {
    padding-left: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_left_md .m-title {
    padding-left: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_left_md .m-title {
    padding-left: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_left_lg .m-title {
    padding-left: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_left_lg .m-title {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_left_lg .m-title {
    padding-left: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-custom.m-padding_left_xl .m-title {
    padding-left: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-custom.m-padding_left_xl .m-title {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-custom.m-padding_left_xl .m-title {
    padding-left: 80px;
  }
}
.l-grid_layout.m-custom .l-grid_layout-item {
  display: flex;
}
.l-grid_layout.m-custom .l-grid_layout-item.m-title {
  padding-bottom: 0;
}
.l-grid_layout.m-custom .l-grid_layout-item.m-title + .l-grid_layout-content {
  padding-top: 0;
}
.l-grid_layout.m-custom .l-grid_layout-item > div {
  width: 100%;
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-mobile_reversed .l-grid_layout-item:last-child {
    order: -1;
  }
}
@media screen and (max-width: 767.9px) {
  .l-grid_layout.m-3_9 .l-grid_layout-item:last-child, .l-grid_layout.m-4_8 .l-grid_layout-item:last-child, .l-grid_layout.m-3_3_6 .l-grid_layout-item:last-child {
    order: -1;
  }
}
.l-grid_layout.m-3_9.m-mobile_reversed .l-grid_layout-item:last-child, .l-grid_layout.m-4_8.m-mobile_reversed .l-grid_layout-item:last-child, .l-grid_layout.m-3_3_6.m-mobile_reversed .l-grid_layout-item:last-child {
  order: 1;
}

@media screen and (max-width: 767.9px) {
  .page-section.m-padding_top_sm .l-grid_layout-content {
    padding-top: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_top_sm .l-grid_layout-content {
    padding-top: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_top_sm .l-grid_layout-content {
    padding-top: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_top_md .l-grid_layout-content {
    padding-top: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_top_md .l-grid_layout-content {
    padding-top: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_top_md .l-grid_layout-content {
    padding-top: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_top_lg .l-grid_layout-content {
    padding-top: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_top_lg .l-grid_layout-content {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_top_lg .l-grid_layout-content {
    padding-top: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_top_xl .l-grid_layout-content {
    padding-top: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_top_xl .l-grid_layout-content {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_top_xl .l-grid_layout-content {
    padding-top: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_right_sm .l-grid_layout-content {
    padding-right: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_right_sm .l-grid_layout-content {
    padding-right: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_right_sm .l-grid_layout-content {
    padding-right: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_right_md .l-grid_layout-content {
    padding-right: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_right_md .l-grid_layout-content {
    padding-right: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_right_md .l-grid_layout-content {
    padding-right: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_right_lg .l-grid_layout-content {
    padding-right: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_right_lg .l-grid_layout-content {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_right_lg .l-grid_layout-content {
    padding-right: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_right_xl .l-grid_layout-content {
    padding-right: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_right_xl .l-grid_layout-content {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_right_xl .l-grid_layout-content {
    padding-right: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_bottom_sm .l-grid_layout-content {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_bottom_sm .l-grid_layout-content {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_bottom_sm .l-grid_layout-content {
    padding-bottom: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_bottom_md .l-grid_layout-content {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_bottom_md .l-grid_layout-content {
    padding-bottom: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_bottom_md .l-grid_layout-content {
    padding-bottom: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_bottom_lg .l-grid_layout-content {
    padding-bottom: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_bottom_lg .l-grid_layout-content {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_bottom_lg .l-grid_layout-content {
    padding-bottom: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_bottom_xl .l-grid_layout-content {
    padding-bottom: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_bottom_xl .l-grid_layout-content {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_bottom_xl .l-grid_layout-content {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_left_sm .l-grid_layout-content {
    padding-left: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_left_sm .l-grid_layout-content {
    padding-left: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_left_sm .l-grid_layout-content {
    padding-left: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_left_md .l-grid_layout-content {
    padding-left: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_left_md .l-grid_layout-content {
    padding-left: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_left_md .l-grid_layout-content {
    padding-left: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_left_lg .l-grid_layout-content {
    padding-left: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_left_lg .l-grid_layout-content {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_left_lg .l-grid_layout-content {
    padding-left: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-section.m-padding_left_xl .l-grid_layout-content {
    padding-left: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .page-section.m-padding_left_xl .l-grid_layout-content {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .page-section.m-padding_left_xl .l-grid_layout-content {
    padding-left: 80px;
  }
}

.l-plp_grid-pd_preview {
  display: none;
}
.l-plp_grid-banner {
  display: flex;
}
.l-plp_grid-banner_content {
  display: flex;
  flex-grow: 1;
}
.l-plp_grid-banner:nth-of-type(2), .l-plp_grid-pd_preview:nth-of-type(2) {
  grid-column: 2/4;
  grid-row: 2/4;
}
@media screen and (max-width: 767.9px) {
  .l-plp_grid-banner:nth-of-type(2), .l-plp_grid-pd_preview:nth-of-type(2) {
    grid-column: 1/-1;
    grid-row: 3/4;
  }
}
.l-page-container.m-edit_mode .l-plp_grid-pd_preview {
  align-items: center;
  background-color: hsl(210, 99%, 26%);
  border: 2px dashed hsl(210, 85%, 44%);
  display: flex;
  font-size: 16px;
  justify-content: center;
}

.l-static_page.m-full_width {
  margin-bottom: 0;
  max-width: 1920px;
  padding-left: 0;
  padding-right: 0;
}
.l-static_page-title {
  margin: 0 auto;
  max-width: 1440px;
  padding-left: 88px;
  padding-right: 88px;
  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .l-static_page-title {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-static_page-title {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-static_page-title {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 767.9px) {
  .l-static_page-title {
    text-align: center;
  }
}

.l-page-review {
  position: absolute;
  top: 0;
  width: 100%;
}
.l-page.m-edit_mode {
  padding-top: 75px;
}
@media screen and (max-width: 1023.9px) {
  .l-page.m-edit_mode {
    padding-top: 116px;
  }
}
.l-page.m-transparent_header .l-page-content {
  margin-top: -178px;
}
@media screen and (max-width: 1023.9px) {
  .l-page.m-transparent_header .l-page-content {
    margin-top: -64px;
  }
}
.l-page.m-transparent_header .b-menu_bar,
.l-page.m-transparent_header .l-header {
  background-color: transparent;
  transition: background-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media screen and (min-width: 1024px) {
  .l-page.m-transparent_header .l-header-inner {
    position: relative;
    z-index: 14;
  }
}
@media screen and (min-width: 1024px) {
  .l-page.m-transparent_header.b-header_stuck .b-menu_bar {
    background-color: hsl(0, 0%, 100%);
  }
}
.l-page.m-transparent_header.b-header_stuck .l-header {
  background-color: hsl(0, 0%, 100%);
}
.l-page.m-transparent_header .l-header-bottom_promo,
.l-page.m-transparent_header .b-header_category {
  display: none;
}
@media screen and (min-width: 1024px) {
  .l-page.m-transparent_header .b-menu_bar-link[aria-expanded=true],
  .l-page.m-transparent_header .b-menu_bar-link:hover,
  .l-page.m-transparent_header .b-menu_bar-item:hover .b-menu_bar-link {
    background-color: transparent;
  }
}
@media screen and (min-width: 1024px) {
  .l-page.m-transparent_header .b-menu_bar-flyout {
    display: block;
    opacity: 0;
    transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    visibility: hidden;
  }
}
@media screen and (min-width: 1024px) {
  .l-page.m-transparent_header .l-header.m-menu_bar-active {
    background-color: hsl(0, 0%, 0%);
    position: relative;
    transition: background-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    z-index: 14;
  }
}
@media screen and (min-width: 1024px) {
  .l-page.m-transparent_header .l-header.m-menu_bar-active ~ .b-menu_panel {
    background-color: hsl(0, 0%, 0%);
    transition: background-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
}
@media screen and (min-width: 1024px) {
  .l-page.m-transparent_header .l-header.m-menu_bar-active ~ .b-menu_panel .b-menu_bar-flyout[aria-hidden=false] {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes growIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.b-edit_mode .b-message.m-error {
  background-color: hsl(0, 33%, 45%);
  border: 2px dashed hsl(0, 100%, 42%);
  color: hsl(0, 100%, 42%);
}

.b-image_tile.m-horizontal_left, .b-image_tile.m-horizontal_center, .b-image_tile.m-horizontal_right {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.b-image_tile.m-horizontal_left.component--full-screen-width, .b-image_tile.m-horizontal_center.component--full-screen-width, .b-image_tile.m-horizontal_right.component--full-screen-width {
  width: calc(var(--vw, 1vw) * 100);
}
.b-image_tile.m-horizontal_left {
  justify-content: flex-start;
}
.b-image_tile.m-horizontal_center {
  justify-content: center;
}
.b-image_tile.m-horizontal_right {
  justify-content: flex-end;
}
.b-image_tile.m-vertical_top {
  align-self: flex-start;
}
.b-image_tile.m-vertical_middle {
  align-self: center;
}
.b-image_tile.m-vertical_bottom {
  align-self: flex-end;
}
@media screen and (max-width: 767.9px) {
  .b-image_tile.m-vertical_fill {
    align-items: flex-start;
  }
}
@media screen and (max-width: 767.9px) {
  .b-image_tile.m-vertical_fill-sm {
    align-self: stretch;
  }
}
.b-image_tile-container {
  display: flex;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-image_tile-container {
    max-width: var(--max-width);
  }
}
@media screen and (max-width: 767.9px) {
  .b-image_tile.m-vertical_fill-sm .b-image_tile-container {
    height: 100%;
  }
}
.b-image_tile-picture {
  background: rgba(0, 0, 0, 0.5);
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: var(--bg-padding, 100%);
  position: relative;
  width: 100%;
}
@media screen and (max-width: 767.9px) {
  .b-image_tile-picture {
    padding-bottom: var(--bg-padding-mobile);
  }
}
.b-image_tile-picture img {
  border: none;
  bottom: 0;
  color: hsl(0, 0%, 95%);
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  object-position: var(--img-obj-position);
}
@media screen and (max-width: 767.9px) {
  .b-image_tile-picture img {
    object-position: var(--img-sm-obj-position, var(--img-obj-position));
  }
}
@media screen and (min-width: 768px) {
  .b-image_tile-picture.m-fixed_height-md_up {
    height: auto;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .b-image_tile-picture.m-fixed_height-md_up img {
    position: initial;
  }
}
@media screen and (max-width: 767.9px) {
  .b-image_tile-picture.m-fixed_height-sm {
    height: auto;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 767.9px) {
  .b-image_tile-picture.m-fixed_height-sm img {
    position: initial;
  }
}

.b-text_block {
  background-color: var(--bg-text-block, transparent);
  width: 100%;
}
.b-text_block.m-vertical_top {
  align-items: flex-start;
}
.b-text_block.m-vertical_middle {
  align-items: center;
}
.b-text_block.m-vertical_bottom {
  align-items: flex-end;
}
.b-text_block.m-vertical_fill {
  align-self: stretch;
}
.b-text_block.m-horizontal_left, .b-text_block.m-horizontal_center, .b-text_block.m-horizontal_right {
  display: flex;
  flex-wrap: wrap;
}
.b-text_block.m-horizontal_left {
  justify-content: flex-start;
}
.b-text_block.m-horizontal_center {
  justify-content: center;
}
.b-text_block.m-horizontal_right {
  justify-content: flex-end;
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_top_sm .b-text_block-container {
    padding-top: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_top_sm .b-text_block-container {
    padding-top: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_top_sm .b-text_block-container {
    padding-top: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_top_md .b-text_block-container {
    padding-top: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_top_md .b-text_block-container {
    padding-top: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_top_md .b-text_block-container {
    padding-top: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_top_lg .b-text_block-container {
    padding-top: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_top_lg .b-text_block-container {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_top_lg .b-text_block-container {
    padding-top: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_top_xl .b-text_block-container {
    padding-top: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_top_xl .b-text_block-container {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_top_xl .b-text_block-container {
    padding-top: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_right_sm .b-text_block-container {
    padding-right: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_right_sm .b-text_block-container {
    padding-right: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_right_sm .b-text_block-container {
    padding-right: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_right_md .b-text_block-container {
    padding-right: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_right_md .b-text_block-container {
    padding-right: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_right_md .b-text_block-container {
    padding-right: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_right_lg .b-text_block-container {
    padding-right: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_right_lg .b-text_block-container {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_right_lg .b-text_block-container {
    padding-right: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_right_xl .b-text_block-container {
    padding-right: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_right_xl .b-text_block-container {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_right_xl .b-text_block-container {
    padding-right: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_bottom_sm .b-text_block-container {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_bottom_sm .b-text_block-container {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_bottom_sm .b-text_block-container {
    padding-bottom: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_bottom_md .b-text_block-container {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_bottom_md .b-text_block-container {
    padding-bottom: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_bottom_md .b-text_block-container {
    padding-bottom: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_bottom_lg .b-text_block-container {
    padding-bottom: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_bottom_lg .b-text_block-container {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_bottom_lg .b-text_block-container {
    padding-bottom: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_bottom_xl .b-text_block-container {
    padding-bottom: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_bottom_xl .b-text_block-container {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_bottom_xl .b-text_block-container {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_left_sm .b-text_block-container {
    padding-left: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_left_sm .b-text_block-container {
    padding-left: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_left_sm .b-text_block-container {
    padding-left: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_left_md .b-text_block-container {
    padding-left: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_left_md .b-text_block-container {
    padding-left: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_left_md .b-text_block-container {
    padding-left: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_left_lg .b-text_block-container {
    padding-left: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_left_lg .b-text_block-container {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_left_lg .b-text_block-container {
    padding-left: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block.m-padding_left_xl .b-text_block-container {
    padding-left: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block.m-padding_left_xl .b-text_block-container {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-text_block.m-padding_left_xl .b-text_block-container {
    padding-left: 80px;
  }
}
.b-text_block-container {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-text_block-container {
    width: var(--width, auto);
  }
}
.b-text_block pre {
  white-space: pre-line;
}
@media screen and (min-width: 1024px) {
  .b-text_block .hidden-lg {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-text_block .hidden-md {
    display: none;
  }
}
@media screen and (max-width: 767.9px) {
  .b-text_block .hidden-sm {
    display: none;
  }
}

.b-actions {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 32px;
  margin: 44px 0 0;
  position: relative;
  width: 100%;
}
.b-actions-item {
  width: var(--button-width, auto);
}
.b-actions-container.m-horizontal_left, .b-actions-container.m-horizontal_center, .b-actions-container.m-horizontal_right {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.b-actions-container.m-horizontal_left.component--full-screen-width, .b-actions-container.m-horizontal_center.component--full-screen-width, .b-actions-container.m-horizontal_right.component--full-screen-width {
  width: calc(var(--vw, 1vw) * 100);
}
.b-actions-container.m-horizontal_left {
  justify-content: flex-start;
}
.b-actions-container.m-horizontal_center {
  justify-content: center;
}
.b-actions-container.m-horizontal_right {
  justify-content: flex-end;
}
.b-actions-container.m-vertical_top {
  align-self: flex-start;
}
.b-actions-container.m-vertical_middle {
  align-self: center;
}
.b-actions-container.m-vertical_bottom {
  align-self: flex-end;
}
.b-actions-container .b-button {
  height: auto;
  min-height: 48px;
  padding-bottom: 4px;
  padding-top: 4px;
  text-align: center;
  white-space: normal;
  width: var(--button-width, auto);
}
.b-actions-container .b-button-icon_left {
  margin-right: 8px;
}
.b-actions-container .b-button-icon_right {
  margin-left: 8px;
}
.b-actions-container .b-button.m-link, .b-actions-container .b-button.m-link_action, .b-actions-container .b-button.m-link_regular, .b-actions-container .b-button.m-link_no_underline, .b-actions-container .b-button.m-link_small, .b-actions-container .b-button.m-link_small_bold {
  background: transparent;
  border: 0;
  border-color: transparent;
  color: inherit;
  cursor: pointer;
  font-weight: 400;
  height: auto;
  letter-spacing: 0;
  min-height: auto;
  padding: 0;
  text-decoration: underline;
  text-transform: none;
  border: 0;
  padding: 0;
}
@media not all and (pointer: coarse) {
  .b-actions-container .b-button.m-link:hover, .b-actions-container .b-button.m-link_action:hover, .b-actions-container .b-button.m-link_regular:hover, .b-actions-container .b-button.m-link_no_underline:hover, .b-actions-container .b-button.m-link_small:hover, .b-actions-container .b-button.m-link_small_bold:hover {
    color: hsl(0, 0%, 0%);
    text-decoration: none;
  }
}
.b-actions-container .b-button.m-link_action {
  font-size: 1.15rem;
  font-weight: 700;
  line-height: 1.5rem;
  padding-bottom: 6px;
  text-decoration: underline solid hsl(0, 0%, 0%);
  text-decoration-thickness: 2px;
  text-transform: none;
  text-underline-offset: 6px;
  transition: text-decoration-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media not all and (pointer: coarse) {
  .b-actions-container .b-button.m-link_action:hover {
    text-decoration: underline solid hsl(0, 0%, 0%);
    text-decoration-thickness: 2px;
  }
}
.b-actions-container .b-button.m-link_action.m-with_icon {
  text-decoration: none;
  text-transform: uppercase;
}
.b-actions-container .b-button.m-link_regular {
  font-size: 1.15rem;
}
.b-actions-container .b-button.m-link_no_underline {
  font-size: 1.15rem;
  text-decoration: none;
}
.b-actions-container .b-button.m-link_small_bold {
  font-weight: 700;
  text-decoration: none;
}
.b-actions.m-one-cta .b-actions-item {
  width: 100%;
}
.b-actions.m-one-cta .b-button {
  width: var(--button-width, auto);
}
.b-actions.m-column {
  flex-direction: column;
}
.b-actions.m-center {
  align-items: center;
  justify-content: center;
}
.b-actions.m-right {
  align-items: flex-end;
  justify-content: flex-end;
}

.btn {
  width: var(--button-width, auto);
  line-height: inherit;
  font-weight: 400;
  min-width: 100px;
}
.btn.disabled, .btn.disabled:hover, .btn.disabled:focus, .btn.disabled:active, .btn:disabled, .btn:disabled:hover, .btn:disabled:focus, .btn:disabled:active, .btn[disabled], .btn[disabled]:hover, .btn[disabled]:focus, .btn[disabled]:active {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #000;
  opacity: 1;
  outline: none;
  pointer-events: none;
}
.btn.m-link, .btn.m-link_action, .btn.m-link_regular, .btn.m-link_underline, .btn.m-link_small, .btn.m-link_small_bold {
  background: transparent;
  border: 0;
  border-color: transparent;
  color: inherit;
  cursor: pointer;
  font-weight: 400;
  height: auto;
  letter-spacing: 0;
  min-height: auto;
  padding: 0;
  text-decoration: underline;
  text-transform: none;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;
}
@media not all and (pointer: coarse) {
  .btn.m-link:hover, .btn.m-link_action:hover, .btn.m-link_regular:hover, .btn.m-link_underline:hover, .btn.m-link_small:hover, .btn.m-link_small_bold:hover {
    color: hsl(0, 0%, 0%);
    text-decoration: none;
  }
}
@media not all and (pointer: coarse) {
  .btn.m-link_regular:hover, .btn.m-link_underline:hover, .btn.m-link_small:hover, .btn.m-link_small_bold:hover {
    color: #3769e2;
  }
}
.btn.m-link_underline, .btn.m-link_small, .btn.m-link_action {
  position: relative;
}
.btn.m-link_underline::after, .btn.m-link_small::after, .btn.m-link_action::after {
  content: " ";
  position: absolute;
  bottom: 1px;
  display: block;
  width: 100%;
  border-bottom: 1px solid;
  transition: inherit;
}
@media not all and (pointer: coarse) {
  .btn.m-link_underline:hover::after, .btn.m-link_small:hover::after, .btn.m-link_action:hover::after {
    display: none;
  }
}
.btn.m-link_small, .btn.m-link_small_bold {
  font-size: 0.875rem;
}
.btn.m-link_small_bold, .btn.m-link_action {
  font-weight: 700;
}
.btn.m-link_action::after {
  border-bottom: 2px solid #e0e0e0;
  bottom: -5px;
}
@media not all and (pointer: coarse) {
  .btn.m-link_action:hover {
    color: #3769e2;
  }
  .btn.m-link_action:hover::after {
    display: block;
    border-color: #3769e2;
  }
}
.btn.m-link_action.m-with_icon {
  text-decoration: none;
  text-transform: uppercase;
}

.btn-type-1 {
  background-color: #e30714;
  border-color: #e30714;
  color: #fff;
  border-width: 2px;
}
.btn-type-1:hover:not(:active):not(:focus) {
  background-color: #fff;
  border-color: #e30714;
  color: #e30714 !important;
}
.btn-type-1:not(:disabled):not(.disabled):focus:not([disabled]), .btn-type-1:not(:disabled):not(.disabled):active:not([disabled]) {
  background-color: #c20c16;
  border-color: #c20c16;
  outline: 4px solid #fff !important;
  color: #fff;
  box-shadow: 0px 0px 8px 5px #c20c16;
}

.btn-type-2 {
  background-color: #fff;
  border-color: #fff;
  color: #424242;
  border-width: 2px;
}
.btn-type-2:hover {
  background-color: #424242;
  border-color: #fff;
  color: #fff !important;
}
.btn-type-2:not(:disabled):not(.disabled):focus:not([disabled]), .btn-type-2:not(:disabled):not(.disabled):active:not([disabled]) {
  background-color: #fff;
  border-color: #5e5c5c;
  outline: 4px solid #fff !important;
  color: #424242 !important;
  box-shadow: 0px 0px 8px 5px #5e5c5c;
  border-width: 2px;
}

.btn-type-3 {
  background-color: #424242;
  border-color: #424242;
  color: #fff;
  border-width: 2px;
}
.btn-type-3:hover:not(:active):not(:focus) {
  background-color: #fff;
  border-color: #424242;
  color: #424242 !important;
}
.btn-type-3:not(:disabled):not(.disabled):focus:not([disabled]), .btn-type-3:not(:disabled):not(.disabled):active:not([disabled]) {
  background-color: #424242;
  border-color: #989898;
  outline: 4px solid #fff !important;
  color: #fff;
  box-shadow: 0px 0px 8px 5px #989898;
}
.btn-type-3.disabled, .btn-type-3.disabled:hover, .btn-type-3.disabled:focus, .btn-type-3.disabled:active, .btn-type-3:disabled, .btn-type-3:disabled:hover, .btn-type-3:disabled:focus, .btn-type-3:disabled:active, .btn-type-3[disabled], .btn-type-3[disabled]:hover, .btn-type-3[disabled]:focus, .btn-type-3[disabled]:active {
  background-color: #000;
  border-color: #e0e0e0;
  color: #e0e0e0 !important;
  opacity: 1;
  outline: none;
  pointer-events: none;
}

.btn-type-4 {
  background-color: #fff;
  border-color: #fff;
  color: #e30714;
  border-width: 2px;
}
.btn-type-4:hover {
  background-color: #e30714;
  border-color: #fff;
  color: #fff !important;
}
.btn-type-4:not(:disabled):not(.disabled):focus:not([disabled]), .btn-type-4:not(:disabled):not(.disabled):active:not([disabled]) {
  background-color: #e30714;
  border-color: #e30714;
  outline: 4px solid #fff !important;
  color: #fff;
  box-shadow: 0px 0px 8px 5px #e30714;
}

.c-banner {
  display: flex;
  width: 100%;
}
.c-banner.m-vertical_top {
  align-items: flex-start;
}
.c-banner.m-vertical_middle {
  align-items: center;
}
.c-banner.m-vertical_bottom {
  align-items: flex-end;
}
.c-banner.m-vertical_fill {
  align-self: stretch;
}
@media screen and (max-width: 767.9px) {
  .c-banner.m-vertical_fill {
    align-items: flex-start;
  }
}
@media screen and (max-width: 767.9px) {
  .c-banner.m-vertical_fill-sm {
    align-items: stretch;
  }
}
.c-banner-inner {
  display: grid;
  width: 100%;
}
@media screen and (min-width: 1367px) {
  .c-banner-inner {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .c-banner-inner {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .c-banner-inner {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767.9px) {
  .c-banner-inner {
    grid-gap: 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
.c-banner .c-banner-inner {
  grid-gap: 0;
}
.c-banner-caption {
  grid-column: grid-start/grid-end;
  grid-row: 1/2;
  padding: 60px 88px;
}
.c-banner-caption.m-horizontal_left, .c-banner-caption.m-horizontal_center, .c-banner-caption.m-horizontal_right {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.c-banner-caption.m-horizontal_left.component--full-screen-width, .c-banner-caption.m-horizontal_center.component--full-screen-width, .c-banner-caption.m-horizontal_right.component--full-screen-width {
  width: calc(var(--vw, 1vw) * 100);
}
.c-banner-caption.m-horizontal_left {
  justify-content: flex-start;
}
.c-banner-caption.m-horizontal_center {
  justify-content: center;
}
.c-banner-caption.m-horizontal_right {
  justify-content: flex-end;
}
.c-banner-caption.m-vertical_top {
  align-self: flex-start;
}
.c-banner-caption.m-vertical_middle {
  align-self: center;
}
.c-banner-caption.m-vertical_bottom {
  align-self: flex-end;
}
@media screen and (max-width: 767.9px) {
  .c-banner-caption.m-sm-vertical_top {
    align-self: flex-start;
  }
  .c-banner-caption.m-sm-vertical_middle {
    align-self: center;
  }
  .c-banner-caption.m-sm-vertical_bottom {
    align-self: flex-end;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .c-banner-caption {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .c-banner-caption {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767.9px) {
  .c-banner-caption {
    padding: 48px 15px;
  }
}
.c-banner-caption.m-beneath_image {
  display: flex;
}
@media screen and (min-width: 768px) {
  .c-banner-caption.m-beneath_image {
    height: 100%;
    padding: 0;
  }
}
.c-banner-caption.m-beneath_image .c-banner_content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.c-banner-caption.m-beneath_bottom {
  grid-row: 2;
  padding: 0;
}
.c-banner-caption.m-beneath_bottom .c-banner_content {
  width: 100%;
}
.c-banner-caption.m-beneath_right {
  flex-direction: row-reverse;
}
@media screen and (max-width: 767.9px) {
  .c-banner-caption.m-sm-beneath_image {
    grid-row: 2;
    padding: 0;
  }
  .c-banner-caption.m-sm-beneath_image .c-banner_content {
    width: 100%;
  }
}
.l-plp_grid-banner .c-banner-caption {
  padding: 44px 16px;
}
.l-plp_grid-banner:nth-of-type(2) .c-banner-caption {
  padding: 24px;
}
@media screen and (min-width: 1024px) {
  .l-grid_layout.m-centered .c-banner-caption {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout.m-centered .c-banner-caption {
    padding-left: 4px;
    padding-right: 4px;
  }
}
.l-grid_layout.m-centered .c-banner-caption.m-beneath_image {
  padding: 0;
}
.c-banner-caption img {
  object-position: var(--content-obj-position);
}
.c-banner-picture {
  background: hsl(0, 0%, 95%);
  display: block;
  overflow: hidden;
  padding-bottom: 44.2477876106%;
  position: relative;
  width: 100%;
  background-color: transparent;
  grid-column: grid-start/grid-end;
  grid-row: 1/2;
  padding-bottom: var(--bg-padding, 44.2477876106%);
  z-index: 0;
}
@media screen and (max-width: 767.9px) {
  .c-banner-picture {
    padding-bottom: var(--bg-padding-mobile, --bg-padding);
  }
}
@media screen and (min-width: 768px) {
  .c-banner-picture.m-fixed_height-md_up {
    min-height: var(--min-height, auto);
    padding-bottom: 0;
  }
}
@media screen and (max-width: 767.9px) {
  .c-banner-picture.m-fixed_height-sm {
    min-height: var(--min-height-mobile, auto);
    padding-bottom: 0;
  }
}
.c-banner-picture img,
.c-banner-picture video {
  border: none;
  bottom: 0;
  color: hsl(0, 0%, 95%);
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.c-banner-picture img {
  object-position: var(--img-obj-position);
}
@media screen and (max-width: 767.9px) {
  .c-banner-picture img {
    object-position: var(--img-sm-obj-position, var(--img-obj-position));
  }
}
@media screen and (max-width: 767.9px) {
  .c-banner-disable_mobile {
    display: none;
  }
}
.c-banner .b-text_block {
  position: relative;
  width: 100%;
}

.c-banner__link {
  border: none;
}

.c-banner_content {
  background-color: var(--bg-content, transparent);
  max-width: 100%;
  position: relative;
  width: var(--content-width, auto);
}
@media screen and (max-width: 767.9px) {
  .c-banner_content {
    width: 100%;
  }
}
.c-banner_content.m-extra_padding {
  padding: 60px;
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .c-banner_content.m-extra_padding {
    padding: 28px;
  }
}
@media screen and (max-width: 767.9px) {
  .c-banner_content.m-extra_padding {
    padding: 20px;
  }
}
.c-banner_content-image {
  width: 100%;
}
.c-banner_content-picture {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.c-banner_content-picture img {
  border: none;
  bottom: 0;
  color: hsl(0, 0%, 95%);
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.b-product_ext {
  display: flex;
  width: 100%;
}
.b-product_ext.m-vertical_top {
  align-items: flex-start;
}
.b-product_ext.m-vertical_middle {
  align-items: center;
}
.b-product_ext.m-vertical_bottom {
  align-items: flex-end;
}
.b-product_ext.m-vertical_fill {
  align-self: stretch;
}
.b-product_ext .b-product_tile {
  width: 100%;
}
.b-product_ext .b-product_tile img {
  object-position: var(--img-obj-position);
}
.b-product_ext .b-product_tile-description {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}
.b-product_ext .b-promotion,
.b-product_ext .b-product_tile_swatches,
.b-product_ext .b-product_tile-no_available,
.b-product_ext .b-product_tile-price {
  margin-bottom: 0;
}
.b-product_ext .b-rating,
.b-product_ext .b-product_tile-no_available,
.b-product_ext .b-button {
  margin-top: 12px;
}
.b-product_ext .b-product_tile-price {
  margin-top: 4px;
}
@media screen and (max-width: 767.9px) {
  .b-product_ext .b-button {
    width: 100%;
  }
}

.b-category_tile.m-horizontal_left, .b-category_tile.m-horizontal_center, .b-category_tile.m-horizontal_right {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.b-category_tile.m-horizontal_left.component--full-screen-width, .b-category_tile.m-horizontal_center.component--full-screen-width, .b-category_tile.m-horizontal_right.component--full-screen-width {
  width: calc(var(--vw, 1vw) * 100);
}
.b-category_tile.m-horizontal_left {
  justify-content: flex-start;
}
.b-category_tile.m-horizontal_center {
  justify-content: center;
}
.b-category_tile.m-horizontal_right {
  justify-content: flex-end;
}
.b-category_tile.m-vertical_top {
  align-self: flex-start;
}
.b-category_tile.m-vertical_middle {
  align-self: center;
}
.b-category_tile.m-vertical_bottom {
  align-self: flex-end;
}
.b-category_tile-inner {
  display: grid;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-category_tile-inner {
    max-width: var(--max-width, 100%);
  }
}
.b-category_tile-link, .b-category_tile-picture, .b-category_tile-content {
  grid-column: 1/2;
  grid-row: 1/2;
}
.b-category_tile-link, .b-category_tile-picture,
.b-category_tile img {
  border-radius: var(--aspect-radio-border, 0);
}
.b-category_tile-link {
  border: 0;
}
.b-category_tile-picture {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: var(--bg-padding, 100%);
  position: relative;
  width: 100%;
}
.b-category_tile-picture img {
  border: none;
  bottom: 0;
  color: hsl(0, 0%, 95%);
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  object-position: var(--img-obj-position);
}
.b-category_tile-link .b-category_tile-picture {
  transform: scale(1);
  transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
.b-category_tile-link:hover .b-category_tile-picture {
  transform: scale(1.1);
}
.b-category_tile-content {
  display: flex;
  font-size: 18px;
  font-weight: 500;
  overflow: hidden;
  padding: 12px 12px;
  pointer-events: none;
  z-index: 1;
}
.b-category_tile-content.m-vertical_top {
  align-items: flex-start;
}
.b-category_tile-content.m-vertical_middle {
  align-items: center;
}
.b-category_tile-content.m-vertical_bottom {
  align-items: flex-end;
}
.b-category_tile-content.m-vertical_fill {
  align-self: stretch;
}
.b-category_tile-content a {
  pointer-events: auto;
}
.b-category_tile-content p {
  margin-top: revert;
}
.b-category_tile .b-text_block {
  width: 100%;
}

.b-header_ext.m-horizontal_left, .b-header_ext.m-horizontal_center, .b-header_ext.m-horizontal_right {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.b-header_ext.m-horizontal_left.component--full-screen-width, .b-header_ext.m-horizontal_center.component--full-screen-width, .b-header_ext.m-horizontal_right.component--full-screen-width {
  width: calc(var(--vw, 1vw) * 100);
}
.b-header_ext.m-horizontal_left {
  justify-content: flex-start;
}
.b-header_ext.m-horizontal_center {
  justify-content: center;
}
.b-header_ext.m-horizontal_right {
  justify-content: flex-end;
}
.b-header_ext.m-vertical_top {
  align-self: flex-start;
}
.b-header_ext.m-vertical_middle {
  align-self: center;
}
.b-header_ext.m-vertical_bottom {
  align-self: flex-end;
}
.b-header_ext-wrapper {
  display: flex;
}
@media screen and (min-width: 768px) {
  .b-header_ext-wrapper {
    max-width: var(--max-width, none);
  }
}
.b-header_ext-wrapper.m-pictute_before .b-header_ext-img {
  margin-right: 20px;
}
.b-header_ext-wrapper.m-pictute_after {
  flex-direction: row-reverse;
}
.b-header_ext-wrapper.m-pictute_after .b-header_ext-img {
  margin-left: 20px;
}
.b-header_ext-wrapper.m-pictute_above {
  flex-direction: column;
}
.b-header_ext-wrapper.m-pictute_above .b-header_ext-img {
  align-self: center;
  margin-bottom: 20px;
}
.b-header_ext-picture {
  display: block;
  height: 48px;
  position: relative;
  width: 48px;
}
.b-header_ext-picture img {
  border: none;
  bottom: 0;
  color: hsl(0, 0%, 95%);
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-header_ext .b-text_block {
  align-self: center;
}

.b-carousel {
  position: relative;
}
.b-carousel-title {
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-bottom: 36px;
  text-align: center;
}
.b-carousel-track {
  display: flex;
  overflow: hidden;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  -ms-scroll-chaining: none;
  scrollbar-width: none;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
}
.b-carousel-track::-webkit-scrollbar {
  display: none;
}
.b-carousel-track.m-mousemove_navigation {
  scroll-snap-type: unset;
}
.b-carousel-track.m-grabbing {
  cursor: grab;
  scroll-behavior: auto;
  user-select: none;
}
.b-carousel-track.m-grabbing::before {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.b-carousel-item {
  scroll-snap-align: start;
}
.b-carousel-ctrl {
  appearance: none;
  background-color: rgba(255, 255, 255, 0.26);
  border: none;
  color: #000;
  cursor: pointer;
  display: none;
  height: 48px;
  margin-top: -24px;
  position: absolute;
  text-align: center;
  top: 50%;
  transition: 0.4s linear;
  transition-property: background-color, opacity;
  user-select: none;
  width: 48px;
  z-index: 1;
}
@media not all and (pointer: coarse) {
  .b-carousel-ctrl:hover {
    background-color: #fff;
    color: color(base-5);
  }
}
.b-carousel-ctrl:focus {
  outline: none;
}
.b-carousel.m-inited .b-carousel-ctrl {
  display: block;
}
@media (max-width: 543.98px) {
  .b-carousel.m-inited .b-carousel-ctrl {
    display: none;
  }
}
.b-carousel-ctrl[disabled], .b-carousel.m-no_scroll .b-carousel-ctrl {
  opacity: 0;
  z-index: -1;
}
@media not all and (pointer: coarse) {
  .b-carousel-ctrl[disabled]:hover, .b-carousel.m-no_scroll .b-carousel-ctrl:hover {
    color: inherit;
  }
}
.b-carousel.m-no_scroll .b-carousel-ctrl {
  height: 0;
  padding: 0;
}
.b-carousel-ctrl.m-prev {
  left: 0;
}
.b-carousel-ctrl.m-next {
  right: 0;
}
.b-carousel-ctrl svg {
  height: 33px;
  width: 33px;
}
.b-carousel-pagination {
  bottom: 16px;
  display: none;
  justify-content: center;
  left: 9px;
  position: absolute;
  right: 9px;
  z-index: 1;
}
@media (max-width: 768.98px) {
  .b-carousel-pagination {
    justify-content: flex-start;
  }
}
.b-carousel.m-no_scroll .b-carousel-pagination {
  display: none;
}
.b-carousel-page {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  height: 20px;
  line-height: 20px;
  position: relative;
  text-align: center;
  width: 20px;
}
.b-carousel-page::before {
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 8px;
  content: "";
  display: inline-block;
  height: 8px;
  left: 50%;
  margin-inline-start: -4px;
  margin-top: -4px;
  position: absolute;
  top: 50%;
  transition: 0.4s linear;
  transition-property: width, height, margin;
  width: 8px;
}
.b-carousel-page.m-current::before {
  background-color: #424242;
  content: "";
  height: 14px;
  margin-inline-start: -7px;
  margin-top: -7px;
  width: 14px;
}
.b-carousel-item_wrapper {
  width: 100%;
}
@media screen and (max-width: 767.9px) {
  .b-carousel-item_wrapper.m-margin_bottom-sm .b-carousel-item_wrapper_in {
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-carousel-item_wrapper.m-margin_bottom-sm .b-carousel-item_wrapper_in {
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel-item_wrapper.m-margin_bottom-sm .b-carousel-item_wrapper_in {
    margin-bottom: 12px;
  }
}
.b-carousel-item_wrapper.m-margin_bottom-sm .b-carousel-item_wrapper_in:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767.9px) {
  .b-carousel-item_wrapper.m-margin_bottom-md .b-carousel-item_wrapper_in {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-carousel-item_wrapper.m-margin_bottom-md .b-carousel-item_wrapper_in {
    margin-bottom: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel-item_wrapper.m-margin_bottom-md .b-carousel-item_wrapper_in {
    margin-bottom: 52px;
  }
}
.b-carousel-item_wrapper.m-margin_bottom-md .b-carousel-item_wrapper_in:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767.9px) {
  .b-carousel-item_wrapper.m-margin_bottom-lg .b-carousel-item_wrapper_in {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-carousel-item_wrapper.m-margin_bottom-lg .b-carousel-item_wrapper_in {
    margin-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel-item_wrapper.m-margin_bottom-lg .b-carousel-item_wrapper_in {
    margin-bottom: 64px;
  }
}
.b-carousel-item_wrapper.m-margin_bottom-lg .b-carousel-item_wrapper_in:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767.9px) {
  .b-carousel-item_wrapper.m-margin_bottom-xl .b-carousel-item_wrapper_in {
    margin-bottom: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-carousel-item_wrapper.m-margin_bottom-xl .b-carousel-item_wrapper_in {
    margin-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel-item_wrapper.m-margin_bottom-xl .b-carousel-item_wrapper_in {
    margin-bottom: 80px;
  }
}
.b-carousel-item_wrapper.m-margin_bottom-xl .b-carousel-item_wrapper_in:last-child {
  margin-bottom: 0;
}

.b-carousel_ext .b-carousel-pagination_dot,
.b-carousel_simple .b-carousel-pagination_dot,
.b-carousel.m-products .b-carousel-pagination_dot {
  background: none;
  border: none;
  color: transparent;
  cursor: pointer;
  fill: gray;
  height: 34px;
  margin-inline-end: 6px;
  padding: 0;
  width: 34px;
}
@media not all and (pointer: coarse) {
  .b-carousel_ext .b-carousel-pagination_dot:hover,
  .b-carousel_simple .b-carousel-pagination_dot:hover,
  .b-carousel.m-products .b-carousel-pagination_dot:hover {
    fill: color("primary-1");
  }
}
.b-carousel_ext .b-carousel-pagination_dot:last-child,
.b-carousel_simple .b-carousel-pagination_dot:last-child,
.b-carousel.m-products .b-carousel-pagination_dot:last-child {
  margin-inline-end: 0;
}
.b-carousel_ext .b-carousel-pagination_dot[aria-disabled=true],
.b-carousel_simple .b-carousel-pagination_dot[aria-disabled=true],
.b-carousel.m-products .b-carousel-pagination_dot[aria-disabled=true] {
  cursor: initial;
}
.b-carousel_ext .b-carousel-pagination_dot svg,
.b-carousel_simple .b-carousel-pagination_dot svg,
.b-carousel.m-products .b-carousel-pagination_dot svg {
  transform: scale(0.6);
  transition: transform 0.4s linear;
}
.b-carousel_ext .b-carousel-pagination_dot.m-current,
.b-carousel_simple .b-carousel-pagination_dot.m-current,
.b-carousel.m-products .b-carousel-pagination_dot.m-current {
  fill: color("primary-1");
}
.b-carousel_ext .b-carousel-pagination_dot.m-current svg,
.b-carousel_simple .b-carousel-pagination_dot.m-current svg,
.b-carousel.m-products .b-carousel-pagination_dot.m-current svg {
  transform: scale(1);
}

.b-carousel_ext .b-carousel-pagination,
.b-carousel.m-products .b-carousel-pagination {
  position: static;
  justify-content: center;
  flex-wrap: nowrap;
}

@media (min-width: 769px) {
  .b-carousel.m-products {
    padding: 0 40px;
  }
}
.b-carousel.m-products.m-inited .carousel-item {
  display: block;
}
@media (max-width: 543.98px) {
  .b-carousel.m-products .b-carousel-track {
    margin: 0 -12px;
  }
}
.b-carousel.m-products .b-carousel-ctrl {
  height: auto;
  padding: 0;
  width: auto;
}
.b-carousel.m-products .b-carousel-item {
  padding: 5px 10px;
  max-width: 25%;
  min-width: 25%;
}
@media (min-width: 769px) and (max-width: 991.98px) {
  .b-carousel.m-products .b-carousel-item {
    max-width: 33.3%;
    min-width: 33.3%;
  }
}
@media (max-width: 543.98px) {
  .b-carousel.m-products .b-carousel-item {
    max-width: 75%;
    min-width: 75%;
  }
}
@media (max-width: 768.98px) {
  .b-carousel.m-products .b-carousel-pagination {
    display: none;
  }
}
.b-carousel.m-products .product {
  background: hsl(0, 0%, 73%);
  box-shadow: none;
  height: 100%;
}
.b-carousel.m-products .product-tile {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
}
.b-carousel.m-products .product-link-button {
  display: none;
}
.b-carousel.m-products .price {
  font-weight: normal;
}
.b-carousel.m-products .price-value {
  display: block;
}
.b-carousel.m-products .tile-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 20px;
}
.b-carousel.m-products .product-tile-master-link-wrap {
  flex-grow: 1;
}

.b-carousel_ext {
  overflow: hidden;
}
.b-carousel_ext.m-vertical_top {
  align-items: flex-start;
}
.b-carousel_ext.m-vertical_middle {
  align-items: center;
}
.b-carousel_ext.m-vertical_bottom {
  align-items: flex-end;
}
.b-carousel_ext.m-vertical_fill {
  align-self: stretch;
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-header_ext.m-horizontal_center {
    padding-left: 68px;
  }
}
.b-carousel_ext .b-carousel {
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
  opacity: 0;
  position: initial;
}
@media screen and (max-width: 767.9px) {
  .b-carousel_ext .b-carousel {
    margin-top: 8px;
  }
}
.b-carousel_ext .b-carousel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
}
.b-carousel_ext .b-carousel-header_title {
  flex-grow: 1;
}
.b-carousel_ext .b-carousel-nav {
  display: flex;
  margin-right: -14px;
  opacity: 0;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: visibility, opacity;
  visibility: hidden;
}
@media screen and (max-width: 767.9px) {
  .b-carousel_ext .b-carousel-nav {
    display: none;
  }
}
.b-carousel_ext .b-carousel-ctrl {
  background-color: transparent;
  margin-top: 0;
  position: initial;
}
.b-carousel_ext .b-carousel-ctrl[disabled] {
  opacity: 0.5;
  z-index: 0;
}
.b-carousel_ext .b-carousel-body {
  display: flex;
}
.b-carousel_ext .b-carousel-track {
  width: 100%;
}
.b-carousel_ext .b-carousel-item {
  display: flex;
  padding: 0 10px;
}
.b-carousel_ext .b-carousel-item.m-vertical_top {
  align-self: flex-start;
}
.b-carousel_ext .b-carousel-item.m-vertical_middle {
  align-self: center;
}
.b-carousel_ext .b-carousel-item.m-vertical_bottom {
  align-self: flex-end;
}
.b-carousel_ext .b-carousel-item.m-sm_6 {
  flex: 0 0 75%;
  max-width: 75%;
}
.b-carousel_ext .b-carousel-item.m-sm_5 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.b-carousel_ext .b-carousel-item.m-sm_4 {
  flex: 0 0 50%;
  max-width: 50%;
}
.b-carousel_ext .b-carousel-item.m-sm_3 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.b-carousel_ext .b-carousel-item.m-sm_2 {
  flex: 0 0 25%;
  max-width: 25%;
}
.b-carousel_ext .b-carousel-item.m-sm_1 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_12 {
    flex: 0 0 109.0909090909%;
    max-width: 109.0909090909%;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_10 {
    flex: 0 0 90.9090909091%;
    max-width: 90.9090909091%;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_9 {
    flex: 0 0 81.8181818182%;
    max-width: 81.8181818182%;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_8 {
    flex: 0 0 72.7272727273%;
    max-width: 72.7272727273%;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_7 {
    flex: 0 0 63.6363636364%;
    max-width: 63.6363636364%;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_6 {
    flex: 0 0 54.5454545455%;
    max-width: 54.5454545455%;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_5 {
    flex: 0 0 45.4545454545%;
    max-width: 45.4545454545%;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_4 {
    flex: 0 0 36.3636363636%;
    max-width: 36.3636363636%;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_3 {
    flex: 0 0 27.2727272727%;
    max-width: 27.2727272727%;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_2 {
    flex: 0 0 18.1818181818%;
    max-width: 18.1818181818%;
  }
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel-item.m-md_1 {
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_12 {
    flex: 0 0 109.0909090909%;
    max-width: 109.0909090909%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_10 {
    flex: 0 0 90.9090909091%;
    max-width: 90.9090909091%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_9 {
    flex: 0 0 81.8181818182%;
    max-width: 81.8181818182%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_8 {
    flex: 0 0 72.7272727273%;
    max-width: 72.7272727273%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_7 {
    flex: 0 0 63.6363636364%;
    max-width: 63.6363636364%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_6 {
    flex: 0 0 54.5454545455%;
    max-width: 54.5454545455%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_5 {
    flex: 0 0 45.4545454545%;
    max-width: 45.4545454545%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_4 {
    flex: 0 0 36.3636363636%;
    max-width: 36.3636363636%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_3 {
    flex: 0 0 27.2727272727%;
    max-width: 27.2727272727%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_2 {
    flex: 0 0 18.1818181818%;
    max-width: 18.1818181818%;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_ext .b-carousel-item.m-lg_1 {
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }
}
.b-carousel_ext .b-carousel.m-inited {
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .b-carousel_ext .b-carousel.m-inited.m-next_visible .b-carousel-nav, .b-carousel_ext .b-carousel.m-inited.m-prev_visible .b-carousel-nav {
    opacity: 1;
    visibility: visible;
  }
}
.b-carousel_ext .b-carousel.m-no_scroll .b-carousel-track.m-grabbing {
  cursor: default;
  user-select: auto;
}
.b-carousel_ext.m-hide_gutters .b-carousel {
  margin: 0;
}
.b-carousel_ext.m-hide_gutters .b-carousel-item {
  padding: 0;
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-carousel_ext .b-promo_box-picture.m-general {
    padding-bottom: 44.2477876106%;
  }
}
.b-carousel_ext .b-text_block {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .b-carousel.m-side-nav {
    flex-direction: row;
  }
  .b-carousel.m-side-nav .b-carousel-body {
    flex-grow: 1;
  }
  .b-carousel.m-side-nav .b-carousel-header_title {
    flex-grow: 0;
  }
  .b-carousel.m-side-nav .b-carousel-nav {
    display: flex;
    margin: 0 0 0 -14px;
  }
}
.b-carousel.m-side-nav .b-carousel-header {
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-carousel.m-side-nav .b-carousel-header {
    flex-shrink: 0;
    width: 25%;
  }
}
.b-carousel.m-side-nav .b-header_ext-wrapper {
  margin-bottom: 40px;
}
@media screen and (max-width: 767.9px) {
  .b-carousel.m-side-nav .b-header_ext-wrapper {
    margin-bottom: 0;
  }
}

.b-product_editorial {
  display: flex;
  width: 100%;
}
.b-product_editorial.m-vertical_top {
  align-items: flex-start;
}
.b-product_editorial.m-vertical_middle {
  align-items: center;
}
.b-product_editorial.m-vertical_bottom {
  align-items: flex-end;
}
.b-product_editorial.m-vertical_fill {
  align-self: stretch;
}
@media screen and (max-width: 767.9px) {
  .b-product_editorial .b-button {
    width: 100%;
  }
}
.b-product_editorial .b-product_tile {
  display: grid;
  grid-column-gap: 20px;
  grid-template: "image sold_out" "image title" "image description" "image price" "image promotion" "image swatches" "image rating" "image actions";
  grid-template-columns: 1fr minmax(auto, 50%);
  grid-template-rows: min-content;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 767.9px) {
  .b-product_editorial .b-product_tile {
    display: flex;
    flex-direction: column;
  }
}
.b-product_editorial .b-product_tile-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.b-product_editorial .b-product_tile-top {
  grid-area: image;
}
@media screen and (min-width: 768px) {
  .b-product_editorial .b-product_tile-top {
    margin-bottom: 0;
  }
}
.b-product_editorial .b-product_tile-price {
  grid-area: price;
}
.b-product_editorial .b-product_tile-price, .b-product_editorial .b-product_tile_swatches,
.b-product_editorial .b-product_tile .b-promotion {
  margin-bottom: 0;
}
.b-product_editorial .b-product_tile .b-promotion,
.b-product_editorial .b-product_tile .b-rating,
.b-product_editorial .b-product_tile .b-button {
  margin-top: 12px;
}
.b-product_editorial .b-product_tile-title {
  grid-area: title;
}
.b-product_editorial .b-product_tile-description {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-area: description;
  margin-bottom: 8px;
}
.b-product_editorial .b-product_tile_swatches {
  grid-area: swatches;
}
.b-product_editorial .b-product_tile .b-promotion {
  grid-area: promotion;
}
.b-product_editorial .b-product_tile .b-rating {
  grid-area: rating;
}
.b-product_editorial .b-product_tile-actions {
  grid-area: actions;
}
.b-product_editorial .b-product_tile-no_available {
  align-self: flex-end;
  grid-area: sold_out;
}
@media screen and (max-width: 767.9px) {
  .b-product_editorial .b-product_tile-no_available {
    align-self: flex-start;
  }
}
.b-product_editorial .b-product_tile img {
  object-position: var(--img-obj-position);
}

.b-carousel_simple {
  display: flex;
}
.b-carousel_simple.m-vertical_top {
  align-items: flex-start;
}
.b-carousel_simple.m-vertical_middle {
  align-items: center;
}
.b-carousel_simple.m-vertical_bottom {
  align-items: flex-end;
}
.b-carousel_simple.m-vertical_fill {
  align-self: stretch;
}
.b-carousel_simple .b-carousel {
  display: flex;
  flex-direction: column;
  margin: 0;
  max-width: 100%;
  width: 100%;
}
@media screen and (max-width: 1023.9px) {
  .b-carousel_simple .b-carousel.m-inited .b-carousel-ctrl {
    display: block;
  }
}
.b-carousel_simple .b-carousel-ctrl {
  background-color: transparent;
  border: 1px solid;
  border-radius: 50%;
  height: 30px;
  padding: 0;
  width: 30px;
  top: 50%;
}
.b-carousel_simple .b-carousel-ctrl[disabled] {
  opacity: 0.5;
  z-index: 1;
}
.b-carousel_simple .b-carousel-ctrl svg {
  height: 17px;
  width: 16px;
}
.b-carousel_simple .b-carousel-pagination {
  justify-content: initial;
  margin: 0 auto;
  min-height: 34px;
  order: 1;
  overflow: hidden;
  position: initial;
  width: 114px;
}
.b-carousel_simple .b-carousel-pagination_content {
  display: inline-flex;
  left: 40px;
  position: relative;
  transition: left cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
.b-carousel_simple .b-carousel-pagination_dot {
  border: none;
  color: transparent;
  cursor: pointer;
  fill: hsl(0, 0%, 46%);
  height: 34px;
  margin-inline-end: 6px;
  padding: 0;
  width: 34px;
}
@media not all and (pointer: coarse) {
  .b-carousel_simple .b-carousel-pagination_dot:hover {
    fill: hsl(223, 77%, 38%);
  }
}
.b-carousel_simple .b-carousel-pagination_dot:last-child {
  margin-inline-end: 0;
}
.b-carousel_simple .b-carousel-pagination_dot[aria-disabled=true] {
  cursor: initial;
}
.b-carousel_simple .b-carousel-pagination_dot svg {
  transform: scale(0.6);
  transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
.b-carousel_simple .b-carousel-pagination_dot.m-current {
  fill: hsl(210, 85%, 44%);
}
.b-carousel_simple .b-carousel-pagination_dot.m-current svg {
  transform: scale(1);
}
.b-carousel_simple .b-carousel-track.m-mousemove_navigation {
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}
.b-carousel_simple .b-carousel-item {
  max-width: 100%;
  min-width: 100%;
}
.b-carousel_simple .b-carousel-item .b-text_block {
  height: 100%;
  position: relative;
}
.b-carousel_simple .b-carousel-item .carousel-item {
  display: block;
}
.b-carousel_simple .b-carousel .product-tile-container-border {
  display: none;
}
.b-carousel_simple.m-type-numeric .b-carousel {
  display: flex;
  flex-direction: column;
}
.b-carousel_simple.m-type-numeric .b-carousel-actions {
  align-items: center;
  display: flex;
  order: 2;
  padding-top: 10px;
  position: relative;
  width: 170px;
  margin: 0 auto;
}
@media screen and (max-width: 767.9px) {
  .b-carousel_simple.m-type-numeric .b-carousel-actions {
    padding-top: 8px;
  }
}
.b-carousel_simple.m-type-numeric .b-carousel-ctrl {
  display: inline-block;
  margin-top: 0;
  position: relative;
  transform: none;
  height: 24px;
  border: 0;
}
.b-carousel_simple.m-type-numeric .b-carousel-ctrl svg {
  height: 24px;
  width: 24px;
}
.b-carousel_simple.m-type-numeric .b-carousel-pagination {
  display: none;
}
.b-carousel_simple.m-type-numeric .b-carousel-numeric_pagination {
  margin-top: 2px;
  text-align: center;
  width: 100%;
}
.b-carousel_simple.m-type-standard .b-carousel {
  padding: 0 60px;
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-carousel_simple.m-type-standard .b-carousel {
    padding: 0 44px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-carousel_simple.m-type-standard .b-carousel {
    padding: 0;
  }
}
.b-carousel_simple.m-type-standard .b-carousel-actions {
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  height: 100%;
}
@media screen and (min-width: 1024px) {
  .b-carousel_simple.m-type-standard .b-carousel-actions {
    left: 60px;
    right: 60px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-carousel_simple.m-type-standard .b-carousel-actions {
    left: 44px;
    right: 44px;
  }
}
.b-carousel_simple.m-type-standard .b-carousel-ctrl {
  margin-top: -15px;
}
@media screen and (min-width: 1024px) {
  .b-carousel_simple.m-type-standard .b-carousel-ctrl.m-prev {
    left: -40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-carousel_simple.m-type-standard .b-carousel-ctrl.m-prev {
    left: -34px;
  }
}
@media screen and (min-width: 1024px) {
  .b-carousel_simple.m-type-standard .b-carousel-ctrl.m-next {
    right: -40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-carousel_simple.m-type-standard .b-carousel-ctrl.m-next {
    right: -34px;
  }
}
.b-carousel_simple.m-type-standard .b-carousel-pagination .page {
  appearance: none;
  border: 0;
  box-shadow: none;
  background-color: hsl(0, 0%, 46%);
  border-radius: 50%;
  height: 12px;
  width: 10px;
  margin: 0 7px;
}
.b-carousel_simple.m-type-standard .b-carousel-pagination .page.m-current {
  background-color: #3769e2;
}
.b-carousel_simple.m-type-standard .b-carousel.m-inited .b-carousel-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.b-carousel_simple.m-hide_controls .b-carousel-actions, .b-carousel_simple.m-hide_controls .b-carousel-pagination {
  display: none;
}
.b-carousel_simple.m-hide_controls .b-carousel-track.m-grabbing {
  cursor: default;
  user-select: auto;
}
.l-grid_layout-item.m-lg_3 .b-carousel_simple.m-type-standard .b-carousel {
  padding: 0;
}
.l-grid_layout-item.m-lg_3 .b-carousel_simple.m-type-standard .b-carousel-actions {
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .l-grid_layout-item.m-md_4 .b-carousel_simple.m-type-standard .b-carousel {
    padding: 0;
  }
}

@keyframes hero-carousel-progress {
  from {
    stroke-dashoffset: 104;
  }
  to {
    stroke-dashoffset: 1;
  }
}
.b-hero_carousel {
  margin: 0 auto;
  max-width: 1920px;
  overflow: hidden;
  position: relative;
}
.b-hero_carousel-track {
  display: flex;
  overflow: hidden;
}
.b-hero_carousel-track.m-grabbing {
  cursor: grab;
  user-select: none;
}
.b-hero_carousel-track.m-grabbing::before,
.b-hero_carousel-track .b-promo_box-picture::before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.b-hero_carousel-item {
  height: 100%;
  left: 0;
  min-width: 100%;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: 1;
}
.b-hero_carousel-item:not(:first-child) {
  visibility: hidden;
}
.b-hero_carousel.m-initialized .b-hero_carousel-item {
  overflow: hidden;
  position: absolute;
  transition: cubic-bezier(0.56, 0.03, 0.47, 0.98) 0.8s;
  transition-property: transform, visibility;
  visibility: hidden;
}
.b-hero_carousel.m-initialized .b-hero_carousel-item.m-prev {
  transform: translateX(-100%);
}
.b-hero_carousel.m-initialized .b-hero_carousel-item.m-next {
  transform: translateX(100%);
}
.b-hero_carousel.m-initialized .b-hero_carousel-item.m-current {
  position: static;
  transform: translateX(0);
  visibility: visible;
}
.b-hero_carousel-ctrl {
  appearance: none;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  color: #000;
  cursor: pointer;
  display: none;
  height: 38px;
  margin-top: -24px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transition: 0.4s linear;
  transition-property: background-color, opacity;
  user-select: none;
  width: 38px;
  z-index: 2;
}
@media not all and (pointer: coarse) {
  .b-hero_carousel-ctrl:hover {
    background-color: #fff;
    color: #000;
  }
}
.b-hero_carousel.m-initialized .b-hero_carousel-ctrl {
  display: block;
}
@media screen and (max-width: 767.9px) {
  .b-hero_carousel.m-initialized .b-hero_carousel-ctrl {
    display: none;
  }
}
.b-hero_carousel-ctrl.m-prev {
  left: 40px;
}
@media screen and (max-width: 1023.9px) {
  .b-hero_carousel-ctrl.m-prev {
    left: 32px;
  }
}
.b-hero_carousel-ctrl.m-next {
  right: 40px;
}
@media screen and (max-width: 1023.9px) {
  .b-hero_carousel-ctrl.m-next {
    right: 32px;
  }
}
.b-hero_carousel-pagination {
  bottom: 15px;
  display: flex;
  justify-content: center;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 0.4s linear;
  visibility: hidden;
  z-index: 2;
}
@media screen and (max-width: 767.9px) {
  .b-hero_carousel-pagination {
    bottom: 24px;
    left: 30%;
  }
}
.b-hero_carousel.m-initialized .b-hero_carousel-pagination {
  opacity: 1;
  visibility: visible;
}
.b-hero_carousel-pagination_content {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 27px;
  display: flex;
  padding: 8px;
}
.b-hero_carousel-pagination_dots {
  display: flex;
  margin: 0 4px 0 0;
}
.b-hero_carousel-pagination_dot {
  background: none;
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  fill: hsl(0, 0%, 46%);
  height: 34px;
  margin-inline-end: 6px;
  padding: 0;
  position: relative;
  width: 34px;
}
@media not all and (pointer: coarse) {
  .b-hero_carousel-pagination_dot:hover {
    fill: #3769e2;
  }
}
.b-hero_carousel-pagination_dot.m-current {
  fill: #3769e2;
}
.b-hero_carousel-pagination_dot:last-child {
  margin-inline-end: 0;
}
.b-hero_carousel-pagination_dot[aria-disabled=true] {
  cursor: initial;
}
.b-hero_carousel-pagination_dot_outline {
  stroke: #fff;
  stroke-width: 2;
  transition: 0.4s ease;
  transition-property: stroke-width;
}
.b-hero_carousel-autoplay {
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  margin-inline-end: 4px;
  padding: 0;
}
.b-hero_carousel-autoplay.m-initialized {
  display: block;
}
.b-hero_carousel-autoplay.m-animated {
  animation: hero-carousel-progress linear;
}
.b-hero_carousel-autoplay_svg {
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 1;
}
.b-hero_carousel-autoplay_progress {
  stroke: #1a44a9;
  stroke-width: 3;
  fill: #fff;
}
.b-hero_carousel-autoplay.m-animated .b-hero_carousel-autoplay_progress {
  stroke: #3769e2;
}
.b-hero_carousel-autoplay_progress_back {
  stroke: white;
  stroke-width: 3;
}
.b-hero_carousel-autoplay_play, .b-hero_carousel-autoplay_pause {
  display: block;
  fill: #3769e2;
  opacity: 1;
  stroke: #3769e2;
  transition: opacity ease 0.2s;
}
.b-hero_carousel-autoplay[aria-pressed=false] .b-hero_carousel-autoplay_play {
  opacity: 0;
}
.b-hero_carousel-autoplay_pause {
  stroke-width: 3;
}
.b-hero_carousel-autoplay[aria-pressed=true] .b-hero_carousel-autoplay_pause {
  opacity: 0;
}
.b-hero_carousel-booking_widget .b-hint {
  display: flex;
  margin-bottom: 40px;
}
@media (min-width: 769px) {
  .b-hero_carousel-booking_widget .b-pdx_booking_widget.m-horizontal {
    margin: -40px auto 80px;
  }
}
@media (min-width: 769px) and (min-width: 769px) {
  .b-hero_carousel-booking_widget .b-pdx_booking_widget.m-overlap {
    bottom: 20px;
    left: 0;
    padding: 0 90px;
    position: absolute;
    top: 20px;
  }
}

.b-hero_carousel_ext {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}
.b-hero_carousel_ext.m-vertical_top {
  align-items: flex-start;
}
.b-hero_carousel_ext.m-vertical_middle {
  align-items: center;
}
.b-hero_carousel_ext.m-vertical_bottom {
  align-items: flex-end;
}
.b-hero_carousel_ext.m-vertical_fill {
  align-self: stretch;
}
.b-hero_carousel_ext.m-vertical_fill .b-hero_carousel-track,
.b-hero_carousel_ext.m-vertical_fill .c-banner.m-vertical_fill,
.b-hero_carousel_ext.m-vertical_fill .b-hero_carousel-item > .b-text_block {
  height: 100%;
}
.b-hero_carousel_ext.m-vertical_fill .b-hero_carousel-item {
  display: flex;
  flex-direction: column;
}
@media (min-width: 769px) {
  .b-hero_carousel_ext.m-with_booking .b-hero_carousel-pagination {
    bottom: 50px;
  }
}
.b-hero_carousel_ext.m-fade .m-initialized .b-hero_carousel-item {
  opacity: 0;
  transform: none;
  transition: opacity 0.4s linear, visibility 0s 0.4s;
}
.b-hero_carousel_ext.m-fade .m-initialized .b-hero_carousel-item.m-current {
  opacity: 1;
  transition: opacity 0.4s linear, visibility 0s;
}
.b-hero_carousel_ext .b-hero_carousel,
.b-hero_carousel_ext .experience-bookingWidget {
  width: 100%;
}

.b-video_banner {
  width: 100%;
}
.b-video_banner.m-vertical_top {
  align-self: flex-start;
}
.b-video_banner.m-vertical_middle {
  align-self: center;
}
.b-video_banner.m-vertical_bottom {
  align-self: flex-end;
}
.b-video_banner.m-vertical_top .b-video_banner-container {
  align-items: flex-start;
}
.b-video_banner.m-vertical_bottom .b-video_banner-container {
  align-items: flex-end;
}
.b-video_banner.m-vertical_middle .b-video_banner-container {
  align-items: center;
}
.b-video_banner.m-vertical_fill .b-text_block {
  height: 100%;
}
.b-video_banner .b-text_block.m-horizontal_left {
  text-align: left;
}
.b-video_banner .b-text_block.m-horizontal_left .b-actions {
  justify-content: flex-start;
}
.b-video_banner .b-text_block.m-horizontal_right {
  text-align: right;
}
.b-video_banner .b-text_block.m-horizontal_right .b-actions {
  justify-content: flex-end;
}
.b-video_banner .b-text_block.m-horizontal_center {
  text-align: center;
}
.b-video_banner .b-text_block.m-horizontal_center .b-actions {
  justify-content: center;
}
.b-video_banner-container {
  display: grid;
  grid-template-columns: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.b-video_banner-container::after {
  background-color: var(--bg-videoBanner-overlay, transparent);
  content: "";
  height: 100%;
  left: 0;
  opacity: var(--opacity, 100%);
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-video_banner-inner {
  grid-column: 1/2;
  grid-row: 1/2;
  padding-bottom: var(--bg-padding, 100%);
  position: relative;
}
@media screen and (max-width: 767.9px) {
  .b-video_banner-inner {
    padding-bottom: var(--bg-padding-mobile, 100%);
  }
}
.b-video_banner-inner iframe {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.b-video_banner-item {
  height: 100%;
  left: 0;
  margin: 0 auto;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-video_banner-item[aria-hidden=true] {
  visibility: hidden;
}
.b-video_banner-text_block {
  display: grid;
  grid-column: 1/2;
  grid-row: 1/2;
}
.b-video_banner-caption {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 50%;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.b-video_banner-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
}
.b-video_banner-ctrl {
  background-color: hsl(0, 0%, 100%);
  border-radius: 50%;
  height: 50px;
  pointer-events: none;
  position: relative;
  width: 50px;
  z-index: 1;
  border: 1px solid #e0e0e0;
}
.b-video_banner-ctrl::before {
  border-bottom: 9px solid transparent;
  border-left: 17px solid hsl(0, 0%, 0%);
  border-top: 9px solid transparent;
  content: "";
  left: 17px;
  position: absolute;
  top: 15px;
}
.b-video_banner-poster {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: visibility cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s 0.3s;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .b-video_banner-poster.m-hidden_md-up {
    display: none;
  }
}
.b-video_banner .b-image_tile-picture {
  height: 100%;
}
@media screen and (max-width: 767.9px) {
  .b-video_banner .b-image_tile-picture {
    padding-bottom: var(--bg-padding-mobile, 100%);
  }
}
.b-video_banner.m-vertical_fill {
  align-items: stretch;
}
.b-video_banner.m-vertical_fill .b-video_banner-item.m-external {
  object-fit: cover;
  object-position: center;
}
.b-video_banner.m-vertical_fill .b-image_tile-picture {
  padding-bottom: 0;
}
.b-video_banner.m-vertical_fill .b-video_banner-container {
  height: 100%;
}
.b-video_banner .b-text_block {
  background-color: transparent;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .b-video_banner .b-text_block {
    padding: 60px 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-video_banner .b-text_block {
    padding: 60px 32px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-video_banner .b-text_block {
    padding: 40px 12px;
  }
}
.b-video_banner .b-text_block-container {
  background-color: var(--bg-text-block, transparent);
}
.b-video_banner .b-text_block .b-button {
  pointer-events: auto;
}

.b-action_banner {
  align-self: flex-start;
  overflow: hidden;
  position: relative;
}
.b-action_banner-spots {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-action_banner.m-edit_mode .b-action_banner-spot {
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.b-hot_spot.m-edit_mode {
  pointer-events: all;
}
@media screen and (min-width: 768px) {
  .b-hot_spot.m-hidden-md_up {
    display: none;
  }
}
@media screen and (max-width: 767.9px) {
  .b-hot_spot.m-hidden-sm {
    display: none;
  }
}
.b-hot_spot-btn {
  cursor: pointer;
  height: 24px;
  left: var(--left-position, 0);
  position: absolute;
  top: var(--top-position, 0);
  transform: translate(-50%, -50%);
  width: 24px;
}
@media screen and (min-width: 1024px) {
  .b-hot_spot-btn:hover::before {
    opacity: 1;
    padding: 16px;
  }
  .b-hot_spot-btn:hover::after {
    opacity: 0.4;
    padding: 24px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-hot_spot-btn {
    left: var(--left-position-sm);
    top: var(--top-position-sm);
  }
}
.b-hot_spot-btn::before, .b-hot_spot-btn::after {
  border: 1px solid var(--bg-color, hsl(0, 0%, 100%));
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s, padding cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  width: 100%;
}
.b-hot_spot-btn.m-active::before {
  opacity: 1;
  padding: 16px;
}
.b-hot_spot-btn.m-active::after {
  opacity: 0.4;
  padding: 24px;
}
.b-hot_spot-sign {
  background-color: var(--bg-color, hsl(0, 0%, 100%));
  border-radius: 50%;
  display: block;
  height: 100%;
  position: relative;
}
.b-hot_spot-sign::before, .b-hot_spot-sign::after {
  background-color: var(--bg-color, hsl(0, 0%, 100%));
  content: "";
  display: block;
  filter: grayscale(1) contrast(100) invert(1);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.b-hot_spot-sign::after {
  height: 1px;
  width: 7px;
}
.b-hot_spot-sign::before {
  height: 7px;
  width: 1px;
}
.b-hot_spot-popup {
  background-color: var(--bg-color, hsl(0, 0%, 100%));
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s, visibility cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  visibility: hidden;
  width: 488px;
  z-index: 2;
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-hot_spot-popup {
    width: 50%;
  }
}
@media screen and (max-width: 767.9px) {
  .b-hot_spot-popup {
    width: 80%;
  }
}
.b-hot_spot-popup[aria-hidden=false] {
  transform: translateX(0);
  transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s, visibility 0s;
  visibility: visible;
}
.b-hot_spot-scrollbox {
  height: 100%;
  overflow-y: auto;
  padding: 44px 60px;
  width: 100%;
}
@media screen and (min-width: 1367px) {
  .b-hot_spot-scrollbox {
    padding: 92px 88px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-hot_spot-scrollbox {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.b-hot_spot-scrollbox::before {
  border-bottom: 44px solid var(--bg-color, hsl(0, 0%, 100%));
  border-top: 44px solid var(--bg-color, hsl(0, 0%, 100%));
  bottom: 0;
  content: "";
  left: 60px;
  pointer-events: none;
  position: absolute;
  right: 60px;
  top: 0;
  z-index: 1;
}
@media screen and (min-width: 1367px) {
  .b-hot_spot-scrollbox::before {
    border-width: 92px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-hot_spot-scrollbox::before {
    left: 16px;
    right: 16px;
  }
}
.b-hot_spot-scrollbox::-webkit-scrollbar {
  width: 2px;
}
.b-hot_spot-scrollbox::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 46%);
}
.b-hot_spot-content {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  justify-content: center;
  min-height: 100%;
}
.b-hot_spot-content > :last-child {
  margin-bottom: 0;
}
.b-hot_spot-content .b-badges {
  z-index: 0;
}
.b-hot_spot-close {
  color: var(--bg-color, hsl(0, 0%, 100%));
  cursor: pointer;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  z-index: 2;
}
@media screen and (max-width: 767.9px) {
  .b-hot_spot-close {
    top: -8px;
  }
}
.b-hot_spot-close svg {
  filter: grayscale(1) contrast(100) invert(1);
}
.b-hot_spot-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s, visibility cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  visibility: hidden;
  z-index: 1;
}
[aria-hidden=false] + .b-hot_spot-overlay {
  opacity: 1;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s, visibility 0s;
  visibility: visible;
}
.l-grid_layout.m-3_3_6 .b-hot_spot-popup, .l-grid_layout.m-6_3_3 .b-hot_spot-popup, .l-grid_layout.m-2_up .b-hot_spot-popup {
  width: 80%;
}

.b-accordion {
  box-shadow: none;
}
.b-accordion-header {
  margin-bottom: 20px;
}
.b-accordion-inner {
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.5);
}

.b-progress_bar {
  background-color: gray;
  display: block;
  margin: 0 auto;
  opacity: 0;
  padding-bottom: 38px;
  padding-top: 12px;
  position: sticky;
  transform: translateY(-2px);
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  visibility: hidden;
  z-index: 3;
}
.b-progress_bar.m-initialized {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 767.9px) {
  .b-progress_bar.m-hide_sm {
    display: none;
  }
}
.b-progress_bar-inner {
  margin: 0 auto;
  max-width: 1920px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-progress_bar-inner {
    padding-left: 28px;
    padding-right: 28px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-progress_bar-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.b-progress_bar-content {
  background-color: gray;
  height: 2px;
  position: relative;
}
.b-progress_bar-line {
  background-color: hsl(0, 0%, 0%);
  display: block;
  height: 2px;
  position: absolute;
  top: 0;
  width: 0;
}
.b-progress_bar-dot {
  color: gray;
  cursor: pointer;
  min-height: 24px;
  min-width: 24px;
  position: absolute;
  text-decoration: none;
  top: -12px;
  transform: translateX(-50%);
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-progress_bar-dot::before {
  background-color: gray;
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 0;
  margin: auto;
  outline: 4px solid hsl(0, 0%, 100%);
  position: absolute;
  right: 0;
  top: 9px;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  width: 8px;
}
.b-progress_bar-dot.m-active {
  color: hsl(0, 0%, 0%);
}
.b-progress_bar-dot.m-active::before {
  background-color: hsl(0, 0%, 0%);
}
@media screen and (min-width: 1024px) {
  .b-progress_bar-dot:hover {
    color: hsl(0, 0%, 0%);
  }
  .b-progress_bar-dot:hover::before {
    background-color: hsl(0, 0%, 0%);
  }
}
.b-progress_bar-dot_name {
  display: inline-block;
  max-width: 140px;
  overflow: hidden;
  padding-top: 20px;
  text-overflow: ellipsis;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  white-space: nowrap;
}
@media screen and (max-width: 767.9px) {
  .b-progress_bar-dot_name {
    opacity: 0;
  }
}
.b-progress_bar-dot.m-text_left .b-progress_bar-dot_name {
  margin-left: -8px;
  transform: translateX(50%);
}
@media screen and (max-width: 767.9px) {
  .b-progress_bar-dot:first-child .b-progress_bar-dot_name {
    opacity: 1;
  }
}
@media screen and (max-width: 767.9px) {
  .b-progress_bar-dot.m-active .b-progress_bar-dot_name {
    opacity: 0;
  }
}
@media screen and (max-width: 767.9px) {
  .b-progress_bar-dot.m-last_active .b-progress_bar-dot_name {
    opacity: 1;
  }
}
.b-progress_bar.m-hide_labels .b-progress_bar-dot_name {
  display: none;
}

.b-page_review-message {
  font-weight: 500;
  padding: 16px 20px;
  text-align: center;
}
.b-page_review-message svg {
  margin-right: 10px;
}
.b-page_review-link {
  text-decoration: underline;
}

.b-search_form.m-horizontal_left, .b-search_form.m-horizontal_center, .b-search_form.m-horizontal_right {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.b-search_form.m-horizontal_left.component--full-screen-width, .b-search_form.m-horizontal_center.component--full-screen-width, .b-search_form.m-horizontal_right.component--full-screen-width {
  width: calc(var(--vw, 1vw) * 100);
}
.b-search_form.m-horizontal_left {
  justify-content: flex-start;
}
.b-search_form.m-horizontal_center {
  justify-content: center;
}
.b-search_form.m-horizontal_right {
  justify-content: flex-end;
}
.b-search_form.m-vertical_top {
  align-self: flex-start;
}
.b-search_form.m-vertical_middle {
  align-self: center;
}
.b-search_form.m-vertical_bottom {
  align-self: flex-end;
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_top_sm .b-search_form-content {
    padding-top: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_top_sm .b-search_form-content {
    padding-top: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_top_sm .b-search_form-content {
    padding-top: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_top_md .b-search_form-content {
    padding-top: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_top_md .b-search_form-content {
    padding-top: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_top_md .b-search_form-content {
    padding-top: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_top_lg .b-search_form-content {
    padding-top: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_top_lg .b-search_form-content {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_top_lg .b-search_form-content {
    padding-top: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_top_xl .b-search_form-content {
    padding-top: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_top_xl .b-search_form-content {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_top_xl .b-search_form-content {
    padding-top: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_right_sm .b-search_form-content {
    padding-right: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_right_sm .b-search_form-content {
    padding-right: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_right_sm .b-search_form-content {
    padding-right: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_right_md .b-search_form-content {
    padding-right: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_right_md .b-search_form-content {
    padding-right: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_right_md .b-search_form-content {
    padding-right: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_right_lg .b-search_form-content {
    padding-right: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_right_lg .b-search_form-content {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_right_lg .b-search_form-content {
    padding-right: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_right_xl .b-search_form-content {
    padding-right: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_right_xl .b-search_form-content {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_right_xl .b-search_form-content {
    padding-right: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_bottom_sm .b-search_form-content {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_bottom_sm .b-search_form-content {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_bottom_sm .b-search_form-content {
    padding-bottom: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_bottom_md .b-search_form-content {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_bottom_md .b-search_form-content {
    padding-bottom: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_bottom_md .b-search_form-content {
    padding-bottom: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_bottom_lg .b-search_form-content {
    padding-bottom: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_bottom_lg .b-search_form-content {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_bottom_lg .b-search_form-content {
    padding-bottom: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_bottom_xl .b-search_form-content {
    padding-bottom: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_bottom_xl .b-search_form-content {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_bottom_xl .b-search_form-content {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_left_sm .b-search_form-content {
    padding-left: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_left_sm .b-search_form-content {
    padding-left: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_left_sm .b-search_form-content {
    padding-left: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_left_md .b-search_form-content {
    padding-left: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_left_md .b-search_form-content {
    padding-left: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_left_md .b-search_form-content {
    padding-left: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_left_lg .b-search_form-content {
    padding-left: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_left_lg .b-search_form-content {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_left_lg .b-search_form-content {
    padding-left: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-search_form.m-padding_left_xl .b-search_form-content {
    padding-left: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-search_form.m-padding_left_xl .b-search_form-content {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_form.m-padding_left_xl .b-search_form-content {
    padding-left: 80px;
  }
}
.b-search_form-content {
  max-width: var(--max-width, 100%);
  width: 100%;
}
@media screen and (max-width: 767.9px) {
  .b-search_form-content {
    max-width: 100%;
  }
}
.b-search_form .b-header_search-wrap {
  margin-bottom: 36px;
}
.b-search_form .b-header_search-title_no_results {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  text-align: start;
  word-break: break-word;
}
.b-search_form .b-header_search-suggestion {
  font-size: 16px;
  margin-top: 16px;
}
.b-search_form .b-header_search-keywords_wrap {
  display: inline-block;
}

@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_top_sm .b-service_tile-inner {
    padding-top: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_top_sm .b-service_tile-inner {
    padding-top: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_top_sm .b-service_tile-inner {
    padding-top: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_top_md .b-service_tile-inner {
    padding-top: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_top_md .b-service_tile-inner {
    padding-top: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_top_md .b-service_tile-inner {
    padding-top: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_top_lg .b-service_tile-inner {
    padding-top: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_top_lg .b-service_tile-inner {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_top_lg .b-service_tile-inner {
    padding-top: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_top_xl .b-service_tile-inner {
    padding-top: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_top_xl .b-service_tile-inner {
    padding-top: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_top_xl .b-service_tile-inner {
    padding-top: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_right_sm .b-service_tile-inner {
    padding-right: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_right_sm .b-service_tile-inner {
    padding-right: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_right_sm .b-service_tile-inner {
    padding-right: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_right_md .b-service_tile-inner {
    padding-right: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_right_md .b-service_tile-inner {
    padding-right: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_right_md .b-service_tile-inner {
    padding-right: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_right_lg .b-service_tile-inner {
    padding-right: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_right_lg .b-service_tile-inner {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_right_lg .b-service_tile-inner {
    padding-right: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_right_xl .b-service_tile-inner {
    padding-right: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_right_xl .b-service_tile-inner {
    padding-right: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_right_xl .b-service_tile-inner {
    padding-right: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_bottom_sm .b-service_tile-inner {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_bottom_sm .b-service_tile-inner {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_bottom_sm .b-service_tile-inner {
    padding-bottom: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_bottom_md .b-service_tile-inner {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_bottom_md .b-service_tile-inner {
    padding-bottom: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_bottom_md .b-service_tile-inner {
    padding-bottom: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_bottom_lg .b-service_tile-inner {
    padding-bottom: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_bottom_lg .b-service_tile-inner {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_bottom_lg .b-service_tile-inner {
    padding-bottom: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_bottom_xl .b-service_tile-inner {
    padding-bottom: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_bottom_xl .b-service_tile-inner {
    padding-bottom: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_bottom_xl .b-service_tile-inner {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_left_sm .b-service_tile-inner {
    padding-left: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_left_sm .b-service_tile-inner {
    padding-left: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_left_sm .b-service_tile-inner {
    padding-left: 12px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_left_md .b-service_tile-inner {
    padding-left: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_left_md .b-service_tile-inner {
    padding-left: 52px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_left_md .b-service_tile-inner {
    padding-left: 52px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_left_lg .b-service_tile-inner {
    padding-left: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_left_lg .b-service_tile-inner {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_left_lg .b-service_tile-inner {
    padding-left: 64px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-service_tile.m-padding_left_xl .b-service_tile-inner {
    padding-left: 48px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-service_tile.m-padding_left_xl .b-service_tile-inner {
    padding-left: 44px;
  }
}
@media screen and (min-width: 1024px) {
  .b-service_tile.m-padding_left_xl .b-service_tile-inner {
    padding-left: 80px;
  }
}

.b-product_attributes-empty {
  background-color: hsl(210, 99%, 26%);
  border: 2px dashed hsl(210, 85%, 44%);
  font-size: 18px;
  font-weight: 600;
  line-height: 2;
  padding: 100px 0;
  text-align: center;
  text-transform: uppercase;
}
.b-product_attributes-empty .b-message {
  background-color: transparent;
  margin: 0;
  padding: 0;
  text-transform: initial;
}

.b-product_attribute_content {
  width: 100%;
}
.b-product_attribute_content-title {
  background-color: hsl(210, 99%, 26%);
  border: 2px dashed hsl(210, 85%, 44%);
  font-size: 12px;
  padding: 4px;
  text-align: center;
}
.b-carousel-item.m-duplicate_attribute .b-product_attribute_content-title, .b-product_attributes_gallery-item.m-duplicate_attribute .b-product_attribute_content-title {
  background-color: hsl(0, 33%, 45%);
  border: 2px dashed hsl(0, 100%, 42%);
  color: hsl(0, 100%, 42%);
}

.b-product_attributes_gallery-title {
  margin-bottom: 40px;
}
@media screen and (max-width: 767.9px) {
  .b-product_attributes_gallery-title {
    margin-bottom: 20px;
  }
}
.b-product_attributes_gallery-main {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-main {
    margin: 0 -10px;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item {
    padding: 0 10px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-product_attributes_gallery-item {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_12 {
    flex: 0 0 100%;
    max-width: 100%;
    min-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    min-width: 91.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    min-width: 83.3333333333%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_9 {
    flex: 0 0 75%;
    max-width: 75%;
    min-width: 75%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    min-width: 66.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    min-width: 58.3333333333%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_6 {
    flex: 0 0 50%;
    max-width: 50%;
    min-width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    min-width: 41.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    min-width: 33.3333333333%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_3 {
    flex: 0 0 25%;
    max-width: 25%;
    min-width: 25%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    min-width: 16.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .b-product_attributes_gallery-item.m-md_1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    min-width: 8.3333333333%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_12 {
    flex: 0 0 100%;
    max-width: 100%;
    min-width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    min-width: 91.6666666667%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    min-width: 83.3333333333%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_9 {
    flex: 0 0 75%;
    max-width: 75%;
    min-width: 75%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    min-width: 66.6666666667%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    min-width: 58.3333333333%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_6 {
    flex: 0 0 50%;
    max-width: 50%;
    min-width: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    min-width: 41.6666666667%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    min-width: 33.3333333333%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_3 {
    flex: 0 0 25%;
    max-width: 25%;
    min-width: 25%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    min-width: 16.6666666667%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_attributes_gallery-item.m-lg_1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    min-width: 8.3333333333%;
  }
}

/*md

# b-promo_tile

This is kind of small banners that used in Page designer as ImageTiles

This is special type of banners that designed to be used in different layout arrangement.

## With caption

```html_example
<figure class="b-promo_tile" style="width: 300px">
	<picture class="b-promo_tile-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
			alt="Just dummy image."
			width="336"
			height="420"
		/>
	</picture>
	<figcaption class="b-promo_tile-caption">
		<p class="b-promo_tile-title">
			New in
		</p>
	</figcaption>
</figure>
```

## Without caption

```html_example
<div class="b-promo_tile" style="width: 300px">
	<picture class="b-promo_tile-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
			alt="Just dummy image."
			width="336"
			height="420"
		/>
	</picture>
</div>
```

*/
.b-promo_tile {
  display: grid;
  height: 100%;
}
.b-promo_tile-picture {
  background: hsl(0, 0%, 95%);
  display: block;
  overflow: hidden;
  padding-bottom: 133.3333333333%;
  position: relative;
  width: 100%;
  grid-column: 1/2;
  grid-row: 1/2;
}
.b-promo_tile-picture img {
  border: none;
  bottom: 0;
  color: hsl(0, 0%, 95%);
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-promo_tile-caption {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1/2;
  grid-row: 1/2;
  justify-content: flex-end;
  padding: 48px 12px;
  text-align: center;
  z-index: 1;
}
@media screen and (max-width: 767.9px) {
  .b-promo_tile-caption {
    padding-bottom: 36px;
    padding-top: 36px;
  }
}
.b-promo_tile.m-caption_below .b-promo_tile-caption {
  grid-row: 2/2;
  padding-bottom: 20px;
  padding-top: 20px;
}
.b-promo_tile-caption .b-button {
  margin-top: 20px;
}
.b-promo_tile-title {
  font-size: 18px;
  font-weight: 500;
}

.b-promo_tiles_grid.m-custom_menu .b-promo_tile-title {
  font-size: 16px;
}

/*md

# b-promo_tiles_grid

This component designed to be starting point for developing different kind of
symmetrical and asymmetrical banner layouts.

It used in Page Designer as tilesGrid. See tilesGrid.isml and imageTile.isml for details.

## Basic component implementation

```html_example
<section class="b-promo_tiles_grid">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

## Layouts

In Launch 360, as example, we provide with 5 basic layouts,
but it could be easily extended to different combinations and any client needs.

### lg-4 md-4 sm-2

```html_example
<section class="b-promo_tiles_grid m-lg_4 m-md_4 m-sm_2">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

### m-lg_4 m-md_4 m-sm_1

```html_example
<section class="b-promo_tiles_grid m-lg_4 m-md_4 m-sm_1">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

### m-lg_3 m-md_3 m-sm_2-1

```html_example
<section class="b-promo_tiles_grid m-lg_3 m-md_3 m-sm_2-1">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

### m-lg_3 m-md_3 m-sm_1

```html_example
<section class="b-promo_tiles_grid m-lg_3 m-md_3 m-sm_1">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
	</div>
</section>
```

### m-lg_2 m-md_2 m-sm_2

```html_example
<section class="b-promo_tiles_grid m-lg_2 m-md_2 m-sm_2">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

### m-lg_2 m-md_2 m-sm_1

```html_example
<section class="b-promo_tiles_grid m-lg_2 m-md_2 m-sm_1">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

## Amount for image tiles

Technically it holds unlimited amount of imageTiles (b-promo_tile).

```html_example
<section class="b-promo_tiles_grid m-lg_3 m-md_3 m-sm_2-1">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
	</div>
</section>
```

*/
.b-promo_tiles_grid-title {
  margin: 96px 0 40px;
  text-align: center;
}
@media screen and (max-width: 767.9px) {
  .b-promo_tiles_grid-title {
    margin-top: 48px;
  }
}
.b-promo_tiles_grid-content {
  display: grid;
  grid-gap: 0;
}
@media screen and (min-width: 1367px) {
  .b-promo_tiles_grid-content {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .b-promo_tiles_grid-content {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-promo_tiles_grid-content {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_tiles_grid-content {
    grid-gap: 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1367px) {
  .b-promo_tiles_grid-content {
    grid-gap: 0;
  }
}
.b-promo_tiles_grid-item {
  display: block;
  grid-column: span 3;
}
@media screen and (min-width: 768px) {
  .b-promo_tiles_grid.m-lg_2 .b-promo_tiles_grid-item {
    grid-column: span 6;
  }
  .b-promo_tiles_grid.m-lg_3 .b-promo_tiles_grid-item {
    grid-column: span 4;
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_tiles_grid-item {
    grid-column: span 3;
  }
  .b-promo_tiles_grid.m-sm_1 .b-promo_tiles_grid-item {
    grid-column: span 6;
  }
  .b-promo_tiles_grid.m-sm_2_1 .b-promo_tiles_grid-item:nth-child(3n) {
    grid-column: span 6;
  }
}
.b-promo_tiles_grid-item_link {
  color: inherit;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
  display: block;
}
@media not all and (pointer: coarse) {
  .b-promo_tiles_grid-item_link:hover {
    color: inherit;
    text-decoration: underline;
  }
}
.b-promo_tiles_grid .product-tile-container .product {
  display: block;
}

.b-promo_tiles_grid.m-custom_menu {
  margin: 8px auto 40px;
  max-width: 66.6666666667%;
  width: 100%;
}

/*md

# b-option_switch

This component allows user to choose one option for ex. shipping method, saved address etc.

```html_example
<div class="b-option_switch">
	<div class="b-option_switch-inner">
		<input id="shippingMethod-001" class="b-option_switch-input" name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio" value="001" data-value="001" data-ref="field" data-event-change="update">
		<div class="b-option_switch-icon"></div>
		<label class="b-option_switch-label" for="shippingMethod-001">
			<div class="b-option_switch-label_surface">
				<span class="b-option_switch-name">Ground</span>
				Free
			</div>
			<span class="b-option_switch-description">
				7-10 Business Days
			</span>
		</label>
	</div>
</div>
<div class="b-option_switch">
	<div class="b-option_switch-inner">
		<input id="shippingMethod-002" class="b-option_switch-input" name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio" value="002" data-value="002" data-ref="field" data-event-change="update" checked="">
		<div class="b-option_switch-icon"></div>
		<label class="b-option_switch-label" for="shippingMethod-002">
			<div class="b-option_switch-label_surface">
				<span class="b-option_switch-name">2-Day Express</span>
				$9.99
			</div>
			<span class="b-option_switch-description">
				2 Business Days
			</span>
		</label>
	</div>
</div>
```

*/
.b-option_switch {
  --option-indent: 24px;
  padding: var(--option-indent);
  user-select: none;
}
.b-option_switch-inner {
  display: flex;
  position: relative;
}
.b-option_switch-label {
  cursor: pointer;
  display: block;
  width: 100%;
}
.b-option_switch-label::before {
  border: 1px solid hsl(0, 0%, 0%);
  border-radius: 0;
  bottom: calc(-1 * var(--option-indent));
  content: "";
  left: calc(-1 * var(--option-indent));
  margin-top: -1px;
  position: absolute;
  right: calc(-1 * var(--option-indent));
  top: calc(-1 * var(--option-indent));
}
.b-option_switch-input:checked ~ .b-option_switch-label::before {
  border-color: hsl(0, 0%, 46%);
  z-index: 1;
}
.b-option_switch-label_surface {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
}
.b-option_switch-label_edit {
  margin-inline-start: 24px;
  z-index: 1;
}
.b-option_switch-input {
  cursor: pointer;
  height: 20px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 20px;
}
.b-option_switch-icon {
  appearance: none;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin-inline-end: 12px;
  min-height: 20px;
  min-width: 20px;
  position: relative;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: border;
  vertical-align: sub;
  width: 20px;
  z-index: -1;
  position: relative;
  z-index: initial;
}
.b-option_switch-icon::-ms-check {
  display: none;
}
.b-option_switch-icon::before {
  background-color: hsl(0, 0%, 100%);
  border-radius: 10px;
  content: "";
  height: 10px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: transform;
  width: 10px;
}
.b-option_switch-input:active + .b-option_switch-icon {
  transform: scale(0.9);
}
.b-option_switch:hover .b-option_switch-icon {
  border-color: hsl(0, 0%, 0%);
}
.b-option_switch-input:checked + .b-option_switch-icon {
  border-color: hsl(0, 0%, 100%);
  border-width: 2px;
}
.b-option_switch-input:checked + .b-option_switch-icon::before {
  transform: translate(-50%, -50%) scale(1);
}
.b-option_switch-name {
  font-size: 16px;
  font-weight: bold;
}
.b-option_switch-description {
  color: hsl(0, 0%, 46%);
  display: block;
  font-weight: 500;
  margin-top: 4px;
  position: relative;
}
.b-option_switch-promotion {
  margin: 8px 0 0;
}
.b-option_switch-promotion .b-promotion {
  margin: 0;
}

.b-option_switch.m-cart {
  --option-indent: 16px;
}

/*md

# b-price

Designed to provide same styles of pricing across different components without explicit CSS class.
`.b-price` inherit font-size from parent wrapper or general font-size

## Regular price

```html_example
<div class="b-price">
	<span class="b-price-item">
		$9.99
	</span>
</div>
```

## Sale price

```html_example
<div class="b-price">
	<span class="b-sr_only">Was</span>
	<span class="b-price-item m-old">
		$11.99
	</span>
	<span class="b-sr_only">, is</span>
	<span class="b-price-item">
		$9.99
	</span>
</div>
```

## Price range

```html_example
<div class="b-price">
	<span class="b-price-item">
		$9.99
	</span>
	<span class="b-price-divider">-</span>
	<span class="b-price-item">
		$11.99
	</span>
</div>
```

## Free price

```html_example
<div class="b-price">
	<span class="b-price-item m-free">
		FREE
	</span>
</div>
```

*/
.b-price {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
  position: relative;
}
.b-price-item {
  display: inline-block;
  margin-inline-end: 8px;
  white-space: nowrap;
}
.b-price-item:last-child {
  margin: 0;
}
.b-price-item.m-old {
  color: hsl(0, 0%, 46%);
  text-decoration: line-through;
}
.b-price-item.m-old ~ .b-price-item {
  color: hsl(335, 100%, 37%);
}
.b-price-item.m-free {
  color: hsl(335, 100%, 37%);
  text-transform: uppercase;
}
.b-price-divider {
  margin-inline-end: 8px;
}
.b-price-per_unit {
  color: hsl(0, 0%, 46%);
  font-size: 14px;
  font-weight: 400;
}
.b-price-badge {
  background-color: hsl(335, 100%, 37%);
  color: hsl(0, 0%, 100%);
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 4px 8px;
  word-break: break-word;
}
.b-product_details-price .b-price-badge {
  font-size: 16px;
  margin-inline-end: 8px;
  position: relative;
  top: -4px;
}
.b-suggestions_product-price .b-price-badge {
  position: relative;
  top: -1px;
}
.b-quick_buy .b-price-badge {
  margin-inline-end: 8px;
}

.b-product_tile {
  position: relative;
}
.b-product_tile-top {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  overflow: hidden;
  position: relative;
}
.b-product_tile-image_link {
  display: block;
}
.b-product_tile-image_link.m-not_available {
  opacity: 0.5;
}
.b-product_tile-image img {
  border: none;
  bottom: 0;
  color: hsl(0, 0%, 95%);
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-product_tile-quick_view {
  background-color: rgba(128, 128, 128, 0.8);
  border: none;
  bottom: 0;
  color: hsl(0, 0%, 0%);
  font-weight: 500;
  left: 0;
  opacity: 0;
  position: absolute;
  text-transform: initial;
  transition-duration: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: color, opacity;
  width: 100%;
}
@media screen and (max-width: 1023.9px) {
  .b-product_tile-quick_view {
    display: none;
  }
}
.b-product_tile-quick_view:hover {
  background-color: rgba(128, 128, 128, 0.8);
  color: hsl(0, 0%, 0%);
}
.b-product_tile-quick_view.m-product_set {
  pointer-events: none;
}
.b-product_tile-quick_view:focus, .b-product_tile-top:hover .b-product_tile-quick_view {
  opacity: 1;
}
.b-product_tile-no_available {
  color: hsl(1, 100%, 40%);
  font-weight: 700;
  margin-bottom: 8px;
}
.b-product_tile-title {
  font-weight: normal;
  line-height: 1.6;
  margin-bottom: 4px;
  padding-right: 4px;
}
.b-product_tile-link {
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  display: block;
  word-break: break-word;
}
@media not all and (pointer: coarse) {
  .b-product_tile-link:hover {
    color: hsl(0, 0%, 0%);
  }
}
.b-product_tile-price {
  margin-bottom: 12px;
}
.b-product_tile.m-hero_product {
  grid-column: 2/-1;
  grid-row: 2/4;
}
@media screen and (max-width: 767.9px) {
  .b-product_tile.m-hero_product {
    grid-column: 1/-1;
    grid-row: 3/4;
  }
}

/*md

# b-promo_box

`promo-box` is basic container for creating promo boxes and banners.
It designed to position description (`b-promo_caption`) over the image box.

This implementation could handle both image and text-centric approaches.

## Example

```html_example
<figure class="b-promo_box m-caption_center">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Overflow handling

This component is designed to handle any type of overflow without cutting text content.

### Very long text

```html_example
<figure class="b-promo_box m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities and other thing into long long title with some additional details
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### Different aspect ratio of image

```html_example
<figure class="b-promo_box">
	<picture class="b-promo_box-picture" style="padding-bottom:10%">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Horizontal alignment

### `m-caption_left`

```html_example
<figure class="b-promo_box m-caption_left">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_right`

```html_example
<figure class="b-promo_box m-caption_right">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_center`

```html_example
<figure class="b-promo_box m-caption_center">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_offcenter`

```html_example
<figure class="b-promo_box m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Vertical alignment

For sake of simpleness and robustness Launch 360 do not provide predefined vertical alignment
variations.

## Video as base

It is not limited what you could use as base for banner - it could be image or video.

What you need to do:

* `autoplay loop muted playsinline` is required to auto play video without user gesture in iOS

Please see [iOS manual](https://webkit.org/blog/6784/new-video-policies-for-ios/)

```html_example
<figure class="b-promo_box">
	<div class="b-promo_box-picture">
		<video autoplay loop muted playsinline width="1600" height="800">
			<source src="../../images/guide/examples/banner-video-16x9-lg.mp4" type="video/mp4" />
			<source src="../../images/guide/examples/banner-video-16x9-lg.mov" type="video/quicktime" />
		</video>
	</div>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Advanced: tiled background

```html_example
<style>
	.b-promo_box.m-image_3x4_right {
		.b-promo_box-picture {
			grid-column: 8 / span 4;
			padding-bottom: aspect-ratio(3, 4);
			padding-bottom: 133.3333333333%;
		}
	}
	.b-promo_box.m-image_3x4_left {
		.b-promo_box-picture {
			grid-column: 1 / span 4;
			padding-bottom: aspect-ratio(3, 4);
			padding-bottom: 133.3333333333%;
		}
	}
</style>
<figure class="b-promo_box m-caption_left m-image_3x4_right m-text_below">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/banner-3x4-5.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
    <figcaption class="b-promo_box-caption b-promo_caption">
        <h2 class="b-promo_caption-title">
            New beauty neutrals color
        </h2>
        <p class="b-promo_caption-subtitle">
            Plunge into calm pastel colors, choose for yourself only the most sophisticated and beautiful attire in the new season
        </p>
        <div class="b-promo_caption-actions">
            <a
                class="b-button"
                href="$url('Search-Show', 'cgid', 'category')$"
            >
                Shop New Season
            </a>
        </div>
    </figcaption>
</figure>
```

## Advanced: Background stretched independent from container

```html_example
<figure class="b-promo_box m-full_bleed m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
    <figcaption class="b-promo_box-inner">
        <div class="b-promo_box-caption b-promo_caption">
            <h2 class="b-promo_caption-title">
                Make boilerplate better
            </h2>
            <p class="b-promo_caption-subtitle">
                Everyone's fallen for boilerplate so we added to her games repertoire for spring with new playful styles
                inspired by darts and that staple of a British pub - the fruit machine.
            </p>
            <div class="b-promo_caption-actions">
                <a
                    class="b-button"
                    href="$url('Search-Show', 'cgid', 'category')$"
                >
                    Shop New Season
                </a>
            </div>
        </div>
    </figcaption>
</figure>
```
*/
.b-promo_box {
  display: grid;
}
@media screen and (min-width: 1367px) {
  .b-promo_box {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .b-promo_box {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-promo_box {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_box {
    grid-gap: 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
.b-promo_box-picture {
  background: hsl(0, 0%, 95%);
  display: block;
  overflow: hidden;
  padding-bottom: 44.2477876106%;
  position: relative;
  width: 100%;
  grid-column: grid-start/grid-end;
  grid-row: 1/2;
  z-index: -1;
}
@media screen and (max-width: 767.9px) {
  .b-promo_box-picture {
    padding-bottom: 100%;
  }
}
.b-promo_box-picture img,
.b-promo_box-picture video {
  border: none;
  bottom: 0;
  color: hsl(0, 0%, 95%);
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-promo_box-caption {
  grid-column: grid-start/grid-end;
  grid-row: 1/2;
  padding: 48px 0;
  text-align: center;
}
.b-promo_box.m-full_bleed {
  grid-template-columns: auto;
  height: 100%;
}
.b-promo_box.m-full_bleed .b-promo_box-picture {
  grid-column: 1/2;
  grid-row: 1/2;
  padding-bottom: 32.3162274619%;
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-promo_box.m-full_bleed .b-promo_box-picture {
    padding-bottom: 58.3333333333%;
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_box.m-full_bleed .b-promo_box-picture {
    padding-bottom: 115.2%;
  }
}
.b-promo_box.m-full_bleed .b-promo_box-inner {
  display: grid;
  margin: 0 auto;
  max-width: 1440px;
  padding-left: 88px;
  padding-right: 88px;
  grid-column: 1/2;
  grid-row: 1/2;
  margin-bottom: 16px;
  margin-top: 16px;
  width: 100%;
}
@media screen and (min-width: 1367px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    grid-gap: 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_box:not(.m-full_bleed) .b-promo_box-caption {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-promo_box.m-caption_left .b-promo_box-caption {
  text-align: start;
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-caption_left .b-promo_box-caption {
    grid-column: 2/span 5;
  }
}
.b-promo_box.m-caption_right .b-promo_box-caption {
  text-align: start;
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-caption_right .b-promo_box-caption {
    grid-column: 8/span 5;
  }
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-caption_center .b-promo_box-caption {
    grid-column: 3/span 8;
  }
}
.b-promo_box.m-caption_offcenter .b-promo_box-caption {
  text-align: start;
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-caption_offcenter .b-promo_box-caption {
    grid-column: 7/span 5;
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_box.m-text_below:not(.m-full_bleed) .b-promo_box-picture {
    grid-column: grid-start/grid-end;
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_box.m-text_below:not(.m-full_bleed) .b-promo_box-caption {
    grid-column: grid-start/grid-end;
    grid-row: 2/3;
    padding: 16px 0;
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_box.m-text_below.m-full_bleed {
    display: block;
  }
  .b-promo_box.m-text_below.m-full_bleed .b-promo_box-picture {
    grid-column: grid-start/grid-end;
  }
  .b-promo_box.m-text_below.m-full_bleed .b-promo_box-inner {
    grid-column: grid-start/grid-end;
    grid-row: 2/3;
  }
  .b-promo_box.m-text_below.m-full_bleed .b-promo_box-caption {
    padding: 16px 0;
  }
}

/*md

# b-promo_caption

Promo caption is the content of promo components.

## Structure

`b-promo_caption` consist from 3 main elements:

* title
* subtitle
* actions container

All of this elements are optional.

## Variation

For sake of simpleness and robustness Launch 360 do not provide predefined sizes and styles
variations.

## Examples

### All elements

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
		Exclusively for Members
	</h2>
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Activate
		</a>
	</div>
</div>
```

### Different order

```html_example
<div class="b-promo_caption">
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
		Exclusively for Members
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Activate
		</a>
	</div>
</div>
```

### Only title and CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Join
		</a>
	</div>
</div>
```

### 2 CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Men
		</a>
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Woman
		</a>
	</div>
</div>
```

### 3 CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Men
		</a>
		<a
			class="b-button"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Woman
		</a>
		<a
			class="b-button m-link"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Kids
		</a>
	</div>
</div>
```

### Without CTA

```html_example
<a
	class="b-promo_caption"
	href="$url('Search-Show', 'cgid', 'category-2')$"
>
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
</a>
```

## Example in component

### b-promo_info_box

```html_example
<div class="b-promo_info_box">
	<div class="b-promo_info_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Shop Now. Pay Later. <br/>
			Exclusively for Members
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a class="m-outline b-button" href="$url('Search-Show', 'cgid', 'category-2')$">
				Join today
			</a>
		</div>
	</div>
</div>
```

### b-promo-box

```html_example
<figure class="b-promo_box">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

*/
.b-promo_caption {
  align-self: center;
}
.b-promo_caption-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 20px;
}
.b-promo_caption-subtitle {
  font-weight: 500;
  margin: 0 0 20px;
}
.b-promo_caption-actions {
  align-items: baseline;
  display: inline-flex;
  flex-wrap: wrap;
  margin: 24px -16px 0;
}
.b-promo_caption-actions a {
  margin: 0 16px 12px;
}

/*md

# b-promo_info_box

This is type of container for banners that hold only text content. It do not have aspect ratio holder
and it stretched/pulled by text content.

So it could easily hold any amount of text content without issues on any breakpoints.

```html_example
<div class="b-promo_info_box">
	<div class="b-promo_info_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Shop Now. Pay Later. <br/>
			Exclusively for Members
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a class="m-outline b-button" href="$url('Search-Show', 'cgid', 'category-2')$">
				Join
			</a>
		</div>
	</div>
</div>
```

## Big amount of text

```html_example
<div class="b-promo_info_box">
	<div class="b-promo_info_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Shop Now. Pay Later. <br/>
			Exclusively for Members <br/>
			But not
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
			<br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
			<br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a class="m-outline b-button" href="$url('Search-Show', 'cgid', 'category-2')$">
				Join
			</a>
		</div>
	</div>
</div>
```

## Small amount of text

```html_example
<div class="b-promo_info_box">
	<div class="b-promo_info_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Shop Now. Pay Later.
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy.
		</p>
		<div class="b-promo_caption-actions">
			<a class="m-outline b-button" href="$url('Search-Show', 'cgid', 'category-2')$">
				Join
			</a>
		</div>
	</div>
</div>
```

*/
.b-promo_info_box {
  display: grid;
  background: hsl(223, 80%, 98%);
  color: hsl(223, 77%, 55%);
}
@media screen and (min-width: 1367px) {
  .b-promo_info_box {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .b-promo_info_box {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-promo_info_box {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_info_box {
    grid-gap: 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
.b-promo_info_box-caption {
  grid-column: 1/grid-end;
  grid-row: 1/2;
  padding: 48px 16px;
  text-align: center;
}

/*md

# b-promo_line

Promo-line is classic text banner. It placed right under the header and stretched to full viewport width.

It consist from several elements, but has only one to place text into it - `b-promo_line-inner`.

It could consist inline images, rich formatting text (`strong`, `em`) or links.

## Example

```html_example
<div class="b-promo_line m-header">
	<p class="b-promo_line-inner">
		Get 20% off everything with code: <strong>ILOVE20</strong> <a href="$url('Product-Show', 'pid', 'product-1')$">Buy now</a>
	</p>
</div>
```

*/
.b-promo_line {
  background-color: hsl(223, 80%, 98%);
  color: hsl(223, 77%, 55%);
  font-size: 16px;
  font-weight: 500;
  padding: 28px 0;
  text-align: center;
}
@media screen and (max-width: 1023.9px) {
  .b-promo_line {
    padding: 12px 0;
  }
}
.b-promo_line.m-search_results {
  background-color: hsl(123, 23%, 95%);
  color: hsl(123, 46%, 34%);
}
@media screen and (max-width: 767.9px) {
  .b-promo_line.m-search_results {
    display: none;
  }
}
.m-has_dialog .b-promo_line.m-header {
  overflow-y: scroll;
}
.b-promo_line-inner {
  margin: 0 auto;
  max-width: 1440px;
  padding-left: 88px;
  padding-right: 88px;
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .b-promo_line-inner {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.9px) {
  .b-promo_line-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-promo_line-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-promo_line a {
  color: inherit;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
}
@media not all and (pointer: coarse) {
  .b-promo_line a:hover {
    color: inherit;
    text-decoration: underline;
  }
}

/*md

# b-promotion

Designed to provide same styles of promotion across different components.

We do not support any HTML in promotion messages.

## Promotion with configured link

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for <a href="#">order</a>
	</div>
</div>
```

## Empty promotion

```html_example
<div class="b-promotion">
	<div class="b-promotion-message"></div>
</div>
```

## Promotion with details in dialog

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for order
	</div>
	<button
		class="b-promotion-details"
		type="button"
		data-widget="emitBusEvent"
		data-bus-event-type="dialog.show"
		data-event-click.prevent="emitBusEvent"
		data-tau="promotion_details_cta"
		data-initialized="1"
	>
		Details
	</button>
</div>
```

*/
.b-promotion {
  align-items: center;
  display: flex;
  font-weight: 500;
  margin-bottom: 8px;
  position: relative;
}
.b-promotion-message {
  background-color: hsl(223, 80%, 98%);
  border-radius: 0;
  color: hsl(223, 77%, 55%);
  padding: 4px 8px;
}
.b-promotion-message:empty {
  display: none;
}
.b-promotion-message.m-centered {
  text-align: center;
  width: 100%;
}
.b-promotion-message.m-large {
  padding: 16px 20px;
}
.b-promotion-message a {
  color: inherit;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
}
@media not all and (pointer: coarse) {
  .b-promotion-message a:hover {
    color: inherit;
    text-decoration: underline;
  }
}
.b-promotion-details {
  color: inherit;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
  margin-inline-start: 12px;
}
@media not all and (pointer: coarse) {
  .b-promotion-details:hover {
    color: hsl(0, 0%, 0%);
  }
}

/*md

# b-scrollable_table

Mobile friendly tabular data component.

This table has an inner scroll on mobile devices with sticky cell headers. On desktop and tablet devices will be shown the usual table.

## Example
```html_example
<div class="b-scrollable_table">
	<div class="b-scrollable_table-content">
	    <table class="b-scrollable_table-table">
	        <tbody>
	            <tr>
	                <th scope="row">
	                    <strong>US</strong>
	                </th>
	                <td>
	                    <strong>2</strong>
	                </td>
	                <td>
	                    <strong>4</strong>
	                </td>
	                <td>
	                    <strong>6</strong>
	                </td>
	                <td>
	                    <strong>8</strong>
	                </td>
	                <td>
	                    <strong>10</strong>
	                </td>
	                <td>
	                    <strong>12</strong>
	                </td>
	            </tr>
	            <tr>
	                <th scope="row">
	                    <strong>EURO</strong>
	                </th>
	                <td>
	                    <strong>34</strong>
	                </td>
	                <td>
	                    <strong>36</strong>
	                </td>
	                <td>
	                    <strong>38</strong>
	                </td>
	                <td>
	                    <strong>40</strong>
	                </td>
	                <td>
	                    <strong>42</strong>
	                </td>
	                <td>
	                    <strong>44</strong>
	                </td>
	            </tr>
	            <tr>
	                <th scope="row">
	                    <strong>UK</strong>
	                </th>
	                <td>
	                    <strong>6</strong>
	                </td>
	                <td>
	                    <strong>8</strong>
	                </td>
	                <td>
	                    <strong>10</strong>
	                </td>
	                <td>
	                    <strong>12</strong>
	                </td>
	                <td>
	                    <strong>14</strong>
	                </td>
	                <td>
	                    <strong>16</strong>
	                </td>
	            </tr>
	            <tr>
	                <th scope="row">CHEST</th>
	                <td>80/31</td>
	                <td>80/32</td>
	                <td>86/34</td>
	                <td>91/36</td>
	                <td>96/38</td>
	                <td>101/40</td>
	            </tr>
	            <tr>
	                <th scope="row">WAIST</th>
	                <td>63/25</td>
	                <td>65/26</td>
	                <td>65/27</td>
	                <td>74/30</td>
	                <td>79/31</td>
	                <td>84/33</td>
	            </tr>
	            <tr>
	                <th scope="row">HIPS</th>
	                <td>89/35</td>
	                <td>91/36</td>
	                <td>94/37</td>
	                <td>99/39</td>
	                <td>104/41</td>
	                <td>109/43</td>
	            </tr>
	        </tbody>
	    </table>
	</div>
</div>
```
*/
.b-scrollable_table {
  margin: 16px 0;
  position: relative;
}
@media screen and (max-width: 767.9px) {
  .b-scrollable_table::after {
    background-color: gray;
    bottom: 0;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
  }
}
.b-scrollable_table-content {
  position: relative;
}
@media screen and (max-width: 767.9px) {
  .b-scrollable_table-content {
    border-left: 2px solid gray;
    overflow: auto;
  }
}
.b-scrollable_table-table {
  border: 2px solid gray;
  border-collapse: collapse;
  width: 100%;
}
@media screen and (max-width: 767.9px) {
  .b-scrollable_table-table {
    border-left: 0;
  }
}
.b-scrollable_table th {
  background-color: hsl(0, 0%, 95%);
  border: 1px solid gray;
  font-weight: normal;
  min-width: 70px;
  padding: 12px 8px;
  text-align: start;
  width: 1px;
}
@media screen and (min-width: 1024px) {
  .b-scrollable_table th {
    min-width: 110px;
    padding: 12px 24px;
  }
}
@media screen and (max-width: 767.9px) {
  .b-scrollable_table th {
    background-clip: padding-box;
    border-left: 0;
    left: 0;
    position: sticky;
  }
}
@media screen and (max-width: 767.9px) {
  .b-scrollable_table th::after {
    background-color: gray;
    bottom: 0;
    content: "";
    position: absolute;
    right: -1px;
    top: 0;
    width: 1px;
  }
}
.b-scrollable_table td {
  border: 1px solid gray;
  min-width: 90px;
  padding: 12px 8px;
  text-align: center;
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .b-scrollable_table td {
    padding: 12px 24px;
  }
}

/*md

# Tabs (`b-tab_list` / `b-tab_panel`)

Tabs presents multiple mutually exclusive panes of content in the same area.
Includes a tabbed control(tab) and a content area. Clicking a tab displays its corresponding pane in the content area.

## Attributes

```
[boolean] - data-active-first - activate first tab and first tab panel
[string]  - data-active-panel - activate tab and tab panel by provided panel id
[boolean] - data-auto-activation - if tabs list should follow accessibility `Tabs with Automatic Activation` feature
```

## Usage

To get started with tabs we should link the tab and the content area.
We have a `data-panel-name` attribute on a tab and `id` attribute on the pane for that.

### Tabs with automatic activation (data-auto-activation="true")

The tab pane content will change just in case of using arrow keys.
With using just a Tab key, inactive tabs will be skipped by focus.
Click functionality works as usual.

```html_example
<div
    data-widget="tabs"
    data-auto-activation="true"
    data-active-panel="firstPanel"
    data-event-keydown="handleKeydown"
>
    <div data-ref="tablist" role="tablist" class="b-tab_list">
        <button aria-selected="true"
                class="b-tab_list-tab"
                data-panel-name="firstPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-firstPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            First tab
        </button>

        <button
                tabindex="-1"
                aria-selected="false"
                class="b-tab_list-tab"
                data-panel-name="secondPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-secondPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            Second tab
        </button>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="firstPanel"
    >
        <h1>The content of a FIRST tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="secondPanel"
    >
        <h1>The content of a SECOND tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.</p>
    </div>
</div>
```

### Tabs with manual activation (data-auto-activation="false")

The tab pane content will change just in case of using Enter/Space button on focused element.
Click functionality works as usual.

```html_example
<div
    data-widget="tabs"
    data-auto-activation="false"
    data-event-keydown="handleKeydown"
    data-active-panel="firstPanel"
>
    <div data-ref="tablist" role="tablist" class="b-tab_list">
        <button aria-selected="true"
                class="b-tab_list-tab"
                data-panel-name="firstPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-firstPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            First tab
        </button>

        <button
            aria-selected="false"
            class="b-tab_list-tab"
            data-panel-name="secondPanel"
            data-widget-event-click="handleTabClick"
            data-id="button-secondPanel"
            data-event-click.prevent="handleClick"
            data-widget="button"
            role="tab"
        >
            Second tab
        </button>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="firstPanel"
    >
        <h1>The content of a FIRST tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="secondPanel"
    >
        <h1>The content of a SECOND tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.</p>
    </div>
</div>
```

## SCSS Notes

We have two SCSS blocks for Tabs. The first is for tab controls `b-tab_list` , and the second is fortab content
areas `b-tab_panel`.
*/
.b-tab_list {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 16px auto;
  overflow-x: auto;
  scrollbar-width: none;
  user-select: none;
}
.b-tab_list::-webkit-scrollbar {
  display: none;
}
.b-tab_list.m-search {
  margin: 0 0 44px;
}
.b-tab_list.m-pdp {
  font-size: 2rem;
  justify-content: flex-start;
  margin: 0 0 32px -8px;
}
.b-tab_list.m-checkout {
  justify-content: flex-start;
}
.b-tab_list.m-account {
  margin-bottom: 40px;
}
@media screen and (max-width: 767.9px) {
  .b-tab_list.m-account {
    margin-bottom: 32px;
  }
}
.b-tab_list-tab {
  appearance: none;
  background: transparent;
  border: none;
  border-bottom: 4px solid transparent;
  border-radius: 0;
  cursor: pointer;
  display: block;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1px;
  padding: 12px 40px;
  position: relative;
  text-decoration: none;
  text-transform: initial;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  white-space: nowrap;
}
@media screen and (max-width: 767.9px) {
  .b-tab_list-tab {
    padding: 12px 20px;
    width: 100%;
  }
}
.b-tab_list-tab:hover {
  color: hsl(0, 0%, 0%);
}
.b-tab_list-tab::after {
  background-color: gray;
  bottom: -5px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}
.b-tab_list-tab.m-active {
  border-color: hsl(0, 0%, 0%);
  color: hsl(0, 0%, 0%);
}
.b-tab_list.m-pdp .b-tab_list-tab {
  margin-inline-start: 0;
  padding: 16px;
}
.b-tab_list.m-checkout .b-tab_list-tab {
  font-weight: bold;
  text-transform: uppercase;
}
@media screen and (max-width: 767.9px) {
  .b-tab_list.m-search .b-tab_list-tab {
    font-size: 20px;
    padding: 12px;
  }
}

.b-tab_panel {
  display: none;
  width: 100%;
}
.b-tab_panel.m-active {
  display: block;
}

.b-tooltip {
  position: relative;
}
.b-tooltip.m-order_summary {
  margin-left: 8px;
}
.b-tooltip-button {
  align-items: center;
  border: 2px solid hsl(0, 0%, 0%);
  border-radius: 50%;
  color: hsl(0, 0%, 0%);
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, color;
  width: 20px;
}
.b-tooltip-button:hover, .b-tooltip-button:focus {
  background-color: hsl(0, 0%, 0%);
  color: gray;
}
.b-tooltip-container {
  background-color: gray;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  font-weight: 300;
  opacity: 0;
  padding: 8px 12px;
  position: absolute;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: opacity, visibility;
  visibility: hidden;
  width: 100%;
}
.b-tooltip.m-right .b-tooltip-container {
  left: calc(100% + 8px);
  top: 0;
}
.b-tooltip.m-order_summary .b-tooltip-container {
  width: 204px;
}
@media screen and (min-width: 1024px) and (max-width: 1366.9px) {
  .b-tooltip.m-order_summary .b-tooltip-container {
    width: 144px;
  }
}
.b-tooltip-button:hover + .b-tooltip-container, .b-tooltip-button:focus + .b-tooltip-container {
  opacity: 1;
  visibility: visible;
}