/*md
@no-stat

# Palette

This is palette settings for project/brand.
It divided into 2 main categories - palette and applied color.

* Palette is general set of colors. It could be used directly if you do not have themes.
* Applied colors designed as layer of abstraction to have ability to overwrite it on brand level.

All themes have individual logic so it almost impossible to implement some single
universal approach. Launch 360 provide basic tools for further customization
depending of your requirements.

Please see _colors.md for more information about themes.

*/
// Palette
// =============================================================================
$color-black: hsl(0, 0%, 0%);
$color-grey18: hsl(0, 0%, 18%);
$color-grey25: hsl(0, 0%, 25%);
$color-grey46: hsl(0, 0%, 46%);
$color-grey73: hsl(0, 0%, 73%);
$color-grey88: hsl(0, 0%, 88%);
$color-grey95: hsl(0, 0%, 95%);
$color-white: hsl(0, 0%, 100%);
$color-blue: hsl(223, 77%, 55%);
$color-blue38: hsl(223, 77%, 38%);
$color-blue98: hsl(223, 80%, 98%);
$color-cherry: hsl(335, 100%, 37%);
$color-cherry31: hsl(335, 100%, 31%);
$color-cherry93: hsl(335, 100%, 93%);
$color-green: hsl(123, 46%, 34%);
$color-green19: hsl(122, 52%, 19%);
$color-green94: hsl(123, 23%, 95%);
$color-red: hsl(1, 100%, 40%);
$color-red34: hsl(1, 100%, 34%);
$color-red95: hsl(0, 100%, 95%);
$color-yellow: hsl(37, 91%, 55%);
$color-yellow54: hsl(28, 100%, 54%);
$color-yellow94: hsl(36, 93%, 94%);

// Applied colors
// =============================================================================
$color-primary: $color-blue;
$color-accent: $color-cherry;
$color-accent-obj: $color-cherry93;
// text
$color-text: $color-black;
$color-text-dimmed: $color-grey46;
$color-text-grey: $color-grey25;
$color-divider: $color-grey73;
$color-divider-light: $color-grey88;
// actions
$color-action: $color-blue;
$color-action-obj: $color-blue;
$color-action-disabled: $color-grey73;
// statuses
$color-success: $color-green;
$color-success-obj: $color-green94;
$color-error: $color-red;
$color-error-obj: $color-red95;
$color-warning: $color-yellow;
$color-warning-obj: $color-yellow94;
$color-info: $color-blue;
$color-info-obj: $color-blue98;
// regions
$color-bg: $color-white;
$color-bg-shade: $color-grey95;
$color-bg-shade2: $color-grey95;
$color-bg-utility-bar: $color-blue;
$color-bg-header-line-1: $color-white;
$color-bg-header-line-2: $color-bg;
$color-bg-footer-region-1: $color-grey95;
$color-bg-footer-region-2: $color-grey88;
$color-bg-panel: $color-white;
$color-bg-panel-head: $color-blue98;
$color-bg-overlay: $color-black;
// components
// components: b-promo_line
$color-bg-promo_line: $color-info-obj;
$color-fg-promo_line: $color-info;
$color-bg-promo_line-m-search_results: $color-success-obj;
$color-fg-promo_line-m-search_results: $color-green;
// components: b-promotion
$color-bg-promotion: $color-info-obj;
$color-fg-promotion: $color-info;
// components: b-notification_dialog
$color-bg-notification_dialog: $color-green;
// components: b-rating
$color-bg-rating-m-filled: $color-yellow;
$color-bg-rating-m-empty: $color-grey73;
// components: b-skip-to
$color-bg-skip_to: $color-blue98;
// components: b-back-to-top
$color-bg-back_to_top: $color-action;
// components: b-badges
$color-bg-badges: $color-cherry;
$color-fg-badges: $color-white;
// components: g-image_container
$color-bg-image: $color-grey95;

// If you need test themes please copy colors from colors.md doc
//$theme: 'yellow';
//@if ($theme == 'yellow') {
//	$color-primary: hsl(213, 24%, 28%);
//	$color-accent: hsl(34, 99%, 70%);
// ...
