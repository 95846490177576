.b-accordion {
	box-shadow: none;

	&-header {
		margin-bottom: 20px;
	}

	&-inner {
		box-shadow: 0 -1px 0 0 $color-divider-light;
	}
}
