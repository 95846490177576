.b-product_attributes {
	&-empty {
		background-color: $color-blue98;
		border: 2px dashed $color-blue;
		font-size: 18px;
		font-weight: 600;
		line-height: 2;
		padding: 100px 0;
		text-align: center;
		text-transform: uppercase;

		.b-message {
			background-color: transparent;
			margin: 0;
			padding: 0;
			text-transform: initial;
		}
	}
}
