@use 'sass:math';

.b-product_attributes_gallery {
	&-title {
		margin-bottom: 40px;

		@include media(sm) {
			margin-bottom: 20px;
		}
	}

	&-main {
		display: flex;
		flex-wrap: wrap;

		@include media(md-up) {
			margin: 0 -10px;
		}
	}

	&-item {
		$sm-cols: grid-columns('sm');
		$md-cols: grid-columns('md');
		$lg-cols: grid-columns('lg');

		@include media(md-up) {
			padding: 0 10px;
		}

		@include media(sm) {
			width: 100%;
		}

		@for $column from $md-cols through 1 {
			@include media('md-up') {
				&.m-md_#{$column} {
					flex: 0 0 math.percentage(math.div($column, $md-cols));
					max-width: math.percentage(math.div($column, $md-cols));
					min-width: math.percentage(math.div($column, $md-cols));
				}
			}
		}

		@for $column from $lg-cols through 1 {
			@include media('lg-up') {
				&.m-lg_#{$column} {
					flex: 0 0 math.percentage(math.div($column, $lg-cols));
					max-width: math.percentage(math.div($column, $lg-cols));
					min-width: math.percentage(math.div($column, $lg-cols));
				}
			}
		}
	}
}
