/*md

# g-link_ui

This component is designed to provide consistent styles of UI-type links across
different UI components. For example links in header or footer, that expected to be more
like buttons and do not have attributes that expected for text links (hyperlinks) -
to be underlined, have visited state etc.

## Usage

```scss
// for regular cases
.b-menu_bar-item {
	@include g-link_ui;
}

// for cases when text color is inverted (white, red etc) and action color will not
// be good (ex blue hover state on red alert banner)
.b-component-link {
	@include g-link_ui(inherit);
}
```

*/
@mixin g-link_ui($_hover_color: $color-action) {
	cursor: pointer;
	font-weight: 500;
	text-decoration: none;

	@include hover-supported {
		&:hover {
			color: $_hover_color;

			@if $_hover_color == inherit {
				text-decoration: underline;
			}
		}
	}
}
