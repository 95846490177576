/*md

# g-link_hamburger

Hamburger menu generic link that used in several component.

Designed to use same style of hamburger link in different components
ex: menu, account link, language switcher etc.

```scss
.b-menu {
	// ...
	&-item {
		@include media(sm) {
			@include g-link_hamburger;
		}
	}
}
```
*/

@mixin g-link_hamburger(
	$_mod: default
) {
	@if $_mod == default {
		@include g-link;

		align-items: center;
		box-shadow: inset 0 -1px 0 0 $color-divider;
		cursor: pointer;
		display: flex;
		font-size: 18px;
		min-height: 48px;
		padding: 0 16px;
		text-decoration: none;
		width: 100%;
	}

	@if $_mod == highlight {
		color: $color-accent;
	}
}
