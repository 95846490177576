/*md

# g-heading_*

Basic simple typography styles applied to different UI components.

This covers only very basic cases and should be extended on project.

```scss
.b-cart_empty {
	// ...

	&-title {
		@include g-heading_1;

		margin-bottom: 32px;
	}
}
```
*/

@mixin g-heading_1 {
	font-size: 44px;
	font-weight: 700;
	line-height: 1.2;
}

@mixin g-heading_2 {
	font-size: 36px;
	font-weight: 700;
	line-height: 1.2;
}

@mixin g-heading_3 {
	font-size: 28px;
	font-weight: 700;
	line-height: 1.2;
}

@mixin g-heading_4 {
	font-size: 24px;
	font-weight: 700;
	line-height: 1.2;
}

@mixin g-heading_5 {
	font-size: 16px;
	font-weight: 700;
}
