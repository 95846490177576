@use 'sass:math';

$sm-cols: grid-columns('sm');
$md-cols: grid-columns('md');
$lg-cols: grid-columns('lg');

.b-carousel_ext {
	@include g-page-designer-vertical_alignments;

	overflow: hidden;

	.b-header_ext.m-horizontal_center {
		@include media (md-up) {
			padding-left: 68px;
		}
	}

	.b-carousel {
		display: flex;
		flex-direction: column;
		margin: 0 -10px;
		opacity: 0;
		position: initial;

		@include media (sm) {
			margin-top: 8px;
		}

		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 6px 10px;
		}

		&-header_title {
			flex-grow: 1;
		}

		&-nav {
			display: flex;
			margin-right: -14px;
			opacity: 0;
			transition: $motion-ease;
			transition-property: visibility, opacity;
			visibility: hidden;

			@include media (sm) {
				display: none;
			}
		}

		&-ctrl {
			background-color: transparent;
			margin-top: 0;
			position: initial;

			&[disabled] {
				opacity: 0.5;
				z-index: 0;
			}
		}

		&-body {
			display: flex;
		}

		&-track {
			width: 100%;
		}

		&-item {
			@include g-page-designer-self_alignments;

			display: flex;
			padding: 0 10px;

			@for $column from $sm-cols through 1 {
				&.m-sm_#{$column} {
					flex: 0 0 math.percentage(math.div($column, $sm-cols + 2));
					max-width: math.percentage(math.div($column, $sm-cols + 2));
				}
			}

			@for $column from $md-cols through 1 {
				@include media('md-up') {
					&.m-md_#{$column} {
						flex: 0 0 math.percentage(math.div($column, $md-cols - 1));
						max-width: math.percentage(math.div($column, $md-cols - 1));
					}
				}
			}

			@for $column from $lg-cols through 1 {
				@include media('lg-up') {
					&.m-lg_#{$column} {
						flex: 0 0 math.percentage(math.div($column, $lg-cols - 1));
						max-width: math.percentage(math.div($column, $lg-cols - 1));
					}
				}
			}
		}

		&.m-inited {
			opacity: 1;
		}

		&.m-inited.m-next_visible,
		&.m-inited.m-prev_visible {
			.b-carousel-nav {
				@include media(md-up) {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		&.m-no_scroll {
			.b-carousel-track {
				&.m-grabbing {
					cursor: default;
					user-select: auto;
				}
			}
		}
	}

	&.m-hide_gutters {
		.b-carousel {
			margin: 0;

			&-item {
				padding: 0;
			}
		}
	}

	.b-promo_box-picture.m-general {
		@include media(md) {
			padding-bottom: aspect-ratio(2.26, 1);
		}
	}

	.b-text_block {
		width: 100%;
	}
}

.b-carousel.m-side-nav {
	@include media(md-up) {
		flex-direction: row;

		.b-carousel-body {
			flex-grow: 1;
		}

		.b-carousel-header_title {
			flex-grow: 0;
		}

		.b-carousel-nav {
			display: flex;
			margin: 0 0 0 -14px;
		}
	}

	.b-carousel-header {
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;

		@include media(md-up) {
			flex-shrink: 0;
			width: 25%;
		}
	}

	.b-header_ext-wrapper {
		margin-bottom: 40px;

		@include media(sm) {
			margin-bottom: 0;
		}
	}
}
