.b-product_editorial {
	@include g-page-designer-vertical_alignments;

	display: flex;
	width: 100%;

	.b-button {
		@include media(sm) {
			width: 100%;
		}
	}

	.b-product_tile {
		display: grid;
		grid-column-gap: 20px;
		grid-template: 'image sold_out' 'image title' 'image description' 'image price' 'image promotion' 'image swatches' 'image rating' 'image actions';
		grid-template-columns: 1fr minmax(auto, 50%);
		grid-template-rows: min-content;
		position: relative;
		width: 100%;

		@include media(sm) {
			display: flex;
			flex-direction: column;
		}

		&-content {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		&-top {
			grid-area: image;

			@include media(md-up) {
				margin-bottom: 0;
			}
		}

		&-price {
			grid-area: price;
		}

		&-price,
		&_swatches,
		.b-promotion {
			margin-bottom: 0;
		}

		.b-promotion,
		.b-rating,
		.b-button {
			margin-top: 12px;
		}

		&-title {
			grid-area: title;
		}

		&-description {
			@include g-text_overflow(2);

			grid-area: description;
			margin-bottom: 8px;
		}

		&_swatches {
			grid-area: swatches;
		}

		.b-promotion {
			grid-area: promotion;
		}

		.b-rating {
			grid-area: rating;
		}

		&-actions {
			grid-area: actions;
		}

		&-no_available {
			align-self: flex-end;
			grid-area: sold_out;

			@include media(sm) {
				align-self: flex-start;
			}
		}

		img {
			object-position: var(--img-obj-position);
		}
	}
}
