$button-size: 48px;
$hot-spot-size: 24px;

.b-hot_spot {
	&.m-edit_mode {
		pointer-events: all;
	}

	&.m-hidden-md_up {
		@include media(md-up) {
			display: none;
		}
	}

	&.m-hidden-sm {
		@include media(sm) {
			display: none;
		}
	}

	&-btn {
		cursor: pointer;
		height: $hot-spot-size;
		left: var(--left-position, 0);
		position: absolute;
		top: var(--top-position, 0);
		transform: translate(-50%, -50%);
		width: $hot-spot-size;

		@include media(lg-up) {
			&:hover::before {
				opacity: 1;
				padding: 16px;
			}

			&:hover::after {
				opacity: 0.4;
				padding: 24px;
			}
		}

		@include media(sm) {
			left: var(--left-position-sm);
			top: var(--top-position-sm);
		}

		&::before,
		&::after {
			border: 1px solid var(--bg-color, $color-white);
			border-radius: 50%;
			content: '';
			height: 100%;
			left: 50%;
			opacity: 0;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: opacity $motion-ease, padding $motion-ease;
			width: 100%;
		}

		&.m-active {
			&::before {
				opacity: 1;
				padding: 16px;
			}

			&::after {
				opacity: 0.4;
				padding: 24px;
			}
		}
	}

	&-sign {
		background-color: var(--bg-color, $color-white);
		border-radius: 50%;
		display: block;
		height: 100%;
		position: relative;

		&::before,
		&::after {
			background-color: var(--bg-color, $color-white);
			content: '';
			display: block;
			filter: grayscale(1) contrast(100) invert(1);
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&::after {
			height: 1px;
			width: 7px;
		}

		&::before {
			height: 7px;
			width: 1px;
		}
	}

	&-popup {
		background-color: var(--bg-color, $color-white);
		bottom: 0;
		left: 0;
		position: absolute;
		top: 0;
		transform: translateX(-100%);
		transition: transform $motion-ease, visibility $motion-ease;
		visibility: hidden;
		width: 488px;
		z-index: 2;

		@include media(md) {
			width: 50%;
		}

		@include media(sm) {
			width: 80%;
		}

		&[aria-hidden='false'] {
			transform: translateX(0);
			transition: transform $motion-ease, visibility 0s;
			visibility: visible;
		}
	}

	&-scrollbox {
		height: 100%;
		overflow-y: auto;
		padding: 44px 60px;
		width: 100%;

		@include media(xl) {
			padding: 92px 88px;
		}

		@include media(sm) {
			padding-left: 16px;
			padding-right: 16px;
		}

		&::before {
			border-bottom: 44px solid var(--bg-color, $color-white);
			border-top: 44px solid var(--bg-color, $color-white);
			bottom: 0;
			content: '';
			left: 60px;
			pointer-events: none;
			position: absolute;
			right: 60px;
			top: 0;
			z-index: 1;

			@include media(xl) {
				border-width: 92px;
			}

			@include media(sm) {
				left: 16px;
				right: 16px;
			}
		}

		&::-webkit-scrollbar {
			width: 2px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $color-grey46;
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		grid-gap: 16px;
		justify-content: center;
		min-height: 100%;

		> :last-child {
			margin-bottom: 0;
		}

		.b-badges {
			z-index: 0;
		}
	}

	&-close {
		color: var(--bg-color, $color-white);
		cursor: pointer;
		height: $button-size;
		position: absolute;
		right: 0;
		top: 0;
		width: $button-size;
		z-index: 2;

		@include media(sm) {
			top: -8px;
		}

		svg {
			filter: grayscale(1) contrast(100) invert(1);
		}
	}

	&-overlay {
		background-color: rgba($color-bg-overlay, 0.4);
		bottom: 0;
		cursor: pointer;
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: opacity $motion-ease, visibility $motion-ease;
		visibility: hidden;
		z-index: 1;

		[aria-hidden='false'] + & {
			opacity: 1;
			transition: opacity $motion-ease, visibility 0s;
			visibility: visible;
		}
	}

	.l-grid_layout.m-3_3_6 &,
	.l-grid_layout.m-6_3_3 &,
	.l-grid_layout.m-2_up & {
		&-popup {
			width: 80%;
		}
	}
}
