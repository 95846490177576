$global-fs: 16;

@mixin fontSize($sizeValue) {
	font-size: ($sizeValue / $global-fs) + rem;
}

@mixin heading($heading-font-size, $line-height, $font-weight: 400) {
	@include fontSize($heading-font-size);

	line-height: $line-height;
	font-weight: $font-weight;
	margin-top: 10px;
	margin-bottom: 20px;
}
