.b-image_tile {
	$root: &;

	@include g-page-designer-alignments;

	&.m-vertical_fill {
		@include media(sm) {
			align-items: flex-start;
		}
	}

	&.m-vertical_fill-sm {
		@include media(sm) {
			align-self: stretch;
		}
	}

	&-container {
		display: flex;
		width: 100%;

		@include media(md-up) {
			max-width: var(--max-width);
		}

		#{$root}.m-vertical_fill-sm & {
			@include media(sm) {
				height: 100%;
			}
		}
	}

	&-picture {
		background: $color-bg-shade2;
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: var(--bg-padding, 100%);
		position: relative;
		width: 100%;

		@include media(sm) {
			padding-bottom: var(--bg-padding-mobile);
		}

		img {
			@include g-image_container(_img);

			object-position: var(--img-obj-position);

			@include media(sm) {
				object-position: var(--img-sm-obj-position, var(--img-obj-position));
			}
		}

		&.m-fixed_height-md_up {
			@include media(md-up) {
				height: auto;
				padding-bottom: 0;
			}

			img {
				@include media(md-up) {
					position: initial;
				}
			}
		}

		&.m-fixed_height-sm {
			@include media(sm) {
				height: auto;
				padding-bottom: 0;
			}

			img {
				@include media(sm) {
					position: initial;
				}
			}
		}
	}
}
