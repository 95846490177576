.b-text_block {
	@include g-page-designer-vertical_alignments;
	@include g-page-designer-horizontal_alignments;
	// @include g-page-designer-typography;
	@include g-page-designer-component-paddings('.b-text_block-container');

	background-color: var(--bg-text-block, transparent);
	width: 100%;

	&-container {
		width: 100%;

		@include media(md-up) {
			width: var(--width, auto);
		}
	}

	pre {
		white-space: pre-line;
	}

	.hidden-lg {
		@include media(lg-up) {
			display: none;
		}
	}

	.hidden-md {
		@include media(md) {
			display: none;
		}
	}

	.hidden-sm {
		@include media(sm) {
			display: none;
		}
	}
}
