.b-page_review {
	&-message {
		font-weight: 500;
		padding: 16px 20px;
		text-align: center;

		svg {
			margin-right: 10px;
		}
	}

	&-link {
		text-decoration: underline;
	}
}
