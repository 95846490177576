/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get data from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/

@use 'sass:map';

@function _get-grid-config($thing, $break, $grid: 'default') {
	@return map.get($grids, $grid, $thing, $break);
}

@function grid-gutter($break, $grid: 'default') {
	@return _get-grid-config('grid-gutter', $break, $grid);
}

@function grid-margin($break, $grid: 'default') {
	@return _get-grid-config('grid-margin', $break, $grid);
}

@function grid-columns($break, $grid: 'default') {
	@return _get-grid-config('grid-columns', $break, $grid);
}
