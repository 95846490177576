/*md

# b-promo_tiles_grid

This component designed to be starting point for developing different kind of
symmetrical and asymmetrical banner layouts.

It used in Page Designer as tilesGrid. See tilesGrid.isml and imageTile.isml for details.

## Basic component implementation

```html_example
<section class="b-promo_tiles_grid">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

## Layouts

In Launch 360, as example, we provide with 5 basic layouts,
but it could be easily extended to different combinations and any client needs.

### lg-4 md-4 sm-2

```html_example
<section class="b-promo_tiles_grid m-lg_4 m-md_4 m-sm_2">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

### m-lg_4 m-md_4 m-sm_1

```html_example
<section class="b-promo_tiles_grid m-lg_4 m-md_4 m-sm_1">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

### m-lg_3 m-md_3 m-sm_2-1

```html_example
<section class="b-promo_tiles_grid m-lg_3 m-md_3 m-sm_2-1">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

### m-lg_3 m-md_3 m-sm_1

```html_example
<section class="b-promo_tiles_grid m-lg_3 m-md_3 m-sm_1">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
	</div>
</section>
```

### m-lg_2 m-md_2 m-sm_2

```html_example
<section class="b-promo_tiles_grid m-lg_2 m-md_2 m-sm_2">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

### m-lg_2 m-md_2 m-sm_1

```html_example
<section class="b-promo_tiles_grid m-lg_2 m-md_2 m-sm_1">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

## Amount for image tiles

Technically it holds unlimited amount of imageTiles (b-promo_tile).

```html_example
<section class="b-promo_tiles_grid m-lg_3 m-md_3 m-sm_2-1">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
	</div>
</section>
```

*/

.b-promo_tiles_grid {
	$root: &;

	&-title {
		margin: 96px 0 40px;
		text-align: center;

		@include media(sm) {
			margin-top: 48px;
		}
	}

	&-content {
		@include g-grid;
		grid-gap: 0;

		@include media(xl) {
			grid-gap: 0;
		}
	}

	&-item {
		display: block;
		grid-column: span 3;

		@include media(md-up) {
			#{$root}.m-lg_2 & {
				grid-column: span 6;
			}

			#{$root}.m-lg_3 & {
				grid-column: span 4;
			}

			//#{$root}.m-lg_4 & {
			//	grid-column: span 3;
			//}
		}

		@include media(sm) {
			grid-column: span 3;

			#{$root}.m-sm_1 & {
				grid-column: span 6;
			}

			//#{$root}.m-sm_2 & {
			//	grid-column: span 3;
			//}
			#{$root}.m-sm_2_1 &:nth-child(3n) {
				grid-column: span 6;
			}
		}
	}

	&-item_link {
		@include g-link(inherit);

		display: block;
	}

	.product-tile-container {
		.product {
			display: block;
		}
	}
}

.b-promo_tiles_grid.m-custom_menu {
	margin: 8px auto 40px;
	max-width: grid-span(8);
	width: 100%;
}
