.b-video_banner {
	$root: &;

	@include g-page-designer-self_alignments;

	width: 100%;

	&.m-vertical_top {
		.b-video_banner-container {
			align-items: flex-start;
		}
	}

	&.m-vertical_bottom {
		.b-video_banner-container {
			align-items: flex-end;
		}
	}

	&.m-vertical_middle {
		.b-video_banner-container {
			align-items: center;
		}
	}

	&.m-vertical_fill {
		.b-text_block {
			height: 100%;
		}
	}

	.b-text_block {
		&.m-horizontal_left {
			text-align: left;

			.b-actions {
				justify-content: flex-start;
			}
		}

		&.m-horizontal_right {
			text-align: right;

			.b-actions {
				justify-content: flex-end;
			}
		}

		&.m-horizontal_center {
			text-align: center;

			.b-actions {
				justify-content: center;
			}
		}
	}

	&-container {
		display: grid;
		grid-template-columns: auto;
		overflow: hidden;
		position: relative;
		width: 100%;

		&::after {
			background-color: var(--bg-videoBanner-overlay, transparent);
			content: '';
			height: 100%;
			left: 0;
			opacity: var(--opacity, 100%);
			pointer-events: none;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	&-inner {
		grid-column: 1/2;
		grid-row: 1/2;
		padding-bottom: var(--bg-padding, 100%);
		position: relative;

		@include media(sm) {
			padding-bottom: var(--bg-padding-mobile, 100%);
		}

		iframe {
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
		}
	}

	&-item {
		height: 100%;
		left: 0;
		margin: 0 auto;
		position: absolute;
		top: 0;
		width: 100%;

		&[aria-hidden='true'] {
			visibility: hidden;
		}
	}

	&-text_block {
		display: grid;
		grid-column: 1/2;
		grid-row: 1/2;
	}

	&-caption {
		align-items: center;
		display: flex;
		flex-direction: column;
		left: 50%;
		padding: 16px;
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
	}

	&-title {
		@include g-heading_3;

		margin-bottom: 20px;
	}

	&-ctrl {
		background-color: $color-white;
		border-radius: 50%;
		height: 50px;
		pointer-events: none;
		position: relative;
		width: 50px;
		z-index: 1;
		border: 1px solid $btn-gray;

		&::before {
			border-bottom: 9px solid transparent;
			border-left: 17px solid $color-black;
			border-top: 9px solid transparent;
			content: '';
			left: 17px;
			position: absolute;
			top: 15px;
		}
	}

	&-poster {
		bottom: 0;
		cursor: pointer;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: visibility $motion-fast 0.3s;
		z-index: 1;

		&.m-hidden_md-up {
			@include media(md-up) {
				display: none;
			}
		}
	}

	.b-image_tile-picture {
		height: 100%;

		@include media(sm) {
			padding-bottom: var(--bg-padding-mobile, 100%);
		}
	}

	&.m-vertical_fill {
		align-items: stretch;

		#{$root}-item.m-external {
			object-fit: cover;
			object-position: center;
		}

		.b-image_tile-picture {
			padding-bottom: 0;
		}

		#{$root}-container {
			height: 100%;
		}
	}

	.b-text_block {
		background-color: transparent;
		left: 0;
		position: relative;
		top: 0;
		width: 100%;
		z-index: 1;

		@include media(lg-up) {
			padding: 60px 40px;
		}

		@include media(md) {
			padding: 60px 32px;
		}

		@include media(sm) {
			padding: 40px 12px;
		}

		&-container {
			background-color: var(--bg-text-block, transparent);
		}

		.b-button {
			pointer-events: auto;
		}
	}
}
