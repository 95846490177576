$cr-color-main-type-1: #e30714;
$cr-color-secondary-type-1: #c20c16;
$cr-color-main-type-2: #424242;
$cr-color-secondary-type-2: #5e5c5c;
$cr-color-main-type-3: #424242;
$cr-color-secondary-type-3: #989898;
$cr-color-main-type-4: #e30714;

.b-actions {
	$root: &;

	display: flex;
	flex-wrap: wrap;
	grid-gap: 32px;
	margin: 44px 0 0;
	position: relative;
	width: 100%;

	&-item {
		width: var(--button-width, auto);
	}

	&-container {
		@include g-page-designer-alignments;

		.b-button {
			height: auto;
			min-height: $size-input-height;
			padding-bottom: 4px;
			padding-top: 4px;
			text-align: center;
			white-space: normal;
			width: var(--button-width, auto);

			&-icon_left {
				margin-right: 8px;
			}

			&-icon_right {
				margin-left: 8px;
			}

			&.m-link,
			&.m-link_action,
			&.m-link_regular,
			&.m-link_no_underline,
			&.m-link_small,
			&.m-link_small_bold {
				@include g-page-designer-link;
				border: 0;
				padding: 0;
			}

			&.m-link_action {
				font-size: 1.15rem;
				font-weight: 700;
				line-height: 1.5rem;
				padding-bottom: 6px;
				text-decoration: underline solid $color-divider;
				text-decoration-thickness: 2px;
				text-transform: none;
				text-underline-offset: 6px;
				transition: text-decoration-color $motion-fast;

				@include hover-supported {
					&:hover {
						text-decoration: underline solid $color-action;
						text-decoration-thickness: 2px;
					}
				}

				&.m-with_icon {
					text-decoration: none;
					text-transform: uppercase;
				}
			}

			&.m-link_regular {
				font-size: 1.15rem;
			}

			&.m-link_no_underline {
				font-size: 1.15rem;
				text-decoration: none;
			}

			&.m-link_small_bold {
				font-weight: 700;
				text-decoration: none;
			}
		}
	}

	&.m-one-cta {
		#{$root}-item {
			width: 100%;
		}

		.b-button {
			width: var(--button-width, auto);
		}
	}

	&.m-column {
		flex-direction: column;
	}

	&.m-center {
		align-items: center;
		justify-content: center;
	}

	&.m-right {
		align-items: flex-end;
		justify-content: flex-end;
	}
}

.btn {
	width: var(--button-width, auto);
	line-height: inherit;
	font-weight: 400;
	min-width: 100px;

	&.disabled,
	&.disabled:hover,
	&.disabled:focus,
	&.disabled:active,
	&:disabled,
	&:disabled:hover,
	&:disabled:focus,
	&:disabled:active,
	&[disabled],
	&[disabled]:hover,
	&[disabled]:focus,
	&[disabled]:active {
		background-color: $btn-gray-light;
		border-color: $btn-gray-light;
		color: $black;
		opacity: 1;
		outline: none;
		pointer-events: none;
	}

	&.m-link,
	&.m-link_action,
	&.m-link_regular,
	&.m-link_underline,
	&.m-link_small,
	&.m-link_small_bold {
		@include g-page-designer-link;
		@include fontSize(16);
		line-height: 1.5rem;
		text-decoration: none;
	}

	&.m-link_regular,
	&.m-link_underline,
	&.m-link_small,
	&.m-link_small_bold {
		@include hover-supported {
			&:hover {
				color: $btn-blue;
			}
		}
	}

	&.m-link_underline,
	&.m-link_small,
	&.m-link_action {
		position: relative;

		&::after {
			content: ' ';
			position: absolute;
			bottom: 1px;
			display: block;
			width: 100%;
			border-bottom: 1px solid;
			transition: inherit;
		}

		@include hover-supported {
			&:hover {
				&::after {
					display: none;
				}
			}
		}
	}

	&.m-link_small,
	&.m-link_small_bold {
		@include fontSize(14);
	}

	&.m-link_small_bold,
	&.m-link_action {
		font-weight: 700;
	}

	&.m-link_action {
		&::after {
			border-bottom: 2px solid $btn-gray;
			bottom: -5px;
		}

		@include hover-supported {
			&:hover {
				color: $btn-blue;

				&::after {
					display: block;
					border-color: $btn-blue;
				}
			}
		}

		&.m-with_icon {
			text-decoration: none;
			text-transform: uppercase;
		}
	}
}

.btn-type-1 {
	background-color: $cr-color-main-type-1;
	border-color: $cr-color-main-type-1;
	color: $white;
	border-width: 2px;

	&:hover:not(:active):not(:focus) {
		background-color: $white;
		border-color: $cr-color-main-type-1;
		color: $cr-color-main-type-1 !important;
	}

	&:not(:disabled):not(.disabled):focus:not([disabled]),
	&:not(:disabled):not(.disabled):active:not([disabled]) {
		background-color: $cr-color-secondary-type-1;
		border-color: $cr-color-secondary-type-1;
		outline: 4px solid $white !important;
		color: $white;
		box-shadow: 0px 0px 8px 5px $cr-color-secondary-type-1;
	}
}

.btn-type-2 {
	background-color: $white;
	border-color: $white;
	color: $cr-color-main-type-2;
	border-width: 2px;

	&:hover {
		background-color: $cr-color-main-type-2;
		border-color: $white;
		color: $white !important;
	}

	&:not(:disabled):not(.disabled):focus:not([disabled]),
	&:not(:disabled):not(.disabled):active:not([disabled]) {
		background-color: $white;
		border-color: $cr-color-secondary-type-2;
		outline: 4px solid $white !important;
		color: $cr-color-main-type-2 !important;
		box-shadow: 0px 0px 8px 5px $cr-color-secondary-type-2;
		border-width: 2px;
	}
}

.btn-type-3 {
	background-color: $cr-color-main-type-3;
	border-color: $cr-color-main-type-3;
	color: $white;
	border-width: 2px;

	&:hover:not(:active):not(:focus) {
		background-color: $white;
		border-color: $cr-color-main-type-3;
		color: $cr-color-main-type-3 !important;
	}

	&:not(:disabled):not(.disabled):focus:not([disabled]),
	&:not(:disabled):not(.disabled):active:not([disabled]) {
		background-color: $cr-color-main-type-3;
		border-color: $cr-color-secondary-type-3;
		outline: 4px solid $white !important;
		color: $white;
		box-shadow: 0px 0px 8px 5px $cr-color-secondary-type-3;
	}

	&.disabled,
	&.disabled:hover,
	&.disabled:focus,
	&.disabled:active,
	&:disabled,
	&:disabled:hover,
	&:disabled:focus,
	&:disabled:active,
	&[disabled],
	&[disabled]:hover,
	&[disabled]:focus,
	&[disabled]:active {
		background-color: $black;
		border-color: $btn-gray;
		color: $btn-gray !important;
		opacity: 1;
		outline: none;
		pointer-events: none;
	}
}

.btn-type-4 {
	background-color: $white;
	border-color: $white;
	color: $cr-color-main-type-4;
	border-width: 2px;

	&:hover {
		background-color: $cr-color-main-type-4;
		border-color: $white;
		color: $white !important;
	}

	&:not(:disabled):not(.disabled):focus:not([disabled]),
	&:not(:disabled):not(.disabled):active:not([disabled]) {
		background-color: $cr-color-main-type-4;
		border-color: $cr-color-main-type-4;
		outline: 4px solid $white !important;
		color: $white;
		box-shadow: 0px 0px 8px 5px $cr-color-main-type-4;
	}
}
