// H1-H6
//
// Markup:
// <h1>H1 - The quick brown fox jumps over the lazy dog</h1>
// <h2>H2 - The quick brown fox jumps over the lazy dog</h2>
// <h3>H3 - The quick brown fox jumps over the lazy dog</h3>
// <h4>H4 - The quick brown fox jumps over the lazy dog</h4>
// <h5>H5 - The quick brown fox jumps over the lazy dog</h5>
// <h6>H6 - The quick brown fox jumps over the lazy dog</h6>
//
// Styleguide 3.2

// Headings
$h6-font-family: $font-primary !default;

h1,
.h1 {
	@include heading($h1-font-size, $h1-lineheight, $font-weight-heavy);
}

h2,
.h2 {
	@include heading($h2-font-size, $h2-lineheight, $font-weight-heavy);
}

h3,
.h3 {
	@include heading($h3-font-size, $h3-lineheight, $font-weight-heavy);
}

h4,
.h4 {
	@include heading($h4-font-size, $h4-lineheight, $font-weight-heavy);
}

h5,
.h5 {
	@include heading($h5-font-size, $h5-lineheight, false);
}

h6,
.h6 {
	@include heading($h6-font-size, $h6-lineheight, false);

	font-family: $h6-font-family;
}
