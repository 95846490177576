/*md
@no-stat

# Z-indexes

Z-index is an inherently tricky thing, and maintaining z-index order in a complex layout is difficult.
With different stacking orders and contexts, keeping track of them as their numbers increase can be hard.
<br />
<br />
We use sass function to help manage z-indexes from single place.
The most important requirement of this technique is sticking to it.
Any rogue hard-coded z-index values could compromise the integrity of those derived from your list.

## Usage

**We don't use hardcoded integer `z-index` values. Instead, we use indexes from the map `$z-indexes`**

### 1. Set up `$z-indexes` map
```scss
$z-indexes: (
    components: (
        component_name: (),
        checkbox: (
            before: (),
            after: (),
            icon: (),
        )
    ),
    content: (),
    popup-menu: ()
);
```

### 2. Add values in SCSS classes using `z()` function

#### Global components
```scss
.b-components { z-index: z(components); }
.b-content { z-index: z(content); }
.b-pop_up-menu { z-index: z(popup-menu); }
```

#### Inside a component
```scss
.b-component_name { z-index: z(components, component_name); }
.b-checkbox {
    &-before { z-index: z(components, checkbox, before); }
    &-after { z-index: z(components, checkbox, after); }
    &-icon { z-index: z(components, checkbox, icon); }
}
```

### 3. Get resulting CSS
```scss
.b-components { z-index: 1; }
.b-content { z-index: 2; }
.b-pop_up-menu { z-index: 3; }

.b-component_name { z-index: 1; }
.b-checkbox-before { z-index: 1; }
.b-checkbox-after { z-index: 2; }
.b-checkbox-icon { z-index: 3; }
```

*/
$z-indexes: (
	components: (
		carousel: (
			item: (),
			controls: (),
			grabbing-overlay: ()
		),
		payment_option: (
			input: (),
			label: ()
		),
		select: (
			icon: (),
			input: ()
		),
		checkbox: (
			input: ()
		),
		search: (
			suggestions: ()
		)
	),
	back-to-top: (),
	progress-bar: (),
	product-panel-sticky: (),
	comparison-panel-sticky: (),
	live-chat-button: (),
	menu-bar: (),
	geolocation: (),
	country-selector: (),
	minicart: (),
	search: (),
	search-toggle-sticky: (),
	header-actions-sticky: (),
	header: (),
	live-chat-dialog: (),
	modal: (),
	zoom-thumbs: (),
	notification-panel: (),
	skip-to: (),
	notifier: (),
	highlighter: ()
);

@import 'z-indexes_tools';
