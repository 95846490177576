.b-action_banner {
	align-self: flex-start;
	overflow: hidden;
	position: relative;

	&-spots {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&.m-edit_mode &-spot {
		height: 100%;
		pointer-events: none;
		position: absolute;
		width: 100%;
	}
}
