@at-root {
	@keyframes growIn {
		0% {
			opacity: 0;
			transform: scale(0.8);
		}

		50% {
			transform: scale(1.2);
		}

		100% {
			opacity: 1;
			transform: scale(1);
		}
	}
}
