@use 'sass:color';

@keyframes hero-carousel-progress {
    from {
        stroke-dashoffset: 104;
    }

    to {
        stroke-dashoffset: 1;
    }
}

.b-hero_carousel {
    $root: &;
    $accent-color: $black;
    $dot-outline-color: color.scale($accent-color, $lightness: 100%);
    $dot-color: $color-grey46;
    $dot-color-hover: color.adjust(color.scale($accent-color, $lightness: -20%), $saturation: -100%);

    margin: 0 auto;
    max-width: $global-site-width-max;
    overflow: hidden;
    position: relative;

    &-track {
        display: flex;
        overflow: hidden;

        &.m-grabbing {
            cursor: grab;
            user-select: none;
        }

        &.m-grabbing::before,
        .b-promo_box-picture::before { // prevent image dragging
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 3;
        }
    }

    &-item {
        height: 100%;
        left: 0;
        min-width: 100%; // until JS loaded it is show single slide. This need to flex packing
        top: 0;
        width: 100%;
        will-change: transform;
        z-index: 1;

        &:not(:first-child) {
            visibility: hidden;
        }

        #{$root}.m-initialized & {
            overflow: hidden;
            position: absolute;
            transition: cubic-bezier(0.56, 0.03, 0.47, 0.98) 0.8s;
            transition-property: transform, visibility;
            visibility: hidden;
        }

        &.m-prev {
            #{$root}.m-initialized & {
                transform: translateX(-100%);
            }
        }

        &.m-next {
            #{$root}.m-initialized & {
                transform: translateX(100%);
            }
        }

        &.m-current {
            #{$root}.m-initialized & {
                position: static;
                transform: translateX(0);
                visibility: visible;
            }
        }
    }

    &-ctrl {
        appearance: none;
        background-color: rgba($white, 0.3);
        border: none;
        color: $black;
        cursor: pointer;
        display: none;
        height: 38px;
        margin-top: -24px;
        padding: 0;
        position: absolute;
        text-align: center;
        top: 50%;
        transition: 0.4s linear;
        transition-property: background-color, opacity;
        user-select: none;
        width: 38px;
        z-index: 2;

        @include hover-supported {
            &:hover {
                background-color: $white;
                color: $black;
            }
        }

        #{$root}.m-initialized & {
            display: block;

            @include media(sm) {
                display: none;
            }
        }

        &.m-prev {
            left: 40px;

            @include media(md-down) {
                left: 32px;
            }
        }

        &.m-next {
            right: 40px;

            @include media(md-down) {
                right: 32px;
            }
        }
    }

    &-pagination {
        bottom: 15px;
        display: flex;
        justify-content: center;
        left: 50%;
        opacity: 0;
        position: absolute;
        transform: translateX(-50%);
        transition: opacity 0.4s linear;
        visibility: hidden;
        z-index: 2;

        @include media(sm) {
            bottom: 24px;
            left: 30%;
        }
    }

    &.m-initialized {
        .b-hero_carousel-pagination {
            opacity: 1;
            visibility: visible;
        }
    }

    &-pagination_content {
        align-items: center;
        background-color: adjust-color-to-bg($white, rgba($black, 0.3), rgba($white, 0.3));
        border-radius: 27px;
        display: flex;
        padding: 8px;
    }

    &-pagination_dots {
        display: flex;
        margin: 0 4px 0 0;
    }

    &-pagination_dot {
        $dot-size: 34px;

        background: none;
        border: none;
        color: transparent;
        cursor: pointer;
        display: block;
        fill: $dot-color;
        height: $dot-size;
        margin-inline-end: 6px;
        padding: 0;
        position: relative;
        width: $dot-size;

        @include hover-supported {
            &:hover {
                fill: $btn-blue;
            }
        }

        &.m-current {
            fill: $btn-blue;
        }

        &:last-child {
            margin-inline-end: 0;
        }

        &[aria-disabled='true'] {
            cursor: initial;
        }
    }

    &-pagination_dot_outline {
        stroke: $white;
        stroke-width: 2;
        transition: 0.4s ease;
        transition-property: stroke-width;
    }

    &-autoplay {
        background: transparent;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: none;
        margin-inline-end: 4px;
        padding: 0;

        &.m-initialized {
            display: block;
        }

        &.m-animated {
            animation: hero-carousel-progress linear;
        }
    }

    &-autoplay_svg {
        fill: transparent;
        stroke-linecap: round;
        stroke-width: 1;
    }

    &-autoplay_progress {
        stroke: $btn-dark-blue;
        stroke-width: 3;
        fill: $white;

        #{$root}-autoplay.m-animated & {
            stroke: $btn-blue;
        }
    }

    &-autoplay_progress_back {
        stroke: $dot-outline-color;
        stroke-width: 3;
    }

    &-autoplay_play,
    &-autoplay_pause {
        display: block;
        fill: $btn-blue;
        opacity: 1;
        stroke: $btn-blue;
        transition: opacity ease 0.2s;
    }

    &-autoplay_play {
        #{$root}-autoplay[aria-pressed='false'] & {
            opacity: 0;
        }
    }

    &-autoplay_pause {
        stroke-width: 3;

        #{$root}-autoplay[aria-pressed='true'] & {
            opacity: 0;
        }
    }

    &-booking_widget {
        .b-hint {
            display: flex;
            margin-bottom: 40px;
        }

        @include media-breakpoint-up(md) {
            .b-pdx_booking_widget.m-horizontal {
                margin: -40px auto 80px;
            }

            .b-pdx_booking_widget.m-overlap {
                @include media-breakpoint-up(md) {
                    bottom: 20px;
                    left: 0;
                    padding: 0 90px;
                    position: absolute;
                    top: 20px;
                }
            }
        }
    }
}

.b-hero_carousel_ext {
    @include g-page-designer-vertical_alignments;

    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;

    &.m-vertical_fill {
        .b-hero_carousel-track,
        .c-banner.m-vertical_fill,
        .b-hero_carousel-item > .b-text_block {
            height: 100%;
        }

        .b-hero_carousel-item {
            display: flex;
            flex-direction: column;
        }
    }

    &.m-with_booking {
        .b-hero_carousel-pagination {
            @include media-breakpoint-up(md) {
                bottom: 50px;
            }
        }
    }

    &.m-fade .m-initialized .b-hero_carousel-item {
        opacity: 0;
        transform: none;
        transition: opacity 0.4s linear, visibility 0s 0.4s; // delay for visibility should be equal to opacity duration

        &.m-current {
            opacity: 1;
            transition: opacity 0.4s linear, visibility 0s;
        }
    }

    .b-hero_carousel,
    .experience-bookingWidget {
        width: 100%;
    }
}
