.l-plp_grid {
	&-pd_preview {
		display: none;
	}

	&-banner {
		display: flex;
	}

	&-banner_content {
		display: flex;
		flex-grow: 1;
	}

	&-banner:nth-of-type(2),
	&-pd_preview:nth-of-type(2) {
		grid-column: 2/4;
		grid-row: 2/4;

		@include media(sm) {
			grid-column: 1/-1;
			grid-row: 3/4;
		}
	}

	.l-page-container.m-edit_mode &-pd_preview {
		align-items: center;
		background-color: $color-blue98;
		border: 2px dashed $color-blue;
		display: flex;
		font-size: 16px;
		justify-content: center;
	}
}
