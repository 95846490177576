/*md

# g-button_icon_only

Designed to provide same styles of buttons that contain only icon (without any text)
across different components without explicit CSS class.

It is used for header menubar icons, hamburger menu items and dialog close button.

```scss
.b-dialog {
	// ...
	&-close {
		@include g-button_icon_only;
	}
}
```
*/

@mixin g-button_icon_only {
	align-items: center;
	appearance: none;
	background: transparent;
	border: none;
	color: inherit;
	cursor: pointer;
	display: flex;
	flex-shrink: 0;
	height: 48px;
	justify-content: center;
	text-align: center;
	width: 48px;

	&:hover {
		color: $color-action;
	}
}
