.l-static_page {
	&.m-full_width {
		margin-bottom: 0;
		max-width: $global-site-width-max;
		padding-left: 0;
		padding-right: 0;
	}

	&-title {
		@include g-section_holder;
		@include g-heading_1;

		@include media(sm) {
			text-align: center;
		}
	}
}
