$size-font: 14px;
$size-line: 1.5;
$size-input-height: 48px; // https://adrianroselli.com/2019/06/target-size-and-2-5-5.html

// Please note!
// The Launch 360 Beauty Vertical project adheres to use values multiples of 4 for margins/paddings/indents.
// This is not a mandatory but strongly recommended approach which allows to prevent excess crushing of values and
// makes development easier for FE and UI departments.

// 4px
// 8px
// 12px
// 16px
// 20px
// ...
