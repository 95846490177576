/*md

# b-promo_tile

This is kind of small banners that used in Page designer as ImageTiles

This is special type of banners that designed to be used in different layout arrangement.

## With caption

```html_example
<figure class="b-promo_tile" style="width: 300px">
	<picture class="b-promo_tile-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
			alt="Just dummy image."
			width="336"
			height="420"
		/>
	</picture>
	<figcaption class="b-promo_tile-caption">
		<p class="b-promo_tile-title">
			New in
		</p>
	</figcaption>
</figure>
```

## Without caption

```html_example
<div class="b-promo_tile" style="width: 300px">
	<picture class="b-promo_tile-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
			alt="Just dummy image."
			width="336"
			height="420"
		/>
	</picture>
</div>
```

*/

.b-promo_tile {
	$root: &;

	display: grid;
	height: 100%;

	&-picture {
		@include g-image_container(_container, aspect-ratio(3, 4));

		grid-column: 1 / 2;
		grid-row: 1 / 2;

		img {
			@include g-image_container(_img);
		}
	}

	&-caption {
		align-items: center;
		display: flex;
		flex-direction: column;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		justify-content: flex-end;
		padding: 48px 12px;
		text-align: center;
		z-index: 1;

		@include media(sm) {
			padding-bottom: 36px;
			padding-top: 36px;
		}

		#{$root}.m-caption_below & {
			grid-row: 2 / 2;
			padding-bottom: 20px;
			padding-top: 20px;
		}

		.b-button { // Case with button inside
			margin-top: 20px;
		}
	}

	&-title {
		font-size: 18px;
		font-weight: 500;
	}
}

.b-promo_tiles_grid.m-custom_menu {
	.b-promo_tile-title {
		font-size: 16px;
	}
}
