/*md

# b-promotion

Designed to provide same styles of promotion across different components.

We do not support any HTML in promotion messages.

## Promotion with configured link

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for <a href="#">order</a>
	</div>
</div>
```

## Empty promotion

```html_example
<div class="b-promotion">
	<div class="b-promotion-message"></div>
</div>
```

## Promotion with details in dialog

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for order
	</div>
	<button
		class="b-promotion-details"
		type="button"
		data-widget="emitBusEvent"
		data-bus-event-type="dialog.show"
		data-event-click.prevent="emitBusEvent"
		data-tau="promotion_details_cta"
		data-initialized="1"
	>
		Details
	</button>
</div>
```

*/

.b-promotion {
	align-items: center;
	display: flex;
	font-weight: 500;
	margin-bottom: 8px;
	position: relative;

	&-message {
		background-color: $color-bg-promotion;
		border-radius: $global-radius;
		color: $color-fg-promotion;
		padding: 4px 8px;

		&:empty { // hide promotion if no callout message
			display: none;
		}

		&.m-centered {
			text-align: center;
			width: 100%;
		}

		&.m-large {
			padding: 16px 20px;
		}

		a {
			@include g-link(inherit);
		}
	}

	&-details {
		@include g-link;

		margin-inline-start: 12px;
	}
}
