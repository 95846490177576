/*md

# g-section_holder_header

Since header is differs from other container (g-section_holder)
we need special component with different `max-width` and `margin` than
`section_holder`.

This is global component designed to hold header of the site.

On projects it could be removed and changed to `section_holder`.

```scss
.l-header-inner {
	background: green;

	&-inner {
		@include g-section_holder_header;
	}
}
```
*/

@mixin g-section_holder_header {
	margin: 0 auto;
	max-width: $global-site-width-max;
	padding-left: 32px;
	padding-right: 32px;

	@include media(md) {
		padding-left: 28px;
		padding-right: 28px;
	}

	@include media(sm) {
		padding-left: 10px;
		padding-right: 10px;
	}
}
