/*md
@no-stat

# Globals variables

This variables are set of different global settings that is used across sets of components.

It include:

* globals
* depth of components (box-shadow)
* motion of components

*/
$global-radius: 0;
$global-line: 1px;
$global-site-width-max: 1920px; // used on Header and HP fullbleed promo
$global-content-width-min: 360px;
$global-content-width-max: 1440px;
$global-form-width: 900px; // used on My Account pages

// Z-depth
$depth-0: none;
$depth-1: 0 3px 5px hsla(0, 0%, 0%, 0.3);
$depth-2: 0 3px 10px hsla(0, 0%, 0%, 0.35);
$depth-2-no-top: 0 10px 10px rgba(0, 0, 0, 0.35);
$depth-3: 0 3px 15px hsla(0, 0%, 0%, 0.35);

// Motion
$motion-fast: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
$motion-ease: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
$motion-ease-popups: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
