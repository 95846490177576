.b-tooltip {
	position: relative;

	&.m-order_summary {
		margin-left: 8px;
	}

	&-button {
		align-items: center;
		border: 2px solid $color-action;
		border-radius: 50%;
		color: $color-action;
		cursor: pointer;
		display: flex;
		height: 20px;
		justify-content: center;
		transition: $motion-ease;
		transition-property: background-color, color;
		width: 20px;

		&:hover,
		&:focus {
			background-color: $color-action;
			color: $color-bg;
		}
	}

	&-container {
		background-color: $color-bg;
		border-radius: 4px;
		box-shadow: 0 0 10px 0 $color-divider-light;
		font-weight: 300;
		opacity: 0;
		padding: 8px 12px;
		position: absolute;
		transition: $motion-ease;
		transition-property: opacity, visibility;
		visibility: hidden;
		width: 100%;

		.b-tooltip.m-right & {
			left: calc(100% + 8px);
			top: 0;
		}

		.b-tooltip.m-order_summary & {
			width: 204px;

			@include media(lg) {
				width: 144px;
			}
		}

		.b-tooltip-button:hover + &,
		.b-tooltip-button:focus + & {
			opacity: 1;
			visibility: visible;
		}
	}
}
