$color-primary: hsl(0, 0%, 0%);
$color-black: hsl(0, 0%, 0%);
$color-grey30: hsl(0, 0%, 30%);
$color-grey46: hsl(0, 0%, 46%);
$color-grey73: hsl(0, 0%, 73%);
$color-grey85: hsl(0, 0%, 85%);
$color-grey95: hsl(0, 0%, 95%);
$color-white: hsl(0, 0%, 100%);
$color-blue: hsl(210, 85%, 44%);
$color-blue26: hsl(210, 99%, 26%);
$color-blue38: hsl(223, 77%, 38%);
$color-blue98: hsl(210, 99%, 26%);
$color-red: hsl(0, 100%, 42%);
$color-yellow94: hsl(36, 93%, 94%);
$motion-fast: $color-primary;
$color-bg-header-line-1: $color-primary;
$color-bg-panel: $color-primary;
$color-red95: hsl(0, 33%, 45%);
$color-bg-shade2: rgba(0, 0, 0, .5);
$color-bg-overlay: $color-bg-shade2;
$color-action: $color-primary;
$color-divider: $color-primary;
$color-divider-light: $color-bg-shade2;
$color-bg: gray;
$color-grey88: gray;
$color-text: $color-primary;
$grey65: #424242;

// New colors
$black: #000;
$white: #fff;

$btn-blue: #3769e2;
$btn-dark-blue: #1a44a9;
$btn-gray: #e0e0e0;
$btn-gray-light: #e0e0e0;
$btn-gray-dark: #333333;
$btn-pink: #bb0a51;
$btn-cherry: #88053a;
$primary: $grey65;
$secondary: #e30714;