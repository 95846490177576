/*md

# g-backdrop_panel

Backdrop (overlay) for panels designed to handle swipe-to-close animation.

Serve as regular overlay.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-backdrop_dialog;
	}
	// ...
}
```
*/

@mixin g-backdrop_panel {
	&::before {
		background-color: rgba($color-bg-overlay, 0.4);
		bottom: 0;
		content: '';
		left: 0;
		opacity: 0;
		position: fixed;
		right: 0;
		top: 0;
		transition: $motion-ease-popups;
		transition-property: visibility, opacity;
		visibility: hidden;
		z-index: z(modal);
	}

	&.m-opened,
	&.m-active {
		&::before {
			opacity: var(--backdrop-opacity);
			transition: none;
			visibility: visible;
		}
	}
}
