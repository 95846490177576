/*md

# g-grid

g-grid is layout component based on CSS grid.

It is designed to use project defined grid (see _grids.scss) into components where CSS grid is
applicable.

As the result this component declare CSS grid configuration. Ex:

```
// scss
@include g-grid();
// css
grid-gap: 20px;
grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
```

And that it could be used to place items inside this declared grid. Ex:

```scss
.b-grid {
	@include g-grid();

	.b-columns__item {
		@include media(lg-up) {
			grid-column: 2 / span 4;
			grid-row: 1 / 2;
		}

		@include media(md-down) {
			grid-column: grid-start / span 7;
		}
	}
}
```

Please see [grid](00-configuration-grids.html) for more grid usage examples.

*/

@mixin g-grid {
	display: grid;

	@include media(xl) {
		grid-gap: grid-gutter(xl);
		grid-template-columns: [grid-start] repeat(grid-columns(xl), 1fr) [grid-end];
	}

	@include media(lg) {
		grid-gap: grid-gutter(lg);
		grid-template-columns: [grid-start] repeat(grid-columns(lg), 1fr) [grid-end];
	}

	@include media(md) {
		grid-gap: grid-gutter(md);
		grid-template-columns: [grid-start] repeat(grid-columns(md), 1fr) [grid-end];
	}

	@include media(sm) {
		grid-gap: grid-gutter(sm);
		grid-template-columns: [grid-start] repeat(grid-columns(sm), 1fr) [grid-end];
	}
}
