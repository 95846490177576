@mixin g-page-designer-alignments() {
	// horizontal
	&.m-horizontal_left,
	&.m-horizontal_center,
	&.m-horizontal_right {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

        &.component--full-screen-width {
            width: view-width(100);
        }
	}

	&.m-horizontal_left {
		justify-content: flex-start;
	}

	&.m-horizontal_center {
		justify-content: center;
	}

	&.m-horizontal_right {
		justify-content: flex-end;
	}

	// vertical
	&.m-vertical_top {
		align-self: flex-start;
	}

	&.m-vertical_middle {
		align-self: center;
	}

	&.m-vertical_bottom {
		align-self: flex-end;
	}
}

@mixin g-page-designer-self_alignments() {
	// vertical
	&.m-vertical_top {
		align-self: flex-start;
	}

	&.m-vertical_middle {
		align-self: center;
	}

	&.m-vertical_bottom {
		align-self: flex-end;
	}
}

@mixin g-page-designer-sm_self_alignments() {
	// vertical
	@include media(sm) {
		&.m-sm-vertical_top {
			align-self: flex-start;
		}

		&.m-sm-vertical_middle {
			align-self: center;
		}

		&.m-sm-vertical_bottom {
			align-self: flex-end;
		}
	}
}

@mixin g-page-designer-vertical_alignments() {
	// vertical
	&.m-vertical_top {
		align-items: flex-start;
	}

	&.m-vertical_middle {
		align-items: center;
	}

	&.m-vertical_bottom {
		align-items: flex-end;
	}

	&.m-vertical_fill {
		align-self: stretch;
	}
}

@mixin g-page-designer-horizontal_alignments() {
	// horizontal
	&.m-horizontal_left,
	&.m-horizontal_center,
	&.m-horizontal_right {
		display: flex;
		flex-wrap: wrap;
	}

	&.m-horizontal_left {
		justify-content: flex-start;
	}

	&.m-horizontal_center {
		justify-content: center;
	}

	&.m-horizontal_right {
		justify-content: flex-end;
	}
}
