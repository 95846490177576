// Common page styles goes here
@import '../01-core/functions/view-width';

.storepage {
	width: 100%;

	@include media(lg) {
		padding-top: 1.25rem;
	}
}

a.pd-image-link {
    border: none;

    &:hover,
    &:visited {
        border: none;
    }
}

.component--full-screen-width {
    max-width: 100vw;

    .container {
        max-width: 100vw;
    }

    .row {
        margin: 0;
    }

    .col-12 {
        padding: 0;
    }

    .pd-image-link {
        width: 100%;
    }

    picture {
        width: 100%;
    }

    .carousel-control-prev {
        left: 1px;
    }

    .carousel-control-next {
        right: 1px;
    }
}

.m-page_designer {
    .component--full-screen-width {
        width: view-width(100);
        margin: 0 calc(#{view-width(-50)} + 50%);
    }
}
