// Font sizes
// $base-font-size: 1rem !default;
// $xl-font-size: $base-font-size * 1.67 !default;
// $lg-font-size: $base-font-size * 1.5 !default;

// $heading-1-font-size: $base-font-size * 4 !default;
// $heading-2-font-size: $base-font-size * 3 !default;
// $heading-3-font-size: $xl-font-size !default;
// $heading-4-font-size: $lg-font-size !default;

// Font sizes
$h1-font-size: 32;
$h2-font-size: 28;
$h3-font-size: 24;
$h4-font-size: 20;
$h5-font-size: 18;
$h6-font-size: 16;

$h1-lineheight: rem(42);
$h2-lineheight: rem(34);
$h3-lineheight: rem(32);
$h4-lineheight: rem(28);
$h5-lineheight: rem(22);
$h6-lineheight: rem(20);

// Font weight
$base-font-weight: 400 !default;
$font-weight-heavy: 700 !default;
$font-weight-heavier: 900 !default;

$font-primary: 'Roboto Condensed', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
